import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { ScopeVersion } from '@core/model/scope-version';
import { ScopeSubmitter } from '@app/core/model/scope-submitter.model';
import { ApprovalFlowService } from '@app/features/scope-overview/service/approval-flow.service';
import { User } from '@app/core/model/user.model';
import { Preference } from '@core/model/user-preferences.interface';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ScopeUiTableComponent } from '@app/shared/components/ui-components/scope-ui-table/scope-ui-table.component';
import { plainToInstance } from 'class-transformer';
import { NgxMaskDirective } from 'ngx-mask'
import { ScopeTabService } from '@app/features/scope-overview/service/scope-tab.service'
import { Privilege } from '@app/core/model/enums/privilege.enum';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { Role } from '@app/features/scoping/models/role.model'
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {cloneDeep} from "lodash";
import {ScopeUiModalComponent} from "@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component";
import {ModalConfig} from "@core/model/modal-config.model";
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {
  ComponentRowComponent
} from '@app/features/scope-overview/components/scope-tab/component-row/component-row.component'
import {
  DeliverableSectionRowComponent
} from '@app/features/scope-overview/components/scope-tab/deliverable-section-row/deliverable-section-row.component'
import { MenuOptions } from '@core/model/definitions/menu-options.interface'
import { ScopeUiCounterComponent } from '@shared/components/ui-components/scope-ui-counter/scope-ui-counter.component';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { ScopeOverviewActions } from '@app/features/scope-overview/store/actions/scope-overview.action';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
import { CdnConfig } from '@core/model/cdn-config.model';

@Component({
  selector: 'scope-deliverable-row',
  imports: [CommonModule, SharedModule, ScopeUiTableComponent, NgxMaskDirective, ComponentRowComponent, DeliverableSectionRowComponent, ScopeUiCounterComponent],
  templateUrl: './deliverable-row.component.html',
  styleUrls: ['./deliverable-row.component.scss'],
  standalone: true,
})
export class DeliverableRowComponent {
  @Input() isTimeline: boolean = false;

  @Input() deliverable!: Deliverable;

  @Input() currentUser!: User;

  @Input() userColumns!: any;

  @Input() deliverableColumns!: any;

  @Input() mainColumnClass!: string

  @Input() componentMainColumnClass!: string

  @Input() scopeVersion!: ScopeVersion;

  @Input() collaborators!: ScopeSubmitter[]

  @Input() deliverableFeeColumns!: Preference[];

  @Input() componentFeeColumns!: Preference[];

  @Input() feeMenuOptions!: MenuOptions[]

  @Input() currentRatecard: RatecardVersion

  @Input() level: number

  @Input() deliverableSelectedStates: { [id: number]: boolean }

  @Input() cdnConfig?: CdnConfig

  @Output() onFetchDeliverable!: EventEmitter<number>;
  @Input() deliverableDrops: string[]

  @Output() onUpdateDeliverable!: EventEmitter<Deliverable>;

  @Output() onUpdateComponent!: EventEmitter<ScopeComponent>;

  @Output() onMoveComponent!: EventEmitter<any>;

  @Output() onMoveComponentOutsideDeliverable!: EventEmitter<any>;

  @Output() onAcceptDeliverable!: EventEmitter<Deliverable>;

  @Output() onRejectDeliverable!: EventEmitter<Deliverable>;

  @Output() onDuplicateDeliverable!: EventEmitter<Deliverable>

  @Output() onDeleteDeliverable!: EventEmitter<Deliverable>

  @Output() onAddDeliverableFee!: EventEmitter<Deliverable>;

  @Output() onAddComponentFee!: EventEmitter<ScopeComponent>;

  @Output() onEditDeliverable!: EventEmitter<number>;

  @Output() onCompleteTrade!: EventEmitter<Deliverable>

  @Output() onTradeDeliverable!: EventEmitter<Deliverable>

  @Output() onStopProgressTrade!: EventEmitter<Deliverable>

  @Output() onAddDeliverableToTrade!: EventEmitter<Deliverable>

  @Output() onConfirmTrade!: EventEmitter<Deliverable>

  @Output() onCancelTrade!: EventEmitter<Deliverable>

  @Output() onReopenTrade!: EventEmitter<Deliverable>

  @Output() onShowDeliverableApplyLocationCardDialog!: EventEmitter<Deliverable>

  @Output() onUpdateFeeItem!: EventEmitter<{ feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent }>

  @Output() onOverrideQuantity!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>

  @Output() onOverrideRateCardRoleHours!: EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>

  privilege: Privilege;

  isLoading: boolean = false;
  isLoaded: boolean = false;
  componentDragging: boolean = false;
  dragTargetId: number = null;
  private readonly destroy$;

  constructor(public scopeApprovalFlowService: ApprovalFlowService,public scopeOverviewService: ScopeOverviewService, public scopeTabService: ScopeTabService, private store: Store, private dialog: MatDialog) {
    this.onFetchDeliverable = new EventEmitter<number>()
    this.onUpdateDeliverable = new EventEmitter<Deliverable>()
    this.onUpdateComponent = new EventEmitter<ScopeComponent>()
    this.onMoveComponent = new EventEmitter<any>()
    this.onMoveComponentOutsideDeliverable = new EventEmitter<any>()
    this.onDuplicateDeliverable = new EventEmitter<Deliverable>()
    this.onDeleteDeliverable = new EventEmitter<Deliverable>()
    this.onAcceptDeliverable = new EventEmitter<Deliverable>()
    this.onRejectDeliverable = new EventEmitter<Deliverable>()
    this.onAddDeliverableFee = new EventEmitter<Deliverable>()
    this.onAddComponentFee = new EventEmitter<ScopeComponent>()
    this.onEditDeliverable = new EventEmitter<number>()
    this.onCompleteTrade = new EventEmitter<Deliverable>()
    this.onTradeDeliverable = new EventEmitter<Deliverable>()
    this.onStopProgressTrade = new EventEmitter<Deliverable>()
    this.onAddDeliverableToTrade = new EventEmitter<Deliverable>()
    this.onConfirmTrade = new EventEmitter<Deliverable>()
    this.onCancelTrade = new EventEmitter<Deliverable>()
    this.onReopenTrade = new EventEmitter<Deliverable>()
    this.onShowDeliverableApplyLocationCardDialog = new EventEmitter<Deliverable>()
    this.onUpdateFeeItem = new EventEmitter<{ feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent }>()
    this.onOverrideQuantity = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent }>()
    this.onOverrideRateCardRoleHours = new EventEmitter<{ deliverable: Deliverable, component: ScopeComponent, role: Role }>()
    this.destroy$ = untilDestroyed();
  }

  completeTrade(deliverable: Deliverable) {
    this.onCompleteTrade.emit(deliverable)
  }

  tradeDeliverable(deliverable: Deliverable) {
    this.onTradeDeliverable.emit(deliverable)
  }

  stopProgressTrade(deliverable: Deliverable) {
    this.onStopProgressTrade.emit(deliverable)
  }

  addDeliverableToTrade(deliverable: Deliverable) {
    this.onAddDeliverableToTrade.emit(deliverable)
  }

  confirmTrade(deliverable: Deliverable) {
    this.onConfirmTrade.emit(deliverable)
  }

  cancelTrade(deliverable: Deliverable) {
    this.onCancelTrade.emit(deliverable)
  }

  reopenTrade(deliverable: Deliverable) {
    this.onReopenTrade.emit(deliverable)
  }

  // Accept/Reject Deliverable
  acceptDeliverable(deliverable: Deliverable) {
    this.onAcceptDeliverable.emit(deliverable)
  }

  rejectDeliverable(deliverable: Deliverable) {
    this.onRejectDeliverable.emit(deliverable)
  }

  duplicateDeliverable(deliverable: Deliverable) {
    this.onDuplicateDeliverable.emit(deliverable);
  }

  deleteDeliverable(deliverable: Deliverable) {
    this.onDeleteDeliverable.emit(deliverable)
  }

  addDeliverableFee() {
    this.onAddDeliverableFee.emit(this.deliverable);
  }

  addComponentFee(component: ScopeComponent) {
    this.onAddComponentFee.emit(component);
  }

  editDeliverable(deliverable: Deliverable) {
    this.onEditDeliverable.emit(deliverable.id);
  }

  hasLocationCards(ratecard){
    return ratecard && ratecard.locationCards?.length > 0
  }

  dropComponent(event: CdkDragDrop<any>, deliverable :Deliverable) {
    let del = cloneDeep(deliverable)
    let currentComponent : ScopeComponent = event.item.data;
    if(currentComponent.deliverable.id === deliverable.id) {
      let filter = del.components.filter(c => c.id == currentComponent.id)[0];
      filter.order = event.currentIndex
      if (event.currentIndex > event.previousIndex) {
        del.components.filter(c => c.id != currentComponent.id).forEach(c => {
          if (c.order <= event.currentIndex && c.order >= event.previousIndex) {
            c.order--
          }
        })
      } else {
        del.components.filter(c => c.id != currentComponent.id).forEach(c => {
          if (c.order >= event.currentIndex && c.order <= event.previousIndex) {
            c.order++
          }
        })
      }
      this.deliverable = del
      this.moveComponent(this.deliverable, filter)
    } else {
      let componentForDuplicate = deliverable.components.find(c=>c.source.id === currentComponent.source.id && c.name == currentComponent.name)
      if(componentForDuplicate){
        let dialog = this.dialog.open(ScopeUiModalComponent, {
          data: new ModalConfig(
            `Duplicate component to` + deliverable.name,
            `Enter the name of the duplicated component`,
            'Complete',
            undefined,
            (form: FormControl) => {
              dialog.close();
              let duplicatedComponentName = form.get('name')?.value;
              let scopeComponent = cloneDeep(currentComponent);
              scopeComponent.order = event.currentIndex
              scopeComponent.setUpdatedName(duplicatedComponentName)
              scopeComponent.name = duplicatedComponentName
              this.moveComponentOutsideDeliverable(scopeComponent,deliverable)
            },
            undefined,
            [{ name: 'name', control: new FormControl(''), type: 'text', label: 'Name' }],
            undefined,
            undefined,
            undefined,
            (form: FormControl) => {
              return form.value.name
            }
          ),
        })
      } else {
        let scopeComponent = cloneDeep(currentComponent);
        scopeComponent.order = event.currentIndex
        this.moveComponentOutsideDeliverable(scopeComponent,deliverable)
      }
    }
  }

  fetchDeliverable() {
    if (!this.deliverable.componentsInitialised) {
      this.onFetchDeliverable.emit(this.deliverable.id);
    }
    this.scopeTabService.toggleDeliverable(this.deliverable.id)
  }

  onUpdateQuantity($event: number) {
    this.onUpdateDeliverable.emit(plainToInstance(Deliverable, { ...this.deliverable, quantity: $event }))
  }

  getCommentsCount(): number {
    if (!this.deliverable.componentsInitialised && !this.isLoading && !this.isLoaded) {
      this.isLoading = true
      this.scopeOverviewService
        .getScopeDeliverable(this.scopeVersion.identity.id, this.deliverable.id)
        .pipe(this.destroy$())
        .subscribe({
          next: (deliverable: Deliverable) => {
            this.store.dispatch(ScopeOverviewActions.getScopeDeliverableSuccess({ deliverable: deliverable }))
            this.isLoading = false
            this.isLoaded = true
          },
          error: (error: any) => {
            console.error(error)
            this.isLoading = false
            this.isLoaded = false
            return error
          }
        })
      return 0;
    } else {
      return this.deliverable.getCommentsCount();
    }
  }

  saveDeliverableDescription(description: string) {
    this.onUpdateDeliverable.emit(plainToInstance(Deliverable, { ...this.deliverable, description }));
  }

  saveDeliverableInternalNote(internalNote: string) {
    this.onUpdateDeliverable.emit(plainToInstance(Deliverable, { ...this.deliverable, internalNote }));
  }

  updateComponent(component: ScopeComponent) {
    this.onUpdateComponent.emit(component);
  }

  moveComponent(deliverable: Deliverable, component: ScopeComponent) {
    this.onMoveComponent.emit({component: component, deliverable: deliverable});
  }

  moveComponentOutsideDeliverable(component: ScopeComponent, deliverable: Deliverable){
    this.onMoveComponentOutsideDeliverable.emit(plainToInstance(ScopeComponent, { ...component, deliverable }))
  }

  showToggleMenu() {
    if (this.scopeApprovalFlowService.isScopeEditable(this.scopeVersion)) return true
    if (this.deliverable.isPendingTrade() && this.scopeVersion.findDeliverableById(this.deliverable.tradedFrom.id).tradesCount > 1) return true
    if (this.deliverable.deliveryStatus == 'IN_PROGRESS' && this.scopeVersion.isStateApproved() &&
        this.scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover &&
        this.scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved) {
      return this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__COMPLETE') ||
        (this.currentUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__TRADING') && this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__TRADE'))
    }
    if (this.deliverable.isBeingTraded()) {
      return this.currentUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__TRADING') && this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__TRADE')
    }
    if (this.deliverable.deliveryStatus == 'ON_HOLD' &&
      this.scopeApprovalFlowService.stateChecks.deliverableStatus.isStateApproved &&
      this.scopeApprovalFlowService.stateChecks.deliverableStatus.isApprover) return this.currentUser.hasPrivilege('SCOPE_DELIVERABLE__STOP')
    return false
  }

  showDeliverableApplyLocationCardDialog() {
    this.onShowDeliverableApplyLocationCardDialog.emit(this.deliverable)
  }

  updateFeeItem(feeItem: FeeItemInstance, deliverable?: Deliverable, component?: ScopeComponent) {
    this.onUpdateFeeItem.emit({ feeItem, deliverable, component })
  }

  overrideQuantity($event: { deliverable: Deliverable, component: ScopeComponent }) {
    this.onOverrideQuantity.emit($event)
  }

  overrideRateCardRoleHours($event: { deliverable: Deliverable, component: ScopeComponent, role: Role }) {
    this.onOverrideRateCardRoleHours.emit($event)
  }

  protected readonly Privilege = Privilege
  protected readonly trackById = trackById;
}
