import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { BootstrapActions, BootstrapActionTypes } from '../actions';
import { BootstrapConfig } from '../../model/bootstrap-config.model';
import { CompanyType } from '@app/core/model/enums/company-type.enum';
import { BootstrapService } from '@core/service/bootstrap.service';
import { IdpDiscovery } from '@app/core/model/definitions/idp-discovery.interface';
import { Store, select } from '@ngrx/store';
import { BootstrapSelectors } from '../selectors/bootstrap.selector';
import { IdpDiscoveryAction } from '../models/bootstrap.action.interface';
import { Router } from '@angular/router';
import { environment } from '@envs/environment';
import { enumToString } from '@shared/utils/utils';
import { UserService } from '@app-features/user-account/services/user.service';
import { Preference, UserPreferences } from '@core/model/user-preferences.interface';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import {
  scopeColumnBaseConfig,
  deliverableColumnBaseConfig, taskColumnBaseConfig,
} from '@app/features/scoping/models/scope-columns.model';
import { ScopeActions, ScopesActionTypes } from '@app/features/scoping/store/actions/scoping.actions';
import { ScopingSelectors } from '@app/features/scoping/store/selectors/scoping.selector';
import { plainToInstance } from 'class-transformer';
import { UserPreferencesColumns } from '../../model/enums/user-preferences-columns.enum';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type';

@Injectable()
export class BootstrapEffects {
  companyType!: string | CompanyType;

  constructor(
    private actions$: Actions,
    private boostrapService: BootstrapService,
    private userService: UserService,
    private store: Store,
    private router: Router,
    private mappingService: DataMappingService
  ) { }

  loadCdnConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.LOAD_BOOTSTRAP_CONFIG),
      switchMap(() => {
        return this.boostrapService.loadBootstrapConfig().pipe(
          map((cdnConfig) => BootstrapActions.loadBootstrapConfigSuccess({ bsConfig: plainToInstance(BootstrapConfig, cdnConfig) })),
          catchError((error) => {
            console.error(error)
            return of(BootstrapActions.loadBootstrapConfigFail({ error }))
          })
        );
      })
    );
  });

  loadCdnConfigSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.LOAD_BOOTSTRAP_CONFIG_SUCCESS),
      switchMap((action: { bsConfig: BootstrapConfig }) => {
        this.companyType =
          action.bsConfig?.companyBootstrap?.companyType ??
          (enumToString(CompanyType.AGENCY, CompanyType) as string);
        return of(BootstrapActions.setCompanyType({ companyType: this.companyType }));
      })
    );
  });

  loadCdnConfigFailure$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(BootstrapActionTypes.LOAD_BOOTSTRAP_CONFIG_FAILURE));
    },
    { dispatch: false }
  );

  loadUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BootstrapActionTypes.LOAD_USER_PREFERENCES,
        ScopesActionTypes.GET_MY_SCOPES_COLUMNS),
      concatMap(() =>
        forkJoin({
          myScopesColumns: this.userService.getUserPreferences(UserPreferencesColumns.MYSCOPES_USERCOLUMNS).pipe(
            map((preferences) =>
              this.mappingService.mergePreferencesWithTableConfig(scopeColumnBaseConfig, preferences as TableColumnPreferences)
            ),
            map((preferences) => ScopeActions.getMyScopesMappedColumnsSuccess({ preferences })),
            catchError((error) => {
              console.error(error)
              return of(BootstrapActions.setMyScopesColumnsFail(error))
            })
          ),
          scopeOverviewColumns: this.userService
            .getUserPreferences(UserPreferencesColumns.SCOPEOVERVIEW_USERCOLUMNS)
            .pipe(
              map((preferences) =>
                this.mappingService.mergeDeliverablePreferencesWithTableConfig(deliverableColumnBaseConfig, preferences as TableColumnPreferences)
              ),
              map((preferences) => ScopeActions.getScopeMappedColumnsSuccess({ preferences })),
              catchError((error) => {
                console.error(error)
                return of(BootstrapActions.setScopeOverviewColumnsFail(error))
              })
            ),
          sowOverviewColumns: this.userService.getUserPreferences(UserPreferencesColumns.SOWOVERVIEW_USERCOLUMNS).pipe(
            map((preferences) => BootstrapActions.setSowOverviewColumnsSuccess({ preferences })),
            catchError((error) => {
              console.error(error)
              return of(BootstrapActions.setSowOverviewColumnsFail(error))
            })
          ),
        }).pipe(mergeMap((actions) => Object.values(actions)))
      )
    )
  );

  updateScopePreferences = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.UPDATE_COLUMN_PREFERENCES),
      withLatestFrom(this.store.pipe(select(ScopingSelectors.selectMyScopesColumnsPreferences))),
      switchMap(([, userPreferences]) => {
        return this.userService.setUserPreferences(UserPreferencesColumns.MYSCOPES_USERCOLUMNS, userPreferences);
      }),
      map(() => BootstrapActions.updateScopeColumnPreferencesSuccess()),
      catchError((error) => {
        console.error(error)
        return of(BootstrapActions.updateScopeColumnPreferencesFail(error))
      })
    );
  });

  updateScopeOverviewPreferences = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.UPDATE_OVERVIEW_COLUMN_PREFERENCES),
      withLatestFrom(this.store.pipe(select(ScopingSelectors.selectScopeOverviewColumns))),
      switchMap(([, userPreferences]) => {
        return this.userService.setUserPreferences(UserPreferencesColumns.SCOPEOVERVIEW_USERCOLUMNS, userPreferences);
      }),
      map(() => BootstrapActions.updateScopeOverviewColumnPreferencesSuccess()),
      catchError((error) => {
        console.error(error)
        return of(BootstrapActions.updateScopeColumnPreferencesFail(error))
      })
    );
  });

  /* SAML Login */
  idpDiscovery = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.IDP_DISCOVERY),
      map((action: { username: string }) => action.username),
      switchMap((username: string) =>
        this.boostrapService
          .samlIdpDiscovery(username)
          .pipe(map((idpDiscovery: IdpDiscovery) => BootstrapActions.idpDiscoverySuccess({ idpDiscovery })))
      )
    );
  });

  idpSamlLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.IDP_SAML_LOGIN),
      withLatestFrom(
        this.store.pipe(select(BootstrapSelectors.selectIdpDiscovery)),
        this.store.pipe(select(BootstrapSelectors.selectBootstrapConfig))
      ),
      filter(
        ([action, idpDiscovery, bootstrapConfig]: [IdpDiscoveryAction, IdpDiscovery, BootstrapConfig | null]) =>
          bootstrapConfig !== null
      ),
      switchMap(([action, idpDiscovery, bootstrapConfig]: [IdpDiscoveryAction, IdpDiscovery, BootstrapConfig]) => {
        const idp = idpDiscovery.idp || bootstrapConfig.companyBootstrap?.samlDiscovery?.idp;
        const externalUrl = `${environment.scopeApiUrl}/saml/login?idp=${idp}`;
        this.router.navigate(['/externalRedirect', { externalUrl }], { skipLocationChange: true });
        return of(BootstrapActions.idpSamlLoginSuccess({ idpDiscovery }));
      }),
      catchError((error) => {
        console.error(error)
        return of(BootstrapActions.idpSamlLoginFail({ error }))
      })
    );
  });

  loadTaskPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.LOAD_TASK_PREFERENCES),
      switchMap(() => {
        return this.userService.getUserPreferences(UserPreferencesColumns.TASKS_USERCOLUMNS).pipe(
          map((preferences) =>
            this.mappingService.mergeTaskPreferencesWithTableConfig(taskColumnBaseConfig, preferences as TableColumnPreferences)
          ),
          map((preferences) => BootstrapActions.loadTaskPreferencesSuccess({ preferences })),
          catchError((error) => {
            console.error(error)
            return of(BootstrapActions.loadTaskPreferencesFail({ error }))
          })
        );
      })
    );
  });

  updateTaskPreferences = createEffect(() => {
    return this.actions$.pipe(
      ofType(BootstrapActionTypes.UPDATE_TASK_COLUMN_PREFERENCES),
      map((action: { preference: Preference }) => action.preference),
      withLatestFrom(this.store.pipe(select(CompanyManagementSelectors.selectTaskColumnUserPreferences))),
      switchMap(([actionPreference, currentPreferences]) => {
        const updatedPreferences: UserPreferences = { ...currentPreferences };
        updatedPreferences[actionPreference.key] = actionPreference;

        return this.userService.setUserPreferences(UserPreferencesColumns.TASKS_USERCOLUMNS, updatedPreferences);
      }),
      map(() => BootstrapActions.updateTaskColumnPreferencesSuccess()),
      catchError((error) => {
        console.error(error)
        return of(BootstrapActions.updateTaskColumnPreferencesFail(error))
      })
    );
  });
}
