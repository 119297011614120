<div class="approval-bar">
  <div *ngIf="isTemplate"></div>
  <ol *ngIf="!isTemplate">
    <li [class.approval-active-state]="currentApprovalState === 'DRAFT'">
      Draft
      <scope-tooltip
        *ngIf="currentApprovalState === 'DRAFT'"
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser"
        [currentScope]="currentScope"
        (onSubmitDraft)="submitDraft()"
        (onWithdrawSubmission)="withdrawSubmission()" />
    </li>
    <li [class.approval-active-state]="currentApprovalState === 'SUBMITTED'">
      In Review
      <scope-tooltip
        *ngIf="currentApprovalState === 'SUBMITTED' && stateChecks.inReviewState.currentMember"
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser"
        (onCompleteReview)="completeReview()" />
    </li>
    <li [class.approval-active-state]="currentApprovalState === 'REVIEWED'">
      Approved
      <scope-tooltip
        *ngIf="currentApprovalState === 'REVIEWED'"
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser"
        (onAgreeToScope)="agreeToScope()"
        (onDeclineScope)="declineScope()" />
    </li>
    <li
      [class.approval-active-state]="currentApprovalState === 'AGENCY_APPROVED'"
      [has-setting]="'SCOPE__APPROVAL__STATUS_CLIENT'">
      Client Approval
      <scope-tooltip
        *ngIf="currentApprovalState === 'AGENCY_APPROVED'"
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser"
        (onAcceptScope)="acceptScope()"
        (onRejectScope)="rejectScope()" />
    </li>
    <li
      [class.approval-active-state]="
        currentUser.company.hasApplicationSetting('SCOPE__APPROVAL__STATUS_CLIENT')
          ? currentApprovalState === 'CLIENT_APPROVED'
          : currentApprovalState === 'AGENCY_APPROVED'
      ">
      Client Accepted
      <scope-tooltip
        *ngIf="
          currentUser.company.hasApplicationSetting('SCOPE__APPROVAL__STATUS_CLIENT')
            ? currentApprovalState === 'CLIENT_APPROVED'
            : currentApprovalState === 'AGENCY_APPROVED'
        "
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser"
        (onCloseScope)="closeScope()" />
    </li>
    <li [class.approval-active-state]="currentApprovalState === 'TRAFFICKED'">
      Trafficked
      <scope-tooltip
        *ngIf="currentApprovalState === 'TRAFFICKED'"
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser"
        (onCloseScope)="closeScope()" />
    </li>
    <li [class.approval-active-state]="currentApprovalState === 'CLOSED'">
      Closed
      <scope-tooltip
        *ngIf="currentApprovalState === 'CLOSED'"
        [stateChecks]="stateChecks"
        [currentState]="currentApprovalState"
        [currentUser]="currentUser" />
    </li>
  </ol>

  <div class="approval-collabarators">
    <div class="approval-collaborators-list">
      <div class="relative pointer" *ngFor="let member of stateChecks.currentStateMembers | slice:0:8; trackBy: trackById">
        <div (click)="openUserTooltip(member)" class="approval-collaborators-list-item">
          <ng-container *ngIf="{ showAlt: false } as memberScope">
            <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder">
              <p>{{ member.user.firstName.charAt(0) }}</p>
            </div>
            <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder" src="{{cdnConfig!.userProfileUrl + '/' + member.user.photoUri}}" alt="" (error)="memberScope.showAlt = true">
          </ng-container>
        </div>
        <div *ngIf="member === selectedMember" class="scope-tooltip">
          <p>{{ member.user.fullName }}</p>
          <p class="current-member-state" *ngIf="stateChecks.overallState.currentMemberState">
            Not {{ stateChecks.overallState.currentMemberState }}
          </p>

          <p
            *ngIf="
              stateChecks.isCurrentVersion &&
              (stateChecks.isInReview ||
                stateChecks.isStateReviewed) &&
              currentUser.id != member.user.id
            "
            class="send-approval-reminder"
            (click)="sendReminder()">
            Send {{ stateChecks.isInReview ? 'Review' : 'Approval' }} Reminder
          </p>
        </div>
      </div>

      <div class="relative pointer" *ngIf="stateChecks.currentStateMembers?.length > 8">
        <div (click)="toggleExtendedMembers()" class="approval-collaborators-list-item">
          <div class="user-avatar-placeholder">
            <p>{{stateChecks.currentStateMembers?.length - 8}}</p>
          </div>
        </div>
        <div class="scope-tooltip" *ngIf="showExtendedMembersList">
          <ul>
            <li *ngFor="let member of stateChecks.currentStateMembers | slice:8; trackBy: trackById">
              <div class="flex items-center">
                <ng-container *ngIf="{ showAlt: false } as memberScope">
                  <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder d-flex-inline mr-2">
                    <p>{{ member.user.firstName.charAt(0) }}</p>
                  </div>
                  <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder d-flex-inline mr-2" src="{{cdnConfig!.userProfileUrl + '/' + member.user.photoUri}}" alt="" (error)="memberScope.showAlt = true">
                </ng-container>
                <div class="flex-col d-flex-inline">
                  <p>{{ member.user.fullName }}</p>
                  <p class="current-member-state" *ngIf="stateChecks.overallState.currentMemberState">
                    Not {{ stateChecks.overallState.currentMemberState }}
                  </p>

                  <p
                    *ngIf="
                      stateChecks.isCurrentVersion &&
                      (stateChecks.isInReview ||
                        stateChecks.isStateReviewed) &&
                      currentUser.id != member.user.id
                    "
                    class="send-approval-reminder"
                    (click)="sendReminder()">
                    Send {{ stateChecks.isInReview ? 'Review' : 'Approval' }} Reminder
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="scope-add-team-member"
      *ngIf="stateChecks.overallState.showQuickAddMember"
      (click)="showAddTeamMemberModal()">
      <div class="scope-add-team-member-wrapper">
        <i class="material-symbols-rounded">person_add</i>
      </div>
    </div>

    <button (click)="openExportModal()" class="scope-output">
      <span> <i class="material-symbols-rounded">download</i> </span>
      <span>Output</span>
    </button>
    <button
      class="scope-traffic"
      [class.disabled]="stateChecks.traffickedState.isTraffickedDisabled"
      *ngIf="((!isTemplate &&  companyIntegrations.length > 0 && stateChecks.traffickedState.currentMember) || (stateChecks.traffickedState.isTraffickable ||
      (currentUser.company.hasWorkatoIntegration && stateChecks.traffickedState.isTraffickableThroughWorkato) )
      && !isTemplate && stateChecks.traffickedState.currentMember)"
      [disabled]="stateChecks.traffickedState.isTraffickedDisabled"
      (click)="exportToIntegration()">
      <span>
        <i class="material-symbols-rounded">
          {{
            stateChecks.traffickedState.isTraffickedDisabled
              ? 'traffic'
              : stateChecks.traffickedState.isTrafficking
              ? 'sync'
              : stateChecks.traffickedState.scopeTraffickingComponent
              ? 'sync_problem'
              : 'swap_vert'
          }}
        </i>
      </span>
      <span>{{
        stateChecks.traffickedState.isTrafficking
          ? 'Trafficking..'
          : !stateChecks.traffickedState.isReadyToTraffic
          ? 'Trafficked'
          : stateChecks.traffickedState.scopeTraffickingComponent
          ? 'View Errors'
          : 'Traffic'
      }}</span>
    </button>
  </div>
</div>
