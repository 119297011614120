<scope-ui-tabs fontSize="13px" (onSelect)="onSelect($event)">
  <scope-ui-tab tabTitle="Company Details" routeLink="details" [active]='!route.snapshot.queryParamMap.get("tab") || route.snapshot.queryParamMap.get("tab") === "details"'
                requiredPrivilege="COMPANY_DETAILS__VIEW">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Accounts" routeLink="child-accounts" [active]='route.snapshot.queryParamMap.get("tab") === "child-accounts"'
                [hidden]="authorisedAsChildCompany() !== null || !(loggedInUser.company.parentCompany == null && parentChildService.childCompanies != null && parentChildService.childCompanies.length > 0)">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Integration Settings" routeLink="integration" [active]='route.snapshot.queryParamMap.get("tab") === "integration"'
                requiredPrivilege="COMPANY_TRAFFIC_SYSTEM__VIEW">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Application Settings" routeLink="application" [active]='route.snapshot.queryParamMap.get("tab") === "application"'
                requiredPrivilege="COMPANY_APPLICATION_SETTINGS__VIEW">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Data" routeLink="data" [active]='route.snapshot.queryParamMap.get("tab") === "data"'
                requiredPrivilege="COMPANY_PREDICTION_DATA__VIEW">
    <div class="manage-tab-wrapper">
      <manage-data class="flex flex-row h-full"></manage-data>
    </div>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Office Locations" routeLink="office-locations" [active]='route.snapshot.queryParamMap.get("tab") === "office-locations"'
                requiredPrivilege="OFFICE_LOCATION__VIEW" [hidden]="authorisedAsChildCompany() !== null">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="{{'scope_type' | lang }}" routeLink="scope-types" [active]='route.snapshot.queryParamMap.get("tab") === "scope-types"'
                requiredPrivilege="SCOPE_TYPE__VIEW" requiredSetting="SCOPE__TYPE_VIEW">
    <div class="manage-tab-wrapper">
      <scope-types></scope-types>
    </div>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="{{'second_party.p' | lang }}" routeLink="clients" [active]='route.snapshot.queryParamMap.get("tab") === "clients"'
                requiredPrivilege="SECOND_PARTY__VIEW">
    <div class="manage-tab-wrapper">
      <second-parties></second-parties>
    </div>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Rate Cards" routeLink="rate cards" [active]='route.snapshot.queryParamMap.get("tab") === "rate cards"'
                requiredPrivilege="RATECARD__VIEW && SCOPE__VIEW_RATES">
    <div class="manage-tab-wrapper">
      <rate-cards></rate-cards>
    </div>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Members" routeLink="members" [active]='route.snapshot.queryParamMap.get("tab") === "members"'
                requiredPrivilege="COMPANY_MEMBERS__VIEW">
    <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Groups" routeLink="groups" [active]='route.snapshot.queryParamMap.get("tab") === "groups"'
                requiredPrivilege="COMPANY_GROUP__VIEW" [hidden]="authorisedAsChildCompany() !== null">
    <div class="manage-tab-wrapper">
      <groups *ngIf="!(groupId$ | async)"></groups>
      <group *ngIf="groupId$ | async as groupId" [id]="groupId"></group>
    </div>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="{{'doc_template' | lang }} templates" routeLink="output" [active]='route.snapshot.queryParamMap.get("tab") === "output"'
                requiredPrivilege="OUTPUT_TEMPLATE__VIEW">
    <output-templates *ngIf='!route.snapshot.queryParamMap.get("id")' class="flex flex-col"></output-templates>
    <document-template
      *ngIf='route.snapshot.queryParamMap.get("type") === "document" && route.snapshot.queryParamMap.get("id")'
      class="flex flex-col h-full"></document-template>
    <spreadsheet-template
      *ngIf='route.snapshot.queryParamMap.get("type") === "spreadsheet" && route.snapshot.queryParamMap.get("id")'
      class="flex flex-col h-full"></spreadsheet-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Scope Fields"  routeLink="scope fields" [active]='route.snapshot.queryParamMap.get("tab") === "scope fields"'
                [hidden]="!loggedInUser.hasAccessRole(UserRoleLevel.SYSTEM) && !loggedInUser.hasAccessRole(UserRoleLevel.ADMIN)" *ngIf='loggedInUser?.company.hasApplicationSetting("SCOPE_DYNAMIC_FIELDS")'>
      <div class="manage-tab-wrapper">
        <app-scope-dynamic-field-setting></app-scope-dynamic-field-setting>
      </div>
  </scope-ui-tab>
</scope-ui-tabs>
