import { Injectable } from '@angular/core';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import _ from 'lodash';
import { EnvService } from '@envs/env-service';
import { User } from '@core/model/user.model';
import { Store } from '@ngrx/store';
import { AuthActions } from '@core/store';
import { EventType } from '@app/core/model/enums/event-type.enum';
import { AuthService } from '@core/service/auth.service'
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';
import { ScopeOverviewService } from '@app/features/scope-overview/service/scope-overview.service';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  stompClient: Stomp.Client
  connected: boolean
  connecting: boolean
  showModal: boolean
  connectLostRetries: number
  MAX_RETRY: number
  subscriptions = {};

  constructor(private envService: EnvService, private store: Store, private authService: AuthService, private snackbarService: SnackbarService, private scopeOverviewService: ScopeOverviewService) {
    const socket = new SockJS(`${envService.wsUrl}notify`);
    this.stompClient = Stomp.over(socket);
    this.connected = false;
    this.connecting = false;
    this.subscriptions = {};
    this.showModal = false
    this.connectLostRetries = 0
    this.MAX_RETRY = 5
  }

  connect() {
    if ((this.stompClient && this.connected) || this.connecting || this.showModal) return;

    var that = this;
    this.connecting = true;
    this.stompClient = this.stompConnect(function () {
      that.connectLostRetries = 0;
      that.connecting = false;

      console.debug("Websocket connection success!");
      that.connected = true;

      //Recovery
      Object.keys(that.subscriptions).forEach(function (subKey) {
        var sub = that.subscriptions[subKey];
        sub.subscription = that.subscribe(that.stompClient, sub);
      })
    }, function(errorMsg: any) {
      that.connecting = false;

      if (!(errorMsg instanceof String) || errorMsg.indexOf("Whoops") > -1){
        that.connected = false;
        console.debug("Lost Websocket connection - Retrying");

        if (++that.connectLostRetries < that.MAX_RETRY){
          setTimeout(function(){
            that.connect();
          }, 1000);
        }

        if (that.connectLostRetries >= that.MAX_RETRY){
          // TODO show modal about unavailable connection with scope.expert
          // that.showModal = true;
        }
      }
    })
  }

  stompConnect(onSuccess: any, onError: any){
    this.stompClient.connect({}, onSuccess, onError);
    this.stompClient.debug = null;
    return this.stompClient;
  }

  registerReceiver(url: string, listener: any) {
    let subscription = this.subscriptions[url];
    if (!subscription){
      subscription = this.subscriptions[url] = {
        url: url,
        listeners: {}
      };

      if (!this.connected) {
        console.debug("Unable to subscribe at this time. " + url);
      } else {
        subscription.subscription = this.subscribe(this.stompClient, subscription);
      }
    }
    const listenerID = Math.random();
    subscription.listeners[listenerID] = listener;
    console.debug("Registered websocket listener on existing url: " + url);
    return new RegisteredSubscription(url, listenerID);
  };

  unregisterReceiver(sub: RegisteredSubscription) {
    let subscription = this.subscriptions[sub.url];
    if (!subscription){
      return;
    }

    delete subscription.listeners[sub.listenerID];
    if (subscription.listeners.length == 0){
      subscription.subscription.unsubscribe();
      delete this.subscriptions[sub.url]
      console.debug("Unregistered websocket listener on url: " + sub.url);
    }
  };


  registerTrafficReportReceiver(reportSubscriptionId: string, listener: any) {
    const url = this.createUrl(NotificationTopicType.SCOPE_TRAFFIC_REPORT_EVENT, { reportSubscriptionId: reportSubscriptionId });
    return this.registerReceiver(url, listener);
  }

  registerScopesEventReceiver(listener: any) {
    const url = this.createUrl(NotificationTopicType.SCOPES_EVENT, { userId: this.authService.loggedInUser.id });
    return this.registerReceiver(url, listener);
  }

  registerScopeEventReceiver(scopeIdentityId: number | string, listener: any) {
    const url = this.createUrl(NotificationTopicType.SCOPE_EVENT, { scopeId: scopeIdentityId });
    return this.registerReceiver(url, listener);
  }

  registerUserTransactionEventReceiver(transactionId: number, listener: any) {
    const url = this.createUrl(NotificationTopicType.USER_TRANSACTION_EVENT, { transactionId: transactionId, userId: this.authService.loggedInUser.id });
    return this.registerReceiver(url, listener);
  }

  registerSowEventReceiver(sowId: number, listener: any) {
    const url = this.createUrl(NotificationTopicType.SOW_EVENT, { sowId: sowId });
    return this.registerReceiver(url, listener);
  }

  registerDeliverableEventReceiver(scopeId: number, deliverableId: number, listener: any) {
    const url = this.createUrl(NotificationTopicType.DELIVERABLE_EVENT, { scopeId: scopeId, deliverableId: deliverableId });
    return this.registerReceiver(url, listener);
  }

  registerLibraryFolderEventReceiver(folderId: number, listener: any) {
    const url = this.createUrl(NotificationTopicType.LIBRARY_EVENT, { libraryId: folderId });
    return this.registerReceiver(url, listener);
  }

  registerCompanyEventReceiver(companyId: number, listener: any) {
    const url = this.createUrl(NotificationTopicType.COMPANY_EVENT, { companyId: companyId });
    return this.registerReceiver(url, listener);
  }

  registerUserEventReceiver(listener: any) {
    const url = this.createUrl(NotificationTopicType.USER_EVENT, { userId: this.authService.loggedInUser.id });
    return this.registerReceiver(url, listener);
  }

  onUserEventReceived = (event: NotificationEvent) => {
    this.refreshNotifications(event);

    switch (event.object.eventType) {
      case "DATA_EXPORT_SUCCESS":
        let context = JSON.parse(event.object.properties.context)
        let companyId = context.companyId ? context.companyId : context
        this.scopeOverviewService.downloadDataExport(event.object.properties.uuid, companyId)
        break
      case "DATA_EXPORT_FAILED": {
        this.snackbarService.showPrioritisedSnackbar("Export generation failed!", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
        break
      }
    }
  }

  registerRequiredListeners() {
    this.registerUserEventReceiver((event: any) => this.onUserEventReceived.call(this, event));
    this.registerCompanyEventReceiver(this.authService.loggedInUser.company.id, this.refreshNotifications);
  }

  isUserSpecificDest(dest: string){
    let match = dest.match("/user");
    return match && match.index == 0;
  }

  subscribe(stompClient: Stomp.Client, sub: any) {
    if (stompClient == null) {
      throw new Error("No stomp client initialised!")
    }
    if (stompClient.connected) {
      let subscription = stompClient.subscribe(sub.url, (response) => {
        let destination = response.headers['destination'];
        let notification = this.NotificationEventParser.parse(this.authService.loggedInUser, JSON.parse(response.body));
        if (notification.canExecute || this.isUserSpecificDest(destination)){
          Object.values(sub.listeners).forEach((listener) => {
            this.refreshNotifications(notification)
            // @ts-ignore
            listener(notification);
          });
        }
      });
      console.debug("Subscribed to " + sub.url);
      return subscription;
    } else {
      throw Error("Unable to subscribe")
    }
  }

  refreshNotifications = (event: NotificationEvent) => {
    if (event.type !== EventType.DELIVERABLE_ACTUALS_UPDATED && event.type !== EventType.SCOPE_DELIVERABLE_ACTUALS_UPDATED) {
      this.store.dispatch(AuthActions.getUserNotifications())
    }
  }

  NotificationEventParser = {
    parse : function(loggedInUser: User, rawEventObject: any){
      let eventType = EventType[rawEventObject.type];
      let notif = new NotificationEvent();
      notif.type = eventType
      notif.canExecute = true;
      notif.initiatedBy = rawEventObject.initiatedBy ? rawEventObject.initiatedBy as User : null;
      notif.object = rawEventObject.event ? rawEventObject.event : null

      if (rawEventObject.event.error) {
        notif.error = rawEventObject.event.error ? rawEventObject.event.error : null
        notif.isAuthError = rawEventObject.event.isAuthError ? rawEventObject.event.isAuthError : null
        notif.errorType = rawEventObject.event.errorType ? rawEventObject.event.errorType : null
      }
      return notif;
    },
    map : function(mapTo, rawEventObject){
      var mapped = rawEventObject;
      if (mapTo && !_.isNumber(mapped)) {
        if (mapTo instanceof Array){
          mapped = this.mapFromEventTypeMapperArray(mapTo, rawEventObject)
        }
      }
      return mapped;
    },
    mapCustom: function(mapTo, rawEventObject){
      var json = {};
      for (var key in mapTo){
        if (mapTo.hasOwnProperty(key)){
          var propVal = mapTo[key];
          var newVal;
          if (propVal.isEventTypeMapper){
            var val = this.mapByEventType(propVal.key, rawEventObject);
            if (val){
              newVal = val;
            }
          }
          else if (typeof propVal === "string"){
            if (propVal[0] === "'"){
              newVal = propVal;
            } else {
              newVal = this.findValueFromPath(propVal, rawEventObject);
            }
          }
          json[key] = newVal;
        }
      }
      return json;
    },
    mapByEventType: function(mapperKey, rawEventObject) {
        return this.mapFromEventTypeMapper(null, rawEventObject);
    },
    findValueFromPath: function(path, rawEventObject){
      var current = rawEventObject;
      path.split('.').forEach(function(propKey){
        var key = propKey;
        var strictMode = true;
        if (key.startsWith('?')){
          key = key.substr(1, key.length);
          strictMode = false;
        }
        var found = current[key];
        if (found == null && strictMode){
          throw new Error("unable to locate " + key + " from path " + path + " on object " + rawEventObject);
        }
        current = found;
      });
      return current;
    },
    mapFromEventTypeMapperArray: function(mapTo, rawEventObject){
      var mapper = mapTo[0];
      var path = mapTo[1];
      if (mapper.isEventTypeMapper && typeof path === "string"){
        var pathElement = rawEventObject[path];
        if (!pathElement){
          throw new Error(mapper.key + ": Path to element does not exist " + path + ". " + JSON.stringify(rawEventObject));
        }
        return this.mapFromEventTypeMapper(mapper, pathElement);
      } else {
        throw new Error("MapTo array must have mapper first then path. Mapper: " + JSON.stringify(mapper) + " path: " + JSON.stringify(path));
      }
    },
    mapFromEventTypeMapper: function(mapper, rawEventObject){
      return rawEventObject
      // if (mapper === EventType.EventTypeMappers.SOW){
      //   return sowDataMapper.mapSOWVersionResponse(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.SCOPE){
      //   return scopeDataMapper.mapScopeFromApi(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.DELIVERABLE){
      //   return scopeDataMapper.mapDeliverableFromApi(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.LIBRARY){
      //   return libraryDataMapper.mapLibraryItemFromApi(rawEventObject[mapper.defaultObjectKey]);
      // }
      // if (mapper === EventType.EventTypeMappers.COMPONENT){
      //   return scopeDataMapper.mapComponentFromApi(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.DELIVERABLE_TPC){
      //   return scopeDataMapper.mapCostsFromApi([rawEventObject])[0];
      // }
      // if (mapper === EventType.EventTypeMappers.COMPONENT_TPC){
      //   return scopeDataMapper.mapComponentCostsFromApi([rawEventObject])[0];
      // }
      // if (mapper === EventType.EventTypeMappers.SECTION){
      //   return scopeDataMapper.mapDeliverableSectionFromApi(rawEventObject);
      // }
      //
      // if (mapper === EventType.EventTypeMappers.SECTION_TPC) {
      //   return scopeDataMapper.mapSectionCostsFromApi([rawEventObject])[0];
      // }
      //
      // if (mapper === EventType.EventTypeMappers.SCOPE_TEAM_MEMBER){
      //   return scopeDataMapper.mapScopeTeamMemberFromApi(rawEventObject)
      // }
      // if (mapper === EventType.EventTypeMappers.SCOPE_COLLABORATOR){
      //   return scopeDataMapper.mapCollaboratorsFromApi(rawEventObject)[0]
      // }
      // if (mapper === EventType.EventTypeMappers.USER_NOTIFICATION){
      //   return userDataMapper.mapUserNotification(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.USER){
      //   return userDataMapper.mapUserFromApi(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.COMPANY){
      //   return userDataMapper.mapCompanyFromApi(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.COMMENT){
      //   return scopeDataMapper.mapCommentFromApi(rawEventObject);
      // }
      // if (mapper === EventType.EventTypeMappers.RAW_JSON){
      //   return rawEventObject;
      // }
      throw new Error("Mapper not found " + mapper);
    }
  };

   DESTINATION_TOPIC = "/topic";
   USER_INFO_EVENT_URL = "/user/queue/info";
   USER_TRANSACTION_EVENT_URL = "/user/queue/transaction/{transactionId}";
   SCOPE_EVENT_URL = "/scope/{scopeId}";
   SOW_EVENT_URL = "/sow/{sowId}";
   USER_SCOPE_EVENT_URL = "/user/queue/scope";
   DELIVERABLE_EVENT_URL = "/scope/{scopeId}/deliverable/{deliverableId}";
   LIBRARY_EVENT_URL = "/library/{libraryId}";
   COMPANY_EVENT_URL = "/company/{companyId}";
  SCOPE_TRAFFIC_REPORT_EVENT_URL = '/topic/traffic-report';

  createUrl(notificationTopicType: NotificationTopicType, params: any) {
    switch (notificationTopicType) {
      case (NotificationTopicType.SCOPE_EVENT):
        if (!params || !params.scopeId) {
          throw new Error("params.scopeId must be specified");
        }
        return this.DESTINATION_TOPIC + this.SCOPE_EVENT_URL
          .replace('{scopeId}', params.scopeId);

      case (NotificationTopicType.USER_EVENT):
        return this.USER_INFO_EVENT_URL;

      case (NotificationTopicType.SCOPE_TRAFFIC_REPORT_EVENT):
        return `${this.SCOPE_TRAFFIC_REPORT_EVENT_URL}/${params.reportSubscriptionId}`
      case (NotificationTopicType.SOW_EVENT):
        if (!params || !params.sowId) {
          throw new Error("params.sowId must be specified");
        }
        return this.DESTINATION_TOPIC + this.SOW_EVENT_URL
          .replace('{sowId}', params.sowId);

      case (NotificationTopicType.USER_TRANSACTION_EVENT):
        if (!params || !params.transactionId) {
          throw new Error("params.transactionId must be specified");
        }
        if (!params.userId) {
          throw new Error("params.userId must be specified");
        }
        return this.USER_TRANSACTION_EVENT_URL
          .replace('{transactionId}', params.transactionId)
          .replace('{userId}', params.userId);

      case (NotificationTopicType.COMPANY_EVENT):
        if (!params || !params.companyId) {
          throw new Error("params.companyId must be specified");
        }
        return this.DESTINATION_TOPIC + this.COMPANY_EVENT_URL
          .replace('{companyId}', params.companyId);

      case (NotificationTopicType.SCOPES_EVENT):
        return this.USER_SCOPE_EVENT_URL;

      case (NotificationTopicType.DELIVERABLE_EVENT):
        if (!params || !params.scopeId) {
          throw new Error("params.scopeId must be specified");
        }
        if (!params || !params.deliverableId) {
          throw new Error("params.deliverableId must be specified");
        }
        return this.DESTINATION_TOPIC + this.DELIVERABLE_EVENT_URL
          .replace("{scopeId}", params.scopeId)
          .replace('{deliverableId}', params.deliverableId)
      case (NotificationTopicType.LIBRARY_EVENT):
        if (!params || !params.libraryId) {
          throw new Error("params.libraryId must be specified");
        }
        return this.DESTINATION_TOPIC + this.LIBRARY_EVENT_URL
          .replace('{libraryId}', params.libraryId);

    }
    throw new Error(notificationTopicType + " is not a valid NotificationTopicType");
  }
}

export class NotificationEvent {
  initiatedUserId!:any
  object!:any
  type!:any
  canExecute!: any
  initiatedBy!: User
  error!: string
  errorType!: string
  isAuthError!: boolean
}

export class RegisteredSubscription {
  url: string
  listenerID: number

  constructor(url: string, listenerID: number) {
    this.url = url
    this.listenerID = listenerID
  }
}

export enum NotificationTopicType {
  SOW_EVENT,
  SCOPE_EVENT,
  SCOPE_TRAFFIC_REPORT_EVENT,
  DELIVERABLE_EVENT ,
  COMPANY_EVENT ,
  SCOPES_EVENT ,
  USER_EVENT ,
  USER_TRANSACTION_EVENT ,
  LIBRARY_EVENT
}

export interface ReportSubscription {
  subId: string;
}

