import { createSelector } from '@ngrx/store';
import * as fromFeature from '@core/store/reducers/index';
import { BootstrapState } from '../reducers/bootstrap.reducer';
import { BootstrapConfig } from '@core/model/bootstrap-config.model';
import { CdnConfig } from "@app/core/model/cdn-config.model";
import { IdpDiscovery } from '@app/core/model/definitions/idp-discovery.interface';

export const getBootstrapFromState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.CoreState) => state.bootstrapConfig
);

const getBootstrapConfig = (state: BootstrapState): BootstrapConfig | null => state.bootstrapConfig;
const getBootstrapLoaded = (state: BootstrapState): boolean => state.loaded;
const getBootstrapLoading = (state: BootstrapState): boolean => state.loading;
const getCdnConfig = (state: BootstrapState): CdnConfig | undefined => state.bootstrapConfig?.cdnConfig;
const getIdpDiscovery = (state: BootstrapState): IdpDiscovery => state.idpDiscovery;
const getCompanyType = (state: BootstrapState): any => state.companyType;

//BOOTSTRAP
export const selectBootstrapConfig = createSelector(getBootstrapFromState, getBootstrapConfig);
export const selectBootstrapLoaded = createSelector(getBootstrapFromState, getBootstrapLoaded);
export const selectBootstrapLoading = createSelector(getBootstrapFromState, getBootstrapLoading);
export const selectCompanyType = createSelector(getBootstrapFromState, getCompanyType);
export const selectCdnConfig = createSelector(getBootstrapFromState, getCdnConfig);
export const selectIdpDiscovery = createSelector(getBootstrapFromState, getIdpDiscovery);

export const BootstrapSelectors = {
  selectBootstrapConfig,
  selectBootstrapLoaded,
  selectBootstrapLoading,
  selectCdnConfig,
  selectIdpDiscovery,
  selectCompanyType,
};
