import {ScopeVersion} from "@core/model/scope-version";
import {Privilege} from "@core/model/enums/privilege.enum";
import {FolderVersion} from "@core/model/folder-version.model";
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { TableColumnPreferences } from '@app/shared/components/ui-components/scope-ui-table/table-column-preferences.type';

export const deliverableColumnBaseConfig: TableColumnPreferences = {
  [TableColumnKey.QUANTITY]: {
    key: TableColumnKey.QUANTITY,
    name: 'Quantity',
    selected: true
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created by',
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Created date',
  },
  [TableColumnKey.START_DATE]: {
    key: TableColumnKey.START_DATE,
    name: 'Start date',
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last edited',
    selected: true
  },
  [TableColumnKey.END_DATE]: {
    key: TableColumnKey.END_DATE,
    name: 'End date',
  },
  [TableColumnKey.MARK_UP]: {
    key: TableColumnKey.MARK_UP,
    name: 'Markup',
  },
  [TableColumnKey.PROFIT]: {
    key: TableColumnKey.PROFIT,
    name: 'Profit',
    selected: true
  },
  [TableColumnKey.SCOPEMARK_HOURS]: {
    key: TableColumnKey.SCOPEMARK_HOURS,
    name: '{LANG:scope_mark} hours',
  },
  [TableColumnKey.SCOPEMARK_PRICE]: {
    key: TableColumnKey.SCOPEMARK_PRICE,
    name: '{LANG:scope_mark} price',
  },
  [TableColumnKey.AGENCY_HOURS]: {
    key: TableColumnKey.AGENCY_HOURS,
    name: '{LANG:agency} hours',
    selected: true
  },
  [TableColumnKey.BUDGET]: {
    key: TableColumnKey.BUDGET,
    name: '{LANG:scope}',
  },
  [TableColumnKey.BALANCE]: {
    key: TableColumnKey.BALANCE,
    name: 'Balance',
  },
  [TableColumnKey.DELIVERY_STATUS]: {
    key: TableColumnKey.DELIVERY_STATUS,
    name: 'Delivery status',
  },
  [TableColumnKey.AGENCY_PRICE]: {
    key: TableColumnKey.AGENCY_PRICE,
    name: '{LANG:agency} price',
    selected: true
  },
  [TableColumnKey.OWNER]: {
    key: TableColumnKey.OWNER,
    name: 'Owner',
  },
  [TableColumnKey.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS]: {
    key: TableColumnKey.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS,
    name: 'Show actuals',
    isVisibleInList: () => false,
  },
};

export const scopeColumnBaseConfig: TableColumnPreferences = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'name',
    valueIconFunction: (entity: any) => {
      if (entity instanceof FolderVersion) return 'format_list_bulleted';
      if (entity instanceof ScopeVersion)
        return entity.isTemplate() ? 'copy_all' : (entity.identity.identificationType === 'SCOPE_BY_ROLE' ? 'table_chart' : 'equalizer')
      
      return ''
    },
    selected: true,
    isVisibleInList: () => false
  },
  [TableColumnKey.SECOND_PARTY]: {
    key: TableColumnKey.SECOND_PARTY,
    name: '{LANG:second_party}',
    field: 'identity.secondParty.name',
  },
  [TableColumnKey.RATECARD]: {
    key: TableColumnKey.RATECARD,
    name: 'Ratecard',
    field: 'identity.rateCard.name',
  },
  [TableColumnKey.STATUS]: {
    key: TableColumnKey.STATUS,
    name: 'Status',
    field: 'status',
    selected: true
  },
  [TableColumnKey.CREATOR]: {
    key: TableColumnKey.CREATOR,
    name: 'Creator',
    field: 'identity.createdBy.fullName',
    selected: true
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'createdBy.company.name',
    selected: true
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date created',
    field: 'createdTs',
  },
  [TableColumnKey.DATE_APPROVED]: {
    key: TableColumnKey.DATE_APPROVED,
    name: 'Date approved',
    field: 'agencyApprovedTs',
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last edited',
    field: 'updatedTs',
  },
  [TableColumnKey.BUDGET]: {
    key: TableColumnKey.BUDGET,
    name: '{LANG:budget}',
    field: 'budget',
  },
  [TableColumnKey.VALUE]: {
    key: TableColumnKey.VALUE,
    name: '{LANG:value}',
    value: (entity: any) => {
      if (entity instanceof FolderVersion) return entity.totalValues || '-';
      if (entity instanceof ScopeVersion) return entity.totalSellingPrice

      return ''
    },
    isVisible: (scope: ScopeVersion) => !scope.containsUnmappedScopeMarkRoles,
    requiredPrivilege: Privilege.SCOPE__VIEW_RATES,
    default: '-',
    noSort: true
  },
  [TableColumnKey.SCOPE_NUMBER]: {
    key: TableColumnKey.SCOPE_NUMBER,
    name: '{LANG:scope} Number',
    field: 'identity.localId',
    selected: true
  },
  [TableColumnKey.SOW_NAME]: {
    key: TableColumnKey.SOW_NAME,
    name: '{LANG:sow}',
    field: 'scopeOfWorkVersion.name',
  },
  [TableColumnKey.BRAND]: {
    key: TableColumnKey.BRAND,
    name: '{LANG:brand}',
    field: 'identity.brand.name',
  },
  [TableColumnKey.REGION]: {
    key: TableColumnKey.REGION,
    name: 'Region',
    field: 'identity.location.region',
    noSort: true
  },
  [TableColumnKey.LOCATION]: {
    key: TableColumnKey.LOCATION,
    name: 'Location',
    field: 'identity.location.location',
  },
  [TableColumnKey.MARGIN]: {
    key: TableColumnKey.MARGIN,
    name: 'Margin',
    field: 'margin',
    requiredSetting: 'SCOPE__MARGIN_SHOW',
    selected: true
  },
};


export const taskColumnBaseConfig: TableColumnPreferences = {
  [TableColumnKey.NAME]: {
    key: TableColumnKey.NAME,
    name: 'NAME',
    field: 'name',
    valueIconFunction: (entity: Deliverable) => entity.fixedPricing ? 'book' : 'task_alt',
    selected: true,
    isVisibleInList: () => false
  },
  [TableColumnKey.RATE_CARD]: {
    key: TableColumnKey.RATE_CARD,
    name: 'Rate Card',
    field: 'rateCardVersion.name',
    selected: true
  },
  [TableColumnKey.RATE_CARD_VERSION]: {
    key: TableColumnKey.RATE_CARD_VERSION,
    name: 'Rate Card Version',
    field: 'rateCardVersion.version',
    selected: true
  },
  [TableColumnKey.CREATED_BY]: {
    key: TableColumnKey.CREATED_BY,
    name: 'Created By',
    field: 'createdBy.fullName',
    selected: true
  },
  [TableColumnKey.DATE_CREATED]: {
    key: TableColumnKey.DATE_CREATED,
    name: 'Date Created',
    field: 'createdTs',
    selected: true
  },
  [TableColumnKey.LAST_EDITED]: {
    key: TableColumnKey.LAST_EDITED,
    name: 'Last Edited',
    field: 'updatedTs',
    selected: true
  }
}
