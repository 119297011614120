import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[imgFallback]',
})
export class FallBackImageDirective {
  @Input() imgFallback!: string;

  constructor(private el: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.el.nativeElement;
    element.src = this.imgFallback || 'https://placehold.co/200'
  }
}
