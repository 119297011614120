import { Type } from 'class-transformer';
import { UserRoleLevel } from './enums/user-role-level.enum';
import { PrivilegeMap, PrivilegeStringMap } from './definitions/privilege.builder';
import { Privilege } from './enums/privilege.enum';
import { PrivilegeDefinition } from './privilege-definition.model';
import { PrivilegeAware } from './definitions/privileged-aware.interface';
import { Company } from './company.model';
import { PrivilegeGroup } from '@core/model/privilege-group.model';

export class User {
  id!: number;
  firstName!: string;
  lastName!: string;
  fullName: string;
  email!: string;
  phoneNumber!: string;
  role!: string;
  photoUri!: string;
  enabled!: boolean;
  verified!: boolean;
  newEmail!: string;
  userRole!: UserRoleLevel;
  registrationTs!: Date;
  updatedTs!: Date;
  privileges!: string[];
  groupsPrivileges!: string[];
  @Type(() => Date) lastLoginDate!: Date;
  @Type(() => Company) company!: Company;
  @Type(() => PrivilegeDefinition) userPrivileges: PrivilegeDefinition[] = [];
  @Type(() => PrivilegeDefinition) groupPrivileges: PrivilegeDefinition[] = [];
  cookieSettings: any
  @Type(() => PrivilegeGroup) privilegeGroup: PrivilegeGroup

  public mapUserPrivileges() {
    this.privileges.forEach((p) => {
      let def: PrivilegeDefinition | undefined = PrivilegeStringMap.get(p);
      if (def) {
        this.userPrivileges.push(def);
      } else {
        console.error('Unknown user privilege ' + p);
      }
    });
  }

  public mapGroupPrivileges() {
    this.groupsPrivileges.forEach((p) => {
      let def: PrivilegeDefinition | undefined = PrivilegeStringMap.get(p);
      if (def) {
        this.groupPrivileges.push(def);
      } else {
        console.error('Unknown group privilege ' + p);
      }
    });
  }

  getFullname(): string {
    return this.firstName + ' ' + this.lastName;
  }

  hasAccessRole(roleLevel: UserRoleLevel): boolean {
    const role = this.userRole;
    if (role === UserRoleLevel.SYSTEM || role === roleLevel) {
      return true;
    }
    return role === UserRoleLevel.ADMIN && roleLevel === UserRoleLevel.MEMBER;

  }

  hasPrivilege(p: any, privilegedEntity?: PrivilegeAware): boolean {
    let privilege: Privilege;
    if (typeof p == 'string') {
      if (p == 'ANY') {
        return true;
      }
      const def = PrivilegeStringMap.get(p);
      if (!def) {
        throw new Error('privilege not found ' + p);
      }
      privilege = def.getPrivilege();
    } else {
      const def = PrivilegeMap.get(p);
      if (!def) {
        throw new Error('privilege not found ' + p);
      }
      privilege = def.getPrivilege();
    }

    var hasPrivilege = this.userPrivileges.find((it) => it.getPrivilege() == privilege) != null;
    if (!hasPrivilege) {
      return this.groupPrivileges.find((it) => it.getPrivilege() == privilege) != null;
    }

    if (privilegedEntity != null && privilegedEntity.isPrivilegeRestricted(privilege)) {
      return false;
    }
    return hasPrivilege;
  }

  hasGroupPrivilege(privilege: Privilege) {
    return this.groupPrivileges.find((it) => it.getPrivilege() == privilege) != null;
  }

  getPhotoUri(userProfileUrl: string) {
    return userProfileUrl + "/" + this.photoUri;
  };

  hasLimitedPermissions() {
    return this.privilegeGroup == null || (this.privilegeGroup.isSystem
        && this.privilegeGroup.roleLevel === "ADMIN") ||
      this.privilegeGroup.privilegeRules.length === 0 ||
      this.privilegeGroup.privilegeRules.find(rule => rule.privilege === "SECOND_PARTY__VIEW") == null ||
      this.privilegeGroup.privilegeRules.find(rule => rule.privilege === "SECOND_PARTY__VIEW").restrictions.length === 0;
  }
}

export class UserSummary {
  id!: number;
  firstName!: string;
  lastName!: string;
  email!: string;
  photoUri!: string;
}
