import { Observable, combineLatest, map, of } from 'rxjs';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from '@shared/utils/snackbar.service';

import {
  AddTaskModalComponent
} from '@app/features/scope-overview/components/scope-tab/add-task-modal/add-task-modal.component';
import { AuthService } from '@core/service/auth.service';
import { BootstrapActions } from '@core/store';
import { CompanyManagementActions } from '@app/features/company-management/store/actions/company-management.actions';
import {
  CompanyManagementSelectors
} from '@app/features/company-management/store/selectors/company-management.selectors';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { Component } from '@angular/core';
import { DataMappingService } from '@app/features/scoping/service/data-mapping.service';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { FormControl } from '@angular/forms';
import { MappedEntity } from '@app/features/scoping/models/mapped-entity.model';
import { MatDialog } from '@angular/material/dialog';
import { MenuOptions } from '@core/model/definitions/menu-options.interface';
import { ModalConfig } from '@core/model/modal-config.model';
import { NewTask } from '@core/model/new-task.model';
import { Page } from '@app/features/scoping/models/page.model';
import { PageEvent } from '@angular/material/paginator';
import { Pagination } from '@core/model/definitions/pagination.interface';
import { Preference } from '@core/model/user-preferences.interface';
import { Privilege } from '@core/model/enums/privilege.enum';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { Sort } from '@angular/material/sort';
import { SortDirection } from '@app/core/model/enums/sort-direction.enum';
import { Store } from '@ngrx/store';
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum';
import { UploadModalComponent } from '@shared/components/upload-modal/upload-modal.component';
import { UploadType } from '@app/features/company-management/models/upload-modal-config';
import { User } from '@core/model/user.model';

@Component({
  selector: 'tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent {
  loggedInUser!: User
  currentTab: string = 'Tasks'
  showDescriptions: boolean;
  taskColumns$!: Observable<Preference[]>
  menuOptions!: MenuOptions[]
  loading$: Observable<boolean>
  tasks$: Observable<Page<Deliverable>>
  mappedTasksDataSource$!: Observable<MappedEntity<Deliverable>[]>
  tableData: Pagination
  searchText = ''
  page = 0
  sort: Sort = {
    active: TableColumnKey.NAME,
    direction: SortDirection.ASC,
  }
  rateCards: any[]

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private mappingService: DataMappingService,
              private store: Store,
              private dialog: MatDialog,
              private snackbarService: SnackbarService) {
    this.loggedInUser = this.authService.loggedInUser
    this.tasks$ = this.store.select(CompanyManagementSelectors.selectTasks)
    this.taskColumns$ = this.store.select(CompanyManagementSelectors.selectTaskColumns)
    this.loading$ = this.store.select(CompanyManagementSelectors.selectLoadingTasks)
  }

  getLibraryTaskTemplates() {
    this.mappedTasksDataSource$ = combineLatest([this.tasks$, this.taskColumns$]).pipe(
        map(([tasks, userPreferences]) => {
          this.tableData = {
            totalCount: tasks.totalElements,
            page: tasks.number
          }
          return this.mappingService.transformArray<Deliverable>(tasks.content, userPreferences)
        })
      )
  }

  ngOnInit() {
    this.getLibraryTaskTemplates()
    this.store.dispatch(CompanyManagementActions.getTasks({ page: 0, searchText: this.searchText, sort: this.sort }))
    this.store.dispatch(BootstrapActions.loadTaskPreferences())
    this.menuOptions = [
      {
        callback: () => {},
        name: () => 'Edit',
        icon: () => 'edit',
        hasPrivilege: () => Privilege.LIBRARY_DELIVERABLE__EDIT,
        isHidden: () => true // TODO - covered in a separate ticket
      },
      {
        callback: (element: any) => this.deleteTask(element.entity),
        name: () => 'Delete',
        icon: () => 'delete',
        hasPrivilege: () => Privilege.LIBRARY_DELIVERABLE__DELETE
      }
    ]
  }

  onSearchKeyPressed(value: string) {
    this.searchText = value
    this.page = 0
    this.store.dispatch(CompanyManagementActions.getTasks({ page: this.page, searchText: this.searchText, sort: this.sort }))
  }

  addTask() {
    let dialog = this.dialog.open(AddTaskModalComponent)
    dialog.componentInstance.newTaskTemplate = true
    dialog.componentInstance.rateCards$ = this.manageService.getCompanyRateCards()
    dialog.componentInstance.onSubmit.subscribe((task: NewTask) => {
      dialog.close()
      this.manageService.addLibraryTaskTemplate(task).subscribe({
        next: () => {
          this.page = 0
          this.store.dispatch(CompanyManagementActions.getTasks({ page: this.page, searchText: this.searchText, sort: this.sort }))
        },
        error: (error) => {
          this.snackbarService.showDefaultErrorSnackbar()
          return error
        },
      })
    })
  }

  private deleteTask(task) {
    let body = `Are you sure you want to delete "` + task.name + `"?`;
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Delete "` + task.name + `"`,
        body,
        'Delete',
        undefined,
        () => {
          dialog.close();
          this.manageService.deleteLibraryTaskTemplate(task.id).subscribe({
            next: () => {
              this.store.dispatch(CompanyManagementActions.getTasks({ page: this.page, searchText: this.searchText, sort: this.sort }))
            },
            error: (error) => {
              this.snackbarService.showDefaultErrorSnackbar()
              return error
            },
          })
        },
        undefined,
        [],
        false,
        true
      ),
    })
  }

  onChangePage(pageEvent: PageEvent) {
    this.page = pageEvent.pageIndex
    this.store.dispatch(CompanyManagementActions.getTasks({ page: this.page, searchText: this.searchText, sort: this.sort }))
  }

  onSort(sort: Sort) {
    this.sort = sort
    this.store.dispatch(CompanyManagementActions.getTasks({ page: this.page, searchText: this.searchText, sort: this.sort }))
  }

  onTogglePreference(preference: Preference) {
    this.store.dispatch(BootstrapActions.updateTaskColumnPreferences({ preference: preference }))
  }

  upload() {
    let dialog = this.dialog.open(UploadModalComponent, {
        data: {
          title: 'Upload Tasks',
          body: `Upload Tasks using Excel file`,
          type: UploadType.TASK,
          verifyService: this.manageService,
          submitFn: (file: File) => this.manageService.import(file, UploadType.TASK),
          successFn: () => {
            dialog.close()
            this.snackbarService.showSnackbar(`File was successfully uploaded. Please, refresh the page.`, SNACKBAR_LENGTH_LONG, SnackbarEventType.SUCCESS)
          },
          showBody: true,
          rateCards$: this.manageService.getCompanyRateCards()
        }
      }
    )
  }

  export() {
    if (!this.rateCards) {
      this.manageService.getCompanyRateCards().subscribe({
        next: (rateCards) => {
          this.rateCards = [{ id: 0, name: 'All Rate Cards' }].concat(rateCards)
          this.openExportModal()
        }
      })
    } else {
      this.openExportModal()
    }
  }

  openExportModal() {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Export Tasks`,
        `Choose the rate card to download tasks for, or select "All Rate Cards"`,
        `Download`,
        undefined,
        (form: FormControl, selections: any[]) => {
          dialog.close()
          let rateCard = selections[0]
          this.manageService.exportTasks(rateCard?.rateCardIdentity?.id).subscribe({
            next: (result: any) => {
              if (!result.resolved) {
                this.snackbarService.showSnackbar("Export started and when complete file will automatically download", SNACKBAR_LENGTH_LONG, SnackbarEventType.INFO)
              }
            },
            error: () => {
              this.snackbarService.showSnackbar("Failed to download export", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
            }
          });
        },
        undefined,
        [
          { name: 'rateCard', control: new FormControl(), type: 'autocomplete', required: true,
            label: `Choose the rate card`, options$: of(this.rateCards)
          }
        ],
        false,
        true,
        undefined,
        (form: FormControl, selections: any[]) => {
          return !!selections.length
        }
      ),
    })
  }
}
