import { Injectable } from '@angular/core';
import { Preference } from '@app/core/model/user-preferences.interface';
import { ScopeComponent } from '@app/features/scoping/models/component.model';
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { Privilege } from '@app/core/model/enums/privilege.enum';
import { ScopeSection } from '../model/scope-section';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import { Department } from '@app/features/scoping/models/department.model';
import { Role } from '@app/features/scoping/models/role.model';
import { LanguageService } from '@app/core/service/language.service';
import { ScopeVersion } from '@core/model/scope-version'
import { AuthService } from '@core/service/auth.service'
import { Money } from '@app/features/scope-overview/model/money.model';
import { TableColumnKey } from '@app/shared/components/ui-components/scope-ui-table/table-column-key.enum';

interface stateMap { [id: number]: boolean }

@Injectable({
  providedIn: 'root',
})
export class ScopeTabService {
  userColumnsPrefsArray!: Preference[];

  sectionColumns!: Preference[];

  deliverableColumns!: Preference[];

  componentColumns!: Preference[];

  departmentColumns!: Preference[];

  roleColumns!: Preference[];

  thirdPartyCostColumns!: Preference[];

  userColumns!: {
    [key: string]: Preference;
  };

  timelineColumns!: {
    [key: string]: Preference;
  };

  feeColumns!: Preference[];

  scopeFeeColumns!: Preference[];

  sectionFeeColumns!: Preference[];

  deliverableFeeColumns!: Preference[];

  componentFeeColumns!: Preference[];

  allFeeColumns!: Preference[];

  scopeByRoleFeeColumns!: Preference[];

  sectionSelectedStates: stateMap = { }

  _sectionSelectedStates: Subject<stateMap> = new Subject<stateMap>()

  deliverableSelectedStates: stateMap = { }

  _deliverableSelectedStates: Subject<stateMap> = new Subject<stateMap>()

  deliverableSectionSelectedStates: stateMap = { }

  _deliverableSectionSelectedStates: Subject<stateMap> = new Subject<stateMap>()

  componentSelectedStates: stateMap = { }

  _componentSelectedStates: Subject<stateMap> = new Subject<stateMap>()

  departmentSelectedStates: stateMap = { }

  _departmentSelectedStates = new BehaviorSubject<stateMap>({})

  sectionDescriptionStates: stateMap = { }

  deliverableDescriptionStates: stateMap = { }

  componentDescriptionStates: stateMap = { }

  sectionFeeTableStates: stateMap = { }

  deliverableFeeTableStates: stateMap = { }

  componentFeeTableStates: stateMap = { }

  deliverableDragging: boolean = false

  dragTargetId: number;

  disableDragging: boolean = false

  taskOpenStates: stateMap = { }

  constructor(private store: Store, private lang: LanguageService, private authService: AuthService) {}

  resetTableStates() {
    this.sectionSelectedStates = { }
    this._sectionSelectedStates.next({})
    this.deliverableSelectedStates = { }
    this._deliverableSelectedStates.next({})
    this.deliverableSectionSelectedStates = { }
    this._deliverableSectionSelectedStates.next({})
    this.componentSelectedStates = { }
    this._componentSelectedStates.next({})
    this.departmentSelectedStates = { }
    this._departmentSelectedStates.next({})
    this.sectionDescriptionStates = { }
    this.deliverableDescriptionStates = { }
    this.componentDescriptionStates = { }
    this.sectionFeeTableStates = { }
    this.deliverableFeeTableStates = { }
    this.componentFeeTableStates = { }
  }

  setUserColumnsArray(): void {
    this.userColumnsPrefsArray = Object.values(this.userColumns);
  }

  setUserColumns(currentScope: ScopeVersion) {
    this.userColumns = {
      [TableColumnKey.SIZE]: {
        key: TableColumnKey.SIZE,
        name: 'Size',
        selected: true,
        isVisibleInList: () => false,
        field: 'complexity',
      },
      [TableColumnKey.WEIGHTED_RATE]: {
        key: TableColumnKey.WEIGHTED_RATE,
        name: `Weighted ${this.lang.get('scope_mark')} rate`,
        selected: true,
        isVisible: () =>
          currentScope.hasPrivilege(Privilege.SCOPE__VIEW_RATES, this.authService.loggedInUser)
          && this.authService.loggedInUser.company.hasApplicationSetting('RATECARD__SCOPEMARK__RATES'),
        isVisibleInList: () => false,
        value: (role: Role) => role.getScopeMarkRate(),
      },
      [TableColumnKey.AGENCY_RATE]: {
        key: TableColumnKey.AGENCY_RATE,
        name: `${this.lang.get('agency')} rate`,
        selected: true,
        isVisible: () => currentScope.hasPrivilege(Privilege.SCOPE__VIEW_RATES, this.authService.loggedInUser),
        isVisibleInList: () => false,
        field: 'rateCardRate',
      },
      [TableColumnKey.CREATED_BY]: {
        key: TableColumnKey.CREATED_BY,
        name: 'Created By',
        selected: false,
        field: 'createdBy.fullName',
      },
      [TableColumnKey.OWNER]: {
        key: TableColumnKey.OWNER,
        name: 'Owner',
        selected: false,
        field: 'owner.fullName',
      },
      [TableColumnKey.DATE_CREATED]: {
        key: TableColumnKey.DATE_CREATED,
        name: 'Date created',
        selected: false,
        field: 'createdTs',
      },
      [TableColumnKey.QUANTITY]: {
        key: TableColumnKey.QUANTITY,
        name: 'Quantity',
        selected: true,
        field: 'quantity',
      },
      [TableColumnKey.START_DATE]: {
        key: TableColumnKey.START_DATE,
        name: 'Start Date',
        selected: true,
        isVisibleInList: () =>
          this.authService.loggedInUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__STARTEND_DATE'),
        field: 'startDate',
      },
      [TableColumnKey.LAST_EDITED]: {
        key: TableColumnKey.LAST_EDITED,
        name: 'Last edited',
        selected: true,
        field: 'updatedTs',
      },
      [TableColumnKey.END_DATE]: {
        key: TableColumnKey.END_DATE,
        name: 'End Date',
        selected: false,
        isVisibleInList: () =>
          this.authService.loggedInUser.company.hasApplicationSetting('SCOPE__DELIVERABLE__STARTEND_DATE'),
        field: 'endDate',
      },
      [TableColumnKey.MARK_UP]: {
        key: TableColumnKey.MARK_UP,
        name: 'Markup',
        selected: false,
        isVisibleInList: () => currentScope.hasPrivilege(Privilege.SCOPE_MARKUP__VIEW, this.authService.loggedInUser),
        value: (entity: ScopeSection | Deliverable) => entity instanceof Deliverable ? entity.getMarkup() : entity.markup,
      },
      [TableColumnKey.PROFIT]: {
        key: TableColumnKey.PROFIT,
        name: 'Profit',
        selected: true,
        isVisibleInList: () => currentScope.hasPrivilege(Privilege.SCOPE_MARKUP__VIEW, this.authService.loggedInUser),
        value: (entity: ScopeSection | Deliverable) => entity instanceof Deliverable ? entity.getTotalProfit() : entity.totalProfit,
      },
      [TableColumnKey.SCOPEMARK_HOURS]: {
        key: TableColumnKey.SCOPEMARK_HOURS,
        name: `${this.lang.get('scope_mark')} Hours`,
        selected: false,
        isVisibleInList: () =>
          this.authService.loggedInUser.company.hasApplicationSetting('PREDICT__SCOPEMARK__COMPONENTS'),
        field: 'scopeMarkTotalHours',
      },
      [TableColumnKey.SCOPEMARK_PRICE]: {
        key: TableColumnKey.SCOPEMARK_PRICE,
        name: `${this.lang.get('scope_mark')} Price`,
        selected: false,
        isVisibleInList: () =>
          this.authService.loggedInUser.company.hasApplicationSetting('PREDICT__SCOPEMARK__COMPONENTS'),
        field: 'scopeMarkTotalSellingPrice',
      },
      [TableColumnKey.AGENCY_HOURS]: {
        key: TableColumnKey.AGENCY_HOURS,
        name: `${this.lang.get('agency')} Hours`,
        selected: true,
        value: (entity: ScopeSection | Deliverable | ScopeComponent | Department | Role) => {
          if (entity instanceof Deliverable) return entity.totalRateCardHours;
          if (entity instanceof ScopeSection) return entity.getAgencyHours(currentScope);
          if (entity instanceof ScopeComponent || entity instanceof Department) return entity.getTotalRateCardHours();

          return entity.getRateCardHours();
        },
      },
      [TableColumnKey.BUDGET]: {
        key: TableColumnKey.BUDGET,
        name: 'Budget',
        selected: false,
        field: 'budget',
      },
      [TableColumnKey.BALANCE]: {
        key: TableColumnKey.BALANCE,
        name: 'Balance',
        selected: false,
        field: 'budgetBalance',
      },
      [TableColumnKey.DELIVERY_STATUS]: {
        key: TableColumnKey.DELIVERY_STATUS,
        name: 'Delivery Status',
        selected: false,
        isVisible: function () { return this.selected && currentScope.isStateApproved() },
        isVisibleInList: () => currentScope.isStateApproved(),
      },
      [TableColumnKey.AGENCY_PRICE]: {
        key: TableColumnKey.AGENCY_PRICE,
        name: `${this.lang.get('agency')} Price`,
        selected: true,
        value: (entity: ScopeSection | Deliverable | ScopeComponent | Department | Role) => {
          if (entity instanceof Deliverable || entity instanceof ScopeComponent || entity instanceof Department)
            return entity.getTotalSellingPrice();
          if (entity instanceof Role) return entity.rateCardTotalSpentPrice;

          return entity.totalSellingPrice;
        },
      },
      [TableColumnKey.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS]: {
        key: TableColumnKey.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS,
        name: 'Show Actuals',
        selected: false,
        isVisibleInList: () =>
          this.authService.loggedInUser.company.hasApplicationSetting('SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS'),
        field: 'traffickedPercentageCompleteValue'
      },
    };

    this.timelineColumns = {
      [TableColumnKey.AGENCY_HOURS]: this.userColumns.AGENCY_HOURS,
      [TableColumnKey.AGENCY_PRICE]: this.userColumns.AGENCY_PRICE
    }

    this.setUserColumnsArray();
  }

  setFeeColumns() {
    this.feeColumns = [
      {
        key: TableColumnKey.FEE_NAME,
        name: `${this.lang.get('scope|u')} / ${this.lang.get('deliverable|u')} / ${this.lang.get('component|u')} FEE`,
        valueIconFunction: () => 'request_quote',
        selected: true,
        isVisibleInList: () => false,
        value: (feeItem: any) => feeItem.feeItem.name,
        footerRowValue: (parentEntity: ScopeVersion | ScopeSection | Deliverable | ScopeComponent) =>
          parentEntity.feeItems?.length ? 'Fee total' : 'No fees defined',
      },
      {
        key: TableColumnKey.AMOUNT,
        name: 'Amount',
        selected: true,
        isVisibleInList: () => false,
        value: (feeItem: any, parentEntity: ScopeVersion | ScopeSection | Deliverable | ScopeComponent) =>
          feeItem.feeItem.amountType === 'PERCENTAGE'
            ? feeItem.feeItem.amount
            : new Money(feeItem.feeItem.amount, feeItem.currencyUnit || parentEntity?.currencyUnit),
      },
      {
        key: TableColumnKey.PRICE,
        name: 'Price',
        selected: true,
        isVisibleInList: () => false,
        value: (feeItem: any) => new Money( feeItem.price, feeItem.currencyUnit),
        footerRowValue: (parentEntity: ScopeVersion | ScopeSection | Deliverable | ScopeComponent) =>
          Intl.NumberFormat('en-GB', { style: 'currency', currency: parentEntity.currencyUnit })
            .format(parentEntity.getTotalFees(null)),
      },
    ];

    this.scopeFeeColumns = this.feeColumns.map((x) => Object.assign({}, x));
    this.scopeFeeColumns[0].name = `${this.lang.get('scope|u')} FEE`;
    this.sectionFeeColumns = this.feeColumns.map((x) => Object.assign({}, x));
    this.sectionFeeColumns[0].name = `${this.lang.get('stage|u')} FEE`;
    this.deliverableFeeColumns = this.feeColumns.map((x) => Object.assign({}, x));
    this.deliverableFeeColumns[0].name = `${this.lang.get('deliverable|u')} FEE`;
    this.componentFeeColumns = this.feeColumns.map((x) => Object.assign({}, x));
    this.componentFeeColumns[0].name = `${this.lang.get('component|u')} FEE`;
    this.allFeeColumns = this.feeColumns.map((x) => Object.assign({}, x));
    this.allFeeColumns[0].name = `FEE NAME`;
    this.allFeeColumns.splice(1, 0, {
      key: TableColumnKey.LINK_TO,
      name: 'Link to',
      selected: true,
      isVisibleInList: () => false,
      value: (feeItem: any) =>
        feeItem.component
          ? { name: feeItem.component.name, id: feeItem.component.id }
          : feeItem.deliverable || feeItem.scopeSection,
      inputType: () => 'linkTo'
    })
    this.scopeByRoleFeeColumns = this.feeColumns.map((x) => Object.assign({}, x));
    this.scopeByRoleFeeColumns[0].name = `FEE NAME`;
  }

  updateFilterTableColumns() {
    let sectionName: Preference = {
      key: TableColumnKey.SECTION_NAME,
      name: 'STAGE',
      selected: true,
      isVisibleInList: () => false,
      field: 'name',
    };
    this.sectionColumns = [
      sectionName,
      ...this.userColumnsPrefsArray.filter((c) =>
        ![TableColumnKey.WEIGHTED_RATE, TableColumnKey.AGENCY_RATE, TableColumnKey.SIZE].includes(c.key)),
    ];

    let deliverableName: Preference = {
      key: TableColumnKey.DELIVERABLE_NAME,
      name: 'DELIVERABLE',
      selected: true,
      isVisibleInList: () => false,
      valueIconFunction: () => 'description',
      field: 'name',
    };
    this.deliverableColumns = [
      deliverableName,
      ...this.userColumnsPrefsArray.filter((c) =>
        ![TableColumnKey.WEIGHTED_RATE, TableColumnKey.AGENCY_RATE, TableColumnKey.SIZE].includes(c.key)),
    ];

    let componentName: Preference = {
      key: TableColumnKey.COMPONENT_NAME,
      name: 'COMPONENT',
      selected: true,
      isVisibleInList: () => false,
      value: (component: ScopeComponent) =>
        component.name && component.name.length > 0 ? component.name : component.source.name,
    };
    this.componentColumns = [
      componentName,
      ...this.userColumnsPrefsArray.filter((c) =>
        [
          TableColumnKey.QUANTITY,
          TableColumnKey.SIZE,
          TableColumnKey.AGENCY_HOURS,
          TableColumnKey.AGENCY_PRICE,
          TableColumnKey.SCOPEMARK_HOURS,
          TableColumnKey.SCOPEMARK_PRICE
        ].includes(c.key)
      ),
    ];

    let departmentName: Preference = {
      key: TableColumnKey.DEPARTMENT_NAME,
      name: 'DEPARTMENT',
      selected: true,
      isVisibleInList: () => false,
      field: 'name',
    };
    this.departmentColumns = [
      departmentName,
      ...this.userColumnsPrefsArray.filter((c) =>
        [TableColumnKey.AGENCY_HOURS, TableColumnKey.AGENCY_PRICE, TableColumnKey.SCOPEMARK_HOURS, TableColumnKey.SCOPEMARK_PRICE].includes(c.key)
      ),
    ];

    let roleName: Preference = {
      key: TableColumnKey.ROLE_NAME,
      name: 'ROLE',
      selected: true,
      isVisibleInList: () => false,
      field: 'name',
    };
    this.roleColumns = [
      roleName,
      ...this.userColumnsPrefsArray.filter((c) =>
        [
          TableColumnKey.WEIGHTED_RATE,
          TableColumnKey.AGENCY_RATE,
          TableColumnKey.AGENCY_HOURS,
          TableColumnKey.AGENCY_PRICE,
          TableColumnKey.SCOPEMARK_HOURS,
          TableColumnKey.SCOPEMARK_PRICE
        ].includes(c.key)
      ),
    ];

    this.thirdPartyCostColumns = [
      {
        key: TableColumnKey.TPC_NAME,
        name: 'THIRD PARTY COST',
        selected: true,
        isVisibleInList: () => false,
        field: 'name',
      },
      {
        key: TableColumnKey.TPC_AMOUNT,
        name: 'Amount',
        selected: true,
        isVisibleInList: () => false,
        field: 'quantity',
      },
      {
        key: TableColumnKey.UNIT,
        name: 'Unit',
        selected: true,
        isVisibleInList: () => false,
        field: 'formula.subjectName',
      },
      {
        key: TableColumnKey.QUANTITY,
        name: 'Quantity',
        selected: true,
        isVisibleInList: () => false,
        field: 'formula.unitTypeQuantity',
      },
      {
        key: TableColumnKey.UNIT_TYPE,
        name: 'Unit type',
        selected: true,
        isVisibleInList: () => false,
        field: 'formula.unitType.name',
      },
      {
        key: TableColumnKey.UNIT_COST,
        name: 'Unit cost',
        selected: true,
        isVisibleInList: () => false,
        field: 'cost',
      },
      {
        key: TableColumnKey.OVERTIME_QUANTITY,
        name: 'Overtime quantity',
        selected: true,
        isVisibleInList: () => false,
        field: 'formula.overtimeUnitQuantity',
      },
      {
        key: TableColumnKey.OVERTIME_UNIT_TYPE,
        name: 'Overtime unit type',
        selected: true,
        isVisibleInList: () => false,
        field: 'formula.unitType.name',
      },
      {
        key: TableColumnKey.OVERTIME_RATE,
        name: 'Overtime rate',
        selected: true,
        isVisibleInList: () => false,
        value: (thirdPartyCost: ThirdPartyCost) => this.getAddAmountWithCurrency(thirdPartyCost),
      },
      {
        key: TableColumnKey.OVERTIME_TOTAL,
        name: 'Overtime total',
        selected: true,
        isVisibleInList: () => false,
        value: (thirdPartyCost: ThirdPartyCost) => this.getTotalAddAmountWithCurrency(thirdPartyCost),
      },
      {
        key: TableColumnKey.MARK_UP,
        name: 'Markup',
        selected: true,
        isVisibleInList: () => false,
        field: 'markupPercentageDecimal',
      },
      {
        key: TableColumnKey.SELLING_PRICE,
        name: 'Selling price',
        selected: true,
        isVisibleInList: () => false,
        value: (thirdPartyCost: ThirdPartyCost) => thirdPartyCost.calculateSellingPrice(),
      },
    ];
  }

  columnVisible = (column) => {
    return column.selected && column.key !== TableColumnKey.SCOPE__TRAFFICKING__DELIVERABLE_ACTUALS &&
      (column.isVisible ? column.isVisible() : true) && (column.isVisibleInList ? column.isVisibleInList() : true);
  }

  getSelectedColumnsCount(): number {
    return Object.values(this.userColumns).filter(this.columnVisible).length;
  }

  countComponentColumns() {
    return [
      this.userColumns.START_DATE,
      this.userColumns.END_DATE,
      this.userColumns.SCOPEMARK_HOURS,
      this.userColumns.SCOPEMARK_PRICE,
      this.userColumns.AGENCY_HOURS,
      this.userColumns.MARK_UP,
      this.userColumns.AGENCY_PRICE,
    ].filter((column) => column?.selected).length
  }

  unselectExcessColumns() {
    if (this.getSelectedColumnsCount() > 9)
      for (let key of Object.keys(this.userColumns)) {
        if (this.getSelectedColumnsCount() <= 9) return;

        let column = this.userColumns[key];

        if (this.columnVisible(column)) column.selected = false;
      }
  }

  getColumnClass(count: number) {
    switch (count) {
      case 0: return 'eleven--columns';
      case 1: return 'ten--columns';
      case 2: return 'nine--columns';
      case 3: return 'eight--columns';
      case 4: return 'seven--columns';
      case 5: return 'six--columns';
      case 6: return 'five--columns';
      case 7: return 'four--columns';
      case 8: return 'three--columns';
      case 9:
      default:
        return 'two--columns';
    }
  }

  calculateMainColumnClass() {
    var count = this.getSelectedColumnsCount();
    return this.getColumnClass(count)
  }

  calculateScopeTotalsMainColumnClass(currentScope: ScopeVersion) {
    var isSelectedFields = [
      this.userColumns.SCOPEMARK_HOURS,
      this.userColumns.SCOPEMARK_PRICE,
      this.userColumns.AGENCY_HOURS,
      this.userColumns.AGENCY_PRICE,
      this.userColumns.BUDGET,
      this.userColumns.BALANCE,
    ];

    if (currentScope.hasPrivilege(Privilege.SCOPE_MARKUP__VIEW, this.authService.loggedInUser)) {
      isSelectedFields.push(this.userColumns.MARK_UP);
      isSelectedFields.push(this.userColumns.PROFIT);
    }

    return this.getColumnClass(isSelectedFields.filter((column) => column.selected).length)
  }

  private getAddAmountWithCurrency(tpc: ThirdPartyCost) {
    return new Money(tpc.formula?.overtimeRate || null, tpc.cost.currency)
  }

  calculateComponentMainColumnClass(): string {
    switch (this.countComponentColumns()) {
      case 7: return 'two--columns'
      case 6: return 'three--columns'
      case 5: return 'four--columns'
      case 4: return 'five--columns'
      case 3: return 'six--columns'
      case 2: return 'seven--columns'
      case 1: return 'eight--columns'
      case 0: return 'nine--columns'
      default: return 'five--columns'
    }
  }

  private getTotalAddAmountWithCurrency(tpc: ThirdPartyCost): Money {
    let overtimeRate = null
    if (tpc.formula) overtimeRate = tpc.formula.overtimeRate ? tpc.formula.overtimeRate * tpc.formula.overtimeUnitQuantity : 0;
    return new Money(overtimeRate, tpc.cost.currency);
  }

  resetDeliverablesState() {
    this.deliverableSelectedStates = { }
    this.deliverableSectionSelectedStates = { }
    this.componentSelectedStates = { }
    this.departmentSelectedStates = { }
  }

  toggleSection(sectionId: number) {
    this.sectionSelectedStates[sectionId] = !this.sectionSelectedStates[sectionId]
    this._sectionSelectedStates.next(this.sectionSelectedStates)
  }

  toggleDeliverable(deliverableId: number) {
    this.deliverableSelectedStates[deliverableId] = !this.deliverableSelectedStates[deliverableId]
    this._deliverableSelectedStates.next(this.deliverableSelectedStates)
  }

  toggleDeliverableSection(sectionId: number) {
    this.deliverableSectionSelectedStates[sectionId] = !this.deliverableSectionSelectedStates[sectionId]
    this._deliverableSectionSelectedStates.next(this.deliverableSectionSelectedStates)
  }

  toggleComponent(componentId: number) {
    this.componentSelectedStates[componentId] = !this.componentSelectedStates[componentId]
    this._componentSelectedStates.next(this.componentSelectedStates)
  }

  toggleDepartment(departmentId: number) {
    this.departmentSelectedStates[departmentId] = !this.departmentSelectedStates[departmentId]
    this._departmentSelectedStates.next(this.departmentSelectedStates)
  }

  updateDepartmentSelectedStates(departmentSelectedStates: stateMap) {
    this._departmentSelectedStates.next(this.departmentSelectedStates = { ...departmentSelectedStates })
  }

  get sectionSelectedStates$() {
    return this._sectionSelectedStates.asObservable();
  }

  get deliverableSelectedStates$() {
    return this._deliverableSelectedStates.asObservable();
  }

  get deliverableSectionSelectedStates$() {
    return this._deliverableSectionSelectedStates.asObservable();
  }

  get componentSelectedStates$() {
    return this._componentSelectedStates.asObservable();
  }

  get departmentSelectedStates$() {
    return this._departmentSelectedStates.asObservable();
  }
}
