<div class="duration-container flex flex-row gap-4">
  <div class="flex flex-1 gap-4" [hidden]="hidePeriod">
    <scope-ui-dropdown
      class="flex-1"
      [selectedValue]="selectedPeriod"
      [controlLabel]="'Duration'"
      [dropdownOptions]="periods"
      [disabled]="disabled"
      [isStandardDropdown]="true"
      (onSelectionChange)="onSelectDuration($event)" />
    <scope-ui-input
      class="flex-1"
      [initialValue]="countDays"
      [hidden]="selectedPeriod.value !== 'fixed_count_days'"
      [hasPlaceholder]="false"
      [inputLabel]="'Count'"
      [minimum]="0"
      [maximum]="730"
      [type]="'number'"
      [parseNumber]="true"
      [isDisabled]="disabled"
      (onInputChange)="onCountOfDaysChange($event)"
      aria-label="Budget" 
      />
  </div>
  <scope-ui-datepicker
    class="flex-1"
    [singleDatePicker]="true"
    [inputLabel]="'Start Date'"
    [initialValue]="startDate"
    [minDate]="minDate"
    [maxDate]="endDate"
    [opens]="opens"
    [disabled]="disabled"
    [validationError]="validationError"
    (onSelect)="onSelectStartDate($event)">
  </scope-ui-datepicker>
  <scope-ui-datepicker
    class="flex-1"
    [singleDatePicker]="true"
    [inputLabel]="'End Date'"
    [initialValue]="endDate"
    [minDate]="startDate"
    [maxDate]="maxDate"
    [opens]="opens"
    [disabled]="disabled"
    [validationError]="validationError"
    (onSelect)="onSelectEndDate($event)">
  </scope-ui-datepicker>
</div>
