import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DEFAULT_DATE_FORMAT, DateRange, formatDate } from "./scope-ui-datepicker.const";
import { DaterangepickerComponent, DaterangepickerDirective, NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SNACKBAR_LENGTH_LONG, SnackbarEventType, SnackbarService } from "@app/shared/utils/snackbar.service";
import dayjs, { Dayjs } from 'dayjs';

import { AuthService } from '@core/service/auth.service';
import { ChosenDate } from 'ngx-daterangepicker-material/daterangepicker.component';
import { FormControl } from "@angular/forms";
import { NgIf } from "@angular/common";
import { SharedModule } from "@shared/shared.module";
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)
dayjs.extend(isBetween)

@Component({
  selector: 'scope-ui-datepicker',
  standalone: true,
  templateUrl: './scope-ui-datepicker.component.html',
  imports: [
    NgxDaterangepickerMd,
    NgIf,
    SharedModule
  ],
  styleUrls: ['./scope-ui-datepicker.component.scss']
})
export class ScopeUiDatepickerComponent implements AfterViewInit {
  @Input() singleDatePicker?: boolean;
  @Input() inlineDatePicker?: boolean;
  @Input() inputLabel!: string;
  @Input() disabled: boolean = false;
  @Input() minDateClass?: string;
  @Input() maxDateClass?: string;
  @Input() validationError?: string;
  @Input() opens?: string = 'auto';
  @Input() set initialValue(value: Date | DateRange) {
    if (value) {
      this._initialValue =
        value instanceof Date
          ? dayjs.utc(value)
          : {
              startDate: dayjs.utc(value.startDate),
              endDate: dayjs.utc(value.endDate),
            }
      this.setInitialValue()
    }
  }
  @Input() set minDate(value: Date) {
    if (value && value instanceof Date) {
      this._minDate = dayjs(value.toISOString().slice(0, 10)).startOf('day');
      this._minDateValue = this._minDate.toISOString();
      if (this._initialValue && this._initialValue < this._minDate) {
        this._initialValue = this._minDate
        this.dateControl.setValue({
          startDate: this._initialValue,
          endDate: this._initialValue
        })
      }
    }
  }
  @Input() set maxDate(value: Date) {
    if (value && value instanceof Date) {
      this._maxDate = dayjs(value.toISOString().slice(0, 10)).endOf('day');
      this._maxDateValue = this._maxDate.toISOString();
      if (this._initialValue && this._initialValue > this._maxDate) {
        this._initialValue = this._maxDate
        this.dateControl.setValue({
          startDate: this._initialValue,
          endDate: this._initialValue
        })
      }
    }
  }

  @Output() onSelect = new EventEmitter<any>();

  @ViewChild(DaterangepickerComponent) datePicker!: DaterangepickerComponent
  @ViewChild(DaterangepickerDirective) datePickerDirective!: DaterangepickerDirective

  companyDateFormat?: string;
  dateControl = new FormControl<string | any>('')

  _minDateValue: any
  _minDate: Dayjs
  _maxDateValue: any
  _maxDate: Dayjs
  _initialValue: Dayjs | DateRange

  constructor(private authService: AuthService, private snackbarService: SnackbarService) {}

  onSelectedChange(date: ChosenDate) {
    this.onSelect.emit({
      startDate: new Date(formatDate(date.startDate)),
      endDate: new Date(formatDate(date.endDate))
    })
  }

  ngOnChanges() {
    this.datePickerDirective?.setDisabledState(this.disabled)
  }

  ngAfterViewInit() {
    this.setInitialValue()
  }

  setInitialValue() {
    if (this._initialValue) {
      if (this.singleDatePicker && !this.inlineDatePicker) {
        if (this._initialValue < this._minDate) this._initialValue = this._minDate
        if (this._initialValue > this._maxDate) this._initialValue = this._maxDate
        
        this.dateControl.setValue({
          startDate: this._initialValue,
          endDate: this._initialValue
        })
      } else if (this.singleDatePicker && this.inlineDatePicker) {
        this.datePicker.setStartDate(dayjs.utc(this._initialValue as Dayjs))
        this.datePicker.setEndDate(dayjs.utc(this._initialValue as Dayjs))
        this.datePicker.updateView()
      } else if (this.datePicker) {
        this.datePicker.setStartDate(dayjs.utc((this._initialValue as DateRange).startDate))
        this.datePicker.setEndDate(dayjs.utc((this._initialValue as DateRange).endDate))
        this.datePicker.updateView()
      }
    } else 
      this.datePickerDirective?.clear()
  }

  protected readonly locale = {
    format: this.authService.loggedInUser?.company?.companyDateFormat || DEFAULT_DATE_FORMAT,
    displayFormat: this.authService.loggedInUser?.company?.companyDateFormat || DEFAULT_DATE_FORMAT,
    daysOfWeek: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  }

  isCustomDate = (date: Dayjs): any => {
    if (formatDate(date) === formatDate(this._minDate)) return this.minDateClass
    if (formatDate(date) === formatDate(this._maxDate)) return this.maxDateClass
    if ((date.get('month') < this._minDate?.get('month') && date.get('year') === this._minDate?.get('year')) 
      || (date.get('month') > this._maxDate?.get('month') && date.get('year') === this._maxDate?.get('year')))
      return 'invisible'

    return false
  }

  onChange($event: any) {
    let date = formatDate($event.startDate) 

    if (this._minDate && dayjs(date).isBefore(this._minDate)) {
      date = formatDate(this._minDate)
      this.showError();
    }

    if (this._maxDate && dayjs(date).isAfter(this._maxDate)) {
      date = formatDate(this._maxDate)
      this.showError();
    }

    if (date !== formatDate(this._initialValue)) this.onSelect.emit(new Date(date))
      
    this.dateControl?.patchValue(new Date(date))
  }

  showError() {
    if (this.validationError)
      this.snackbarService.showSnackbar(this.validationError, SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
  }
}
