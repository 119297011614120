import { Action, createReducer, on } from '@ngrx/store';
import { ScopeOverviewState } from '../../model/scope-overview.state.model';
import { ScopeOverviewActions } from '../actions/scope-overview.action';
import { ScopeVersion } from '@app/core/model/scope-version';
import { plainToInstance } from 'class-transformer';
import { Deliverable } from '@app/features/scoping/models/deliverable.model';
import {
  CompanyScopeCustomFieldsDefinition
} from '@app/features/scope-overview/model/company-scope-custom-fields-definition.model'
import {
  ScopeCustomFieldValueStructure
} from '@app/features/scope-overview/model/scope-custom-field-value-structure.model'
import { ScopeSubmitter } from '@core/model/scope-submitter.model'
import { ScopeVoter } from '@core/model/scope-voter.model'
import { cloneDeep, unionBy } from 'lodash'
import { ScopeTeam } from '@core/model/scope-team.model'
import { moveItemInArray } from '@angular/cdk/drag-drop'
import { FeeItemInstance } from '@app/features/scope-overview/model/fee-item.model'
import { ThirdPartyCost } from '@app/core/model/third-party-cost.model'

export const initialState: ScopeOverviewState = {
  activities: [],
  tradedDeliverables: [],
  currentScope: undefined,
  scopeRetainedHourStats: undefined,
  sowRetainedHourStats: undefined,
  thirdPartyCostBalance: undefined,
  loading: {
    loadingScope: false,
    loadingScopeRetainedStats: false,
    loadingThirdPartyCostBalance: false,
    loadingVersionChange: false,
    loadingAddScopeSection: false,
    loadingArchiveScopeSection: false,
    loadingScopeDeliverable: false,
    loadingAvailableDeliverableTypes: false,
    loadingAddScopeDeliverable: false,
    loadingDuplicateDeliverable: false,
    loadingUpdateDeliverable: false,
    loadingDeleteDeliverable: false,
    loadingLibraryEntry: false,
    loadingLibraryTemplate: false,
    loadingOutputTemplate: false,
    loadingDeleteScopeSection: false,
    loadingDeleteScopeFee: false,
    loadingAllFees: false,
    loadingAllTPCs: false,
    loadingAllTasks: false,
  },
  loaded: {
    loadedScope: false,
    loadedScopeRetainedStats: false,
    loadedThirdPartyCostBalance: false,
    loadedVersionChange: false,
    loadedAddScopeSection: false,
    loadedScopeDeliverable: false,
    loadedAvailableDeliverableTypes: false,
    loadedAddScopeDeliverable: false,
    loadedDuplicateDeliverable: false,
    loadedUpdateDeliverable: false,
    loadedDeleteDeliverable: false,
    loadedLibraryEntry: false,
    loadedLibraryTemplate: false,
    loadedOutputTemplate: false,
    loadedDeleteScopeSection: false,
    loadedDeleteScopeFee: false,
    loadedAllFees: false,
    loadedAllThirdPartyCosts: false,
    loadedAllTasks: false,
  },
  availableDeliverableTypes: undefined,
  deliverableLibraryEntry: undefined,
  deliverableLibraryTemplate: undefined,
  outputTemplate: undefined,
  docxPreview: undefined,
  previewError: undefined,
  teamError: undefined,
  allComponentFees: [],
  allComponentTPCs: [],
  allSectionTPCs: []
};

function setScopeNotificationEvent(state: ScopeOverviewState, action: any) {
  // Create an instance of the relevant class from the current state's currentScope
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope)

  // Extract scopeNotificationProps from action
  const { scopeNotificationProps } = action

  // Iterate over the properties in scopeNotificationProps and update them on the currentScopeInstance
  Object.keys(scopeNotificationProps).forEach(key => {
    currentScopeInstance[key] = scopeNotificationProps[key];
  })

  return {
    ...state,
    currentScope: currentScopeInstance
  }
}



function getCurrentScopeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingScope: true },
    loaded: { ...state.loaded, loadedScope: false },
  };
}

function remapDeliverablesInTradedHierarchy(currentScopeInstance: ScopeVersion) {
  if (!currentScopeInstance.isStateApproved()) return

  let tradedDeliverables = currentScopeInstance.deliverables.filter(del => del.deliveryStatus === 'TRADING_FROM_IN_PROGRESS')

  tradedDeliverables.forEach((deliverable) => {
    let tradedToDeliverables = currentScopeInstance.deliverables.filter(del => del.tradedFrom?.id === deliverable.id).reverse()
    tradedToDeliverables.forEach((del) => {
      moveItemInArray(currentScopeInstance.deliverables, currentScopeInstance.deliverables.findIndex((d) => d.id == del.id), currentScopeInstance.deliverables.findIndex((d) => d.id == deliverable.id) + 1)
      del.order = deliverable.order
    })
    deliverable.tradesCount = tradedToDeliverables.length
  })
}

function getCurrentScopeSuccessHandler(state: ScopeOverviewState, { currentScope }: any) {
  const currentScopeInstance = plainToInstance(ScopeVersion, currentScope);

  remapDeliverablesInTradedHierarchy(currentScopeInstance)

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingScope: false },
    loaded: { ...state.loaded, loadedScope: true, loadedAllFees: false, loadedAllThirdPartyCosts: false },
  };
}

function getCurrentScopeFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingScope: false },
    loaded: { ...state.loaded, loadedScope: false },
  };
}

function getScopeRetainedStats(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingScopeRetainedStats: true },
    loaded: { ...state.loaded, loadedScopeRetainedStats: false },
  };
}

function getScopeRetainedStatsSuccess(state: ScopeOverviewState, { scopeRetainedHourStats }: any) {
  return {
    ...state,
    scopeRetainedHourStats,
    loading: { ...state.loading, loadingScopeRetainedStats: false },
    loaded: { ...state.loaded, loadedScopeRetainedStats: true },
  };
}

function getScopeRetainedStatsFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingScopeRetainedStats: false },
    loaded: { ...state.loaded, loadedScopeRetainedStats: false },
  };
}

function getSowRetainedStats(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingSowRetainedStats: true },
    loaded: { ...state.loaded, loadedSowRetainedStats: false },
  };
}

function getSowRetainedStatsSuccess(state: ScopeOverviewState, { sowRetainedHourStats }: any) {
  return {
    ...state,
    sowRetainedHourStats,
    loading: { ...state.loading, loadingSowRetainedStats: false },
    loaded: { ...state.loaded, loadedSowRetainedStats: true },
  };
}

function getSowRetainedStatsFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingSowRetainedStats: false },
    loaded: { ...state.loaded, loadedSowRetainedStats: false },
  };
}

function getActivityCommentsSuccessHandler(state: ScopeOverviewState, { activities }: any) {
  return {
    ...state,
    activities,
  };
}

function submitCommentSuccessHandler(state: ScopeOverviewState, { updatedComments }: any) {
  return {
    ...state,
    activities: updatedComments,
  };
}

function getThirdPartyCostBalance(state: ScopeOverviewState, { activities }: any) {
  return {
    ...state,
    loading: { ...state.loading, loadingThirdPartyCostBalance: true },
    loaded: { ...state.loaded, loadedThirdPartyCostBalance: false },
  };
}

function getThirdPartyCostBalanceSuccess(state: ScopeOverviewState, { thirdPartyCostBalance }: any) {
  return {
    ...state,
    thirdPartyCostBalance,
    loading: { ...state.loading, loadingThirdPartyCostBalance: false },
    loaded: { ...state.loaded, loadedThirdPartyCostBalance: true },
  };
}

function getThirdPartyCostBalanceFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingThirdPartyCostBalance: false },
    loaded: { ...state.loaded, loadedThirdPartyCostBalance: false },
  };
}

function changeScopeVersionHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingChangeVersion: true },
    loaded: { ...state.loaded, loadingChangeVersion: false },
  };
}

function changeScopeVersionSuccess(state: ScopeOverviewState, { currentScope }: any) {
  return {
    ...state,
    loading: { ...state.loading, loadingChangeVersion: false },
    loaded: { ...state.loaded, loadingChangeVersion: true },
    currentScope,
  };
}

function changeScopeVersionFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingChangeVersion: false },
    loaded: { ...state.loaded, loadingChangeVersion: true },
  };
}

function addScopeSection(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAddScopeSection: true },
    loaded: { ...state.loaded, loadingAddScopeSection: false },
  };
}

function addScopeSectionSuccess(state: ScopeOverviewState, { scopeSection }: any) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  currentScopeInstance.sections = [...currentScopeInstance.sections, scopeSection];

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeSection: false },
    loaded: { ...state.loaded, loadingAddScopeSection: true },
  };
}

function addScopeSectionFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAddScopeSection: false },
    loaded: { ...state.loaded, loadingAddScopeSection: true },
  };
}

function deleteScopeSection(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingLibraryEntry: false },
    loaded: { ...state.loaded, loadedLibraryEntry: true },
  };
}

function deleteScopeSectionSuccess(state: ScopeOverviewState, action: any) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  currentScopeInstance.sections = currentScopeInstance.sections.filter(section => section.id !== action.sectionId);

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingDeleteScopeSection: false },
    loaded: { ...state.loaded, loadedDeleteScopeSection: true },
  };
}

function deleteScopeSectionFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeleteScopeSection: false },
    loaded: { ...state.loaded, loadedDeleteScopeSection: true },
  };
}

function archiveScope(state: ScopeOverviewState, { scopeSectionId }: any) {
  return {
    ...state,
    loading: { ...state.loading, loadingArchiveScopeSection: true },
    loaded: { ...state.loaded, loadingArchiveScopeSection: false },
  };
}

function archiveScopeSuccess(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingArchiveScopeSection: false },
    loaded: { ...state.loaded, loadingArchiveScopeSection: true },
  };
}

function archiveScopeFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingArchiveScopeSection: false },
    loaded: { ...state.loaded, loadingArchiveScopeSection: true },
  };
}

function unarchiveScope(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingUnarchiveScopeSection: true },
    loaded: { ...state.loaded, loadingUnarchiveScopeSection: false },
  };
}

function unarchiveScopeSuccess(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingUnarchiveScopeSection: false },
    loaded: { ...state.loaded, loadingUnarchiveScopeSection: true },
  };
}

function unarchiveScopeFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingUnarchiveScopeSection: false },
    loaded: { ...state.loaded, loadingUnarchiveScopeSection: true },
  };
}

function getScopeDeliverable(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingScopeDeliverable: true },
    loaded: { ...state.loaded, loadedScopeDeliverable: false },
  };
}

function getScopeDeliverableSuccessHandler(state: ScopeOverviewState, { deliverable }: any) {
  let allComponentsInitialised = true
  let allComponentFees = state.allComponentFees
  let allComponentTPCs = state.allComponentTPCs
  let allSectionTPCs = state.allSectionTPCs

  let deliverables = state.currentScope.deliverables.map((d) => {
    if (d.id === deliverable.id) {
      if (!state.loaded.loadedAllFees) {
        allComponentFees = allComponentFees.concat(deliverable.components.flatMap((component) => component.feeItems))
      }
      if (!state.loaded.loadedAllThirdPartyCosts) {
        allComponentTPCs = allComponentTPCs.concat(deliverable.components.flatMap((component) => component.thirdPartyCosts))
        allSectionTPCs = allSectionTPCs.concat(deliverable.sections.flatMap((section) => section.thirdPartyCosts))
      }
      return plainToInstance(Deliverable, { ...deliverable, componentsInitialised: true })
    }
    if (!d.componentsInitialised) allComponentsInitialised = false
    return d
  })

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, { ...state.currentScope, deliverables: deliverables }),
    allComponentFees: allComponentFees,
    allComponentTPCs: allComponentTPCs,
    allSectionTPCs: allSectionTPCs,
    loading: { ...state.loading, loadingScopeDeliverable: false },
    loaded: {
      ...state.loaded, loadedScopeDeliverable: true,
      loadedAllFees: state.loaded.loadedAllFees || allComponentsInitialised,
      loadedAllThirdPartyCosts: state.loaded.loadedAllThirdPartyCosts || allComponentsInitialised
    }
  }
}

function getScopeDeliverableFailHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingScopeDeliverable: false },
    loaded: { ...state.loaded, loadedScopeDeliverable: false },
  };
}

function getAllTasksHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAllTasks: true },
    loaded: { ...state.loaded, loadedAllTasks: false },
  };
}

function getAllTasksSuccessHandler(state: ScopeOverviewState, { tasks }: any) {
  let deliverables = tasks.map((deliverable) => {
    return plainToInstance(Deliverable, { ...deliverable, componentsInitialised: true })
  })

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, { ...state.currentScope, deliverables: deliverables }),
    loading: { ...state.loading, loadingAllTasks: false },
    loaded: {
      ...state.loaded, loadedAllTasks: true
    }
  }
}

function getAllTasksFailHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAllTasks: false },
    loaded: { ...state.loaded, loadedAllTasks: false },
  };
}

function getAvailableDeliverableTypes(state: ScopeOverviewState) {
  return {
    ...state,
    availableDeliverableTypes: null,
    loading: { ...state.loading, loadingAvailableDeliverableTypes: true },
    loaded: { ...state.loaded, loadedAvailableDeliverableTypes: false },
  };
}

function getAvailableDeliverableTypesSuccess(state: ScopeOverviewState, { availableDeliverableTypes }: any) {
  return {
    ...state,
    availableDeliverableTypes: availableDeliverableTypes,
    loading: { ...state.loading, loadingAvailableDeliverableTypes: false },
    loaded: { ...state.loaded, loadedAvailableDeliverableTypes: true },
  };
}

function getAvailableDeliverableTypesFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingAvailableDeliverableTypes: false },
    loaded: { ...state.loaded, loadedAvailableDeliverableTypes: true },
  };
}

function addScopeDeliverable(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAddScopeDeliverable: true },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: false },
  };
}

function addScopeDeliverableSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  const deliverableInstance = plainToInstance(Deliverable, action.deliverable);
  deliverableInstance.componentsInitialised = true
  let deliverables = currentScopeInstance.deliverables

  if (!deliverables.find((del) => del.id == deliverableInstance.id)) deliverables.push(deliverableInstance)

  currentScopeInstance.deliverables = deliverables

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addScopeDeliverableFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addScopeDeliverablesSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverables = currentScopeInstance.deliverables
  action.deliverables.forEach((deliverable) => {
    const deliverableInstance = plainToInstance(Deliverable, deliverable);
    deliverableInstance.componentsInitialised = true

    if (!deliverables.find((del) => del.id == deliverableInstance.id)) deliverables.push(deliverableInstance)
  });

  currentScopeInstance.deliverables = deliverables

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function duplicateScopeDeliverable(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDuplicateDeliverable: true },
    loaded: { ...state.loaded, loadedDuplicateDeliverable: false },
  };
}

function moveComponentToDeliverable(state: ScopeOverviewState, action) {
  let currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope)
  let deliverable = currentScopeInstance.deliverables.find(d => d.components?.find(c => c.id == action.componentId))
  deliverable.components = deliverable.components.filter(c => c.id != action.componentId)
  let index = 0;
  deliverable.components.sort((c1, c2) => c1.order - c2.order).forEach(c => {
    c.order = index
    index++
  })
  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: {...state.loading, loadingMoveComponentToDeliverable: true},
    loaded: {...state.loaded, loadedMoveComponentToDeliverable: false},
  };
}

function duplicateScopeDeliverableSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);
  const deliverableInstance = plainToInstance(Deliverable, action.deliverable);
  deliverableInstance.componentsInitialised = true
  let deliverables = currentScopeInstance.deliverables

  if (!deliverables.find((del) => del.id == deliverableInstance.id)) deliverables.push(deliverableInstance)

  currentScopeInstance.deliverables = deliverables

  return {
    ...state,
    currentScope: currentScopeInstance,
    filteredDeliverables: state.filteredDeliverables?.length ? [...state.filteredDeliverables, deliverableInstance] : undefined,
    loading: { ...state.loading, loadingDuplicateDeliverable: false },
    loaded: { ...state.loaded, loadedDuplicateDeliverable: true },
  };
}

function duplicateScopeDeliverableFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDuplicateDeliverable: false },
    loaded: { ...state.loaded, loadedDuplicateDeliverable: true },
  };
}

function moveComponentToDeliverableFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingMoveComponentToDeliverable: false },
    loaded: { ...state.loaded, loadedMoveComponentToDeliverable: true },
  };
}

function moveComponentToDeliverableSuccessHandler(state: ScopeOverviewState, {deliverable}: any): ScopeOverviewState {
  let clonedDel = cloneDeep(deliverable);
  clonedDel.componentsInitialised = true
  let currentScopeInstance  = plainToInstance(ScopeVersion, state.currentScope)
  currentScopeInstance.deliverables = [...currentScopeInstance.deliverables.filter(del => del.id != clonedDel.id),clonedDel]
  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function updateScopeDeliverable(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let index = currentScopeInstance.deliverables.findIndex((d) => d.id == action.deliverable.id)
  currentScopeInstance.deliverables[index] = action.deliverable

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateDeliverable: true },
    loaded: { ...state.loaded, loadedUpdateDeliverable: false },
  };
}

function updateScopeDeliverableSuccessHandler(state: ScopeOverviewState, {deliverable}: any): ScopeOverviewState {
  let clonedDel = cloneDeep(deliverable);
  clonedDel.componentsInitialised = true
  let currentScopeInstance  = plainToInstance(ScopeVersion, state.currentScope)
  currentScopeInstance.deliverables = [...currentScopeInstance.deliverables.filter(del => del.id != clonedDel.id),clonedDel]
  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function updateScopeDeliverableFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function clearScopeTaskRoles(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let index = currentScopeInstance.deliverables.findIndex((d) => d.id == action.taskId)
  currentScopeInstance.deliverables[index].components[0].departments.forEach(dep => {
    dep.roles.forEach(r => r.rateCardMinutes = 0)
  })

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateDeliverable: true },
    loaded: { ...state.loaded, loadedUpdateDeliverable: false },
  };
}

function clearScopeTaskRolesSuccessHandler(state: ScopeOverviewState): ScopeOverviewState {
   return {
    ...state,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function clearScopeTaskRolesFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function updateScopeSection(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let index = currentScopeInstance.sections.findIndex((s) => s.id == action.section.id)
  currentScopeInstance.sections[index] = action.section

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateSection: true },
    loaded: { ...state.loaded, loadedUpdateSection: false },
  };
}

function updateScopeSectionSuccessHandler(state: ScopeOverviewState): ScopeOverviewState {
  return {
    ...state,
    loading: { ...state.loading, loadingUpdateSection: false },
    loaded: { ...state.loaded, loadedUpdateSection: true },
  };
}

function updateScopeSectionFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateSection: false },
    loaded: { ...state.loaded, loadedUpdateSection: true },
  };
}

function updateScopeComponent(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((d) => d.id === action.deliverableId);
  if (deliverable && deliverable.components) {
    let index = deliverable.components.findIndex((c) => c.id == action.component.id)
    deliverable.components[index] = action.component
  }
  if(action.deliverable){
    let deliverable = currentScopeInstance.deliverables.find(d=> d.id == action.deliverable.id)
    deliverable.components = action.deliverable.components
  }
  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateDeliverable: true },
    loaded: { ...state.loaded, loadedUpdateDeliverable: false },
  };
}

function updateScopeComponentSuccessHandler(state: ScopeOverviewState): ScopeOverviewState {
  return {
    ...state,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function updateScopeComponentFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function updateDeliverableSectionHandler(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((d) => d.id === action.section.deliverableId);
  if (deliverable && deliverable.sections) {
    let index = deliverable.sections.findIndex((c) => c.id == action.section.id)
    deliverable.sections[index] = action.section
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateDeliverable: true },
    loaded: { ...state.loaded, loadedUpdateDeliverable: false },
  };
}

function updateDeliverableSectionSuccessHandler(state: ScopeOverviewState): ScopeOverviewState {
  return {
    ...state,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function updateDeliverableSectionFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateDeliverable: false },
    loaded: { ...state.loaded, loadedUpdateDeliverable: true },
  };
}

function overrideScopeComponentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.component.deliverable.id)
  if (deliverable) {
    let component = deliverable.components.find((com) => com.id == action.component.id)
    if (component) {
      component.comments = action.component.comments
      component.quantity = action.component.quantity
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function overrideRoleSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.component.deliverable.id)
  if (deliverable) {
    let component = deliverable.components.find((com) => com.id == action.component.id)
    if (component) {
      let department = component.departments.find((dep) => dep.id == action.role.department.id)
      if (department) {
        let role = department.roles.find((role) => role.id == action.role.id)
        if (role) {
          role.rateCardMinutes = action.role.rateCardMinutes
        }
      }
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function updateRoleHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.taskId)
  if (deliverable) {
    let department = deliverable.components[0].departments.find((dep) => dep.id == action.role.department.id)
    if (department) {
      let role = department.roles.find((role) => role.id == action.role.id)
      if (role) {
        Object.assign(role, action.role)
      }
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function createScopeTpcSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);
  currentScopeInstance.thirdPartyCosts.push(plainToInstance(ThirdPartyCost, action.thirdPartyCost));

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function updateScopeTpcSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);
  let tpc = currentScopeInstance.thirdPartyCosts.find((t) => t.id == action.thirdPartyCost.id)
  if (tpc) {
    Object.assign(tpc, plainToInstance(ThirdPartyCost, action.thirdPartyCost))
  }

  return {
    ...state,
    currentScope: currentScopeInstance
   };
}

function deleteScopeTpcSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);
  let thirdPartyCosts = currentScopeInstance.thirdPartyCosts.filter((t) => t.id != action.thirdPartyCost.id)

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, { ...currentScopeInstance, thirdPartyCosts })
   };
}

function deleteDeliverable(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDeleteDeliverable: true },
    loaded: { ...state.loaded, loadingDeleteDeliverable: false },
  };
}

function deleteDeliverableSuccess(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  currentScopeInstance.deliverables = currentScopeInstance.deliverables.filter(
    (deliverable) => deliverable.id !== action.deliverableId
  );

  return {
    ...state,
    currentScope: currentScopeInstance,
    filteredDeliverables: state.filteredDeliverables?.filter(
      (deliverable) => deliverable.id !== action.deliverableId
    ),
    loading: { ...state.loading, loadingDeleteDeliverable: false },
    loaded: { ...state.loaded, loadedDeleteDeliverable: true },
  };
}

function deleteDeliverableFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeleteDeliverable: false },
    loaded: { ...state.loaded, loadedDeleteDeliverable: true },
  };
}

function createLibraryTemplate(state: ScopeOverviewState) {
  return {
    ...state,
    deliverableLibraryTemplate: undefined,
    loading: { ...state.loading, loadingLibraryTemplate: true },
    loaded: { ...state.loaded, loadedLibraryTemplate: false },
  };
}

function createLibraryTemplateSuccess(state: ScopeOverviewState, { template }: any) {
  return {
    ...state,
    deliverableLibraryTemplate: template,
    loading: { ...state.loading, loadingLibraryTemplate: false },
    loaded: { ...state.loaded, loadedLibraryTemplate: true },
  };
}

function createLibraryTemplateFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingLibraryTemplate: false },
    loaded: { ...state.loaded, loadedLibraryTemplate: true },
  };
}

function createLibraryEntry(state: ScopeOverviewState) {
  return {
    ...state,
    deliverableLibraryEntry: undefined,
    loading: { ...state.loading, loadingLibraryEntry: true },
    loaded: { ...state.loaded, loadedLibraryEntry: false },
  };
}

function createLibraryEntrySuccess(state: ScopeOverviewState, { updateEntry }: any) {
  return {
    ...state,
    deliverableLibraryEntry: updateEntry,
    loading: { ...state.loading, loadingLibraryEntry: false },
    loaded: { ...state.loaded, loadedLibraryEntry: true },
  };
}

function createLibraryEntryFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingLibraryEntry: false },
    loaded: { ...state.loaded, loadedLibraryEntry: true },
  };
}

function getOutputTemplate(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingOutputTemplate: true },
    loaded: { ...state.loaded, loadedOutputTemplate: false },
  };
}

function getOutputTemplateSuccess(state: ScopeOverviewState, { template }: any) {
  return {
    ...state,
    outputTemplate: template,
    loading: { ...state.loading, loadingOutputTemplate: false },
    loaded: { ...state.loaded, loadedOutputTemplate: true },
  };
}

function getOutputTemplateFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingOutputTemplate: false },
    loaded: { ...state.loaded, loadedOutputTemplate: true },
  };
}

function updateTemplateFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
  };
}

function previewScopeDocx(state: ScopeOverviewState, { preview }: any) {
  return {
    ...state,
    docxPreview: undefined,
  };
}

function previewScopeDocxSuccess(state: ScopeOverviewState, { preview }: any) {
  return {
    ...state,
    docxPreview: preview,
  };
}

function previewScopeDocxFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    previewError: error,
  };
}

function getSecondParty(state: ScopeOverviewState) {
  return {
    ...state,
    secondParty: null,
    loading: { ...state.loading, loadingSecondParty: true },
    loaded: { ...state.loaded, loadedSecondParty: false },
  };
}

function getSecondPartySuccess(state: ScopeOverviewState, { secondParty }: any) {
  return {
    ...state,
    secondParty: secondParty,
    loading: { ...state.loading, loadingSecondParty: false },
    loaded: { ...state.loaded, loadedSecondParty: true },
  };
}

function getSecondPartyFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingSecondParty: false },
    loaded: { ...state.loaded, loadedSecondParty: true },
  };
}

function getOfficeLocation(state: ScopeOverviewState) {
  return {
    ...state,
    officeLocation: null,
    loading: { ...state.loading, loadingOfficeLocation: true },
    loaded: { ...state.loaded, loadedOfficeLocation: false },
  };
}

function getOfficeLocationSuccess(state: ScopeOverviewState, { officeLocation }: any) {
  return {
    ...state,
    officeLocation: officeLocation,
    loading: { ...state.loading, loadingOfficeLocation: false },
    loaded: { ...state.loaded, loadedOfficeLocation: true },
  };
}

function getOfficeLocationFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingOfficeLocation: false },
    loaded: { ...state.loaded, loadedOfficeLocation: true },
  };
}

function getSowRetainedTeamStats(state: ScopeOverviewState) {
  return {
    ...state,
    sowRetainedTeamStats: null,
    loading: { ...state.loading, loadingSowRetainedTeamStats: true },
    loaded: { ...state.loaded, loadedSowRetainedTeamStats: false },
  };
}

function getSowRetainedTeamStatsSuccess(state: ScopeOverviewState, { sowRetainedTeamStats }: any) {
  return {
    ...state,
    sowRetainedTeamStats,
    loading: { ...state.loading, loadingSowRetainedTeamStats: false },
    loaded: { ...state.loaded, loadedSowRetainedTeamStats: true },
  };
}

function getSowRetainedTeamStatsFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingSowRetainedTeamStats: false },
    loaded: { ...state.loaded, loadedSowRetainedTeamStats: false },
  };
}

function getScopeCustomFieldsDefinitionOutdated(state: ScopeOverviewState) {
  return {
    ...state,
    customFieldsOutdated: null
  };
}

function getScopeCustomFieldsDefinitionOutdatedSuccess(state: ScopeOverviewState, { outdated }: any) {
  return {
    ...state,
    customFieldsOutdated: outdated
  };
}

function getScopeCustomFieldsDefinitionOutdatedFail(state: ScopeOverviewState) {
  return {
    ...state
  };
}

function getAllRateCardsForScopeCreate(state: ScopeOverviewState) {
  return {
    ...state,
    rateCards: null,
    loading: { ...state.loading, loadingRateCards: true },
    loaded: { ...state.loaded, loadedRateCards: false },
  };
}

function getAllRateCardsForScopeCreateSuccess(state: ScopeOverviewState, { rateCards }: any) {
  return {
    ...state,
    rateCards,
    loading: { ...state.loading, loadingRateCards: false },
    loaded: { ...state.loaded, loadedRateCards: true },
  };
}

function getAllRateCardsForScopeCreateFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingRateCards: false },
    loaded: { ...state.loaded, loadedRateCards: false },
  };
}

function getDefaultRateCard(state: ScopeOverviewState) {
  return {
    ...state,
    defaultRateCard: null,
    loading: { ...state.loading, loadingDefaultRateCard: true },
    loaded: { ...state.loaded, loadedDefaultRateCard: false },
  };
}

function getDefaultRateCardSuccess(state: ScopeOverviewState, { defaultRateCard }: any) {
  return {
    ...state,
    defaultRateCard,
    loading: { ...state.loading, loadingDefaultRateCard: false },
    loaded: { ...state.loaded, loadedDefaultRateCard: true },
  };
}

function getDefaultRateCardFail(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDefaultRateCard: false },
    loaded: { ...state.loaded, loadedDefaultRateCard: false },
  };
}

function updateScope(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: action.scope,
    loading: { ...state.loading, loadingUpdateScopeDetails: true },
    loaded: { ...state.loaded, loadedUpdateScopeDetails: false },
  };
}

function updateScopeSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  if (action.scope.isScopeByRole()) {
    action.scope.deliverables.filter((d) => !d.section).forEach((deliverable) => {
      deliverable.departments?.forEach((d) => {
        d.roles.forEach((r) => (r.fte = r.getFtePercentage(action.scope.identity.fteValue)))
      })
    })
  }
  return {
    ...state,
    currentScope: action.scope,
    loading: { ...state.loading, loadingUpdateScopeDetails: false },
    loaded: { ...state.loaded, loadedUpdateScopeDetails: true },
  };
}

function updateScopeFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateScopeDetails: false },
    loaded: { ...state.loaded, loadedUpdateScopeDetails: true },
  };
}

function refreshOutdatedCustomFields(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingRefreshOutdatedCustomFields: true },
    loaded: { ...state.loaded, loadedRefreshOutdatedCustomFields: false },
  };
}

function refreshOutdatedCustomFieldsSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      companyScopeCustomFieldsDefinition: plainToInstance(
        CompanyScopeCustomFieldsDefinition,
        action.updatedScopeVersionCustomFields.companyScopeVersionCustomFieldsDefinition
      ),
      scopeVersionCustomFieldValueStructure: plainToInstance(
        ScopeCustomFieldValueStructure,
        action.updatedScopeVersionCustomFields.scopeVersionCustomFieldValueStructure
      )
    }),
    customFieldsOutdated: false,
    loading: { ...state.loading, loadingRefreshOutdatedCustomFields: false },
    loaded: { ...state.loaded, loadedRefreshOutdatedCustomFields: true },
  };
}

function refreshOutdatedCustomFieldsFailHandler(state: ScopeOverviewState, error) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingRefreshOutdatedCustomFields: false },
    loaded: { ...state.loaded, loadedRefreshOutdatedCustomFields: true },
  };
}

function addScopeCollaborator(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function addScopeCollaboratorSuccessHandler(state: ScopeOverviewState, action) {
  let collaborators = unionBy(state.currentScope.collaborators, action.collaborators.map((c) => plainToInstance(ScopeSubmitter, { user: c.user })), 'user.id')
  let teamCollaborators = unionBy(state.currentScope.identity.team.collaborators, action.collaborators, 'id')

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      collaborators: collaborators,
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          collaborators: teamCollaborators
        })
      }
    }),
    loading: { ...state.loading, loadingUpdateScopeDetails: false },
    loaded: { ...state.loaded, loadedUpdateScopeDetails: true },
  };
}

function addScopeReviewer(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function addScopeReviewerSuccessHandler(state: ScopeOverviewState, action) {
  let reviewers = unionBy(state.currentScope.reviewers, action.reviewers.map((c) => plainToInstance(ScopeSubmitter, { user: c.user })), 'user.id')
  let teamReviewers = unionBy(state.currentScope.identity.team.reviewers, action.reviewers, 'id')

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      reviewers: reviewers,
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          reviewers: teamReviewers
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function addScopeApprover(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function addScopeApproverSuccessHandler(state: ScopeOverviewState, action) {
  let approvers = unionBy(state.currentScope.approvers, action.approvers.map((c) => plainToInstance(ScopeVoter, { voter: c.user })), 'voter.id')
  let teamApprovers = unionBy(state.currentScope.identity.team.approvers, action.approvers, 'id')

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      approvers: approvers,
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          approvers: teamApprovers
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function addScopeTrafficker(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function addScopeTraffickerSuccessHandler(state: ScopeOverviewState, action) {
  let traffickers = unionBy(state.currentScope.identity.team.traffickers, action.traffickers, 'id')

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          traffickers: traffickers
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function updateTeamMember(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function updateTeamMemberSuccessHandler(state: ScopeOverviewState, action) {
  let team
  if (action.member.type == "COLLABORATOR"){
    team = plainToInstance(ScopeTeam, {
      ...state.currentScope.identity.team,
      collaborators: state.currentScope.identity.team.collaborators.map((c) => c.id == action.member.id ? action.member : c)
    })
  } else if (action.member.type == "REVIEWER"){
    team = plainToInstance(ScopeTeam, {
      ...state.currentScope.identity.team,
      reviewers: state.currentScope.identity.team.reviewers.map((c) => c.id == action.member.id ? action.member : c)
    })
  } else if (action.member.type == "APPROVER"){
    team = plainToInstance(ScopeTeam, {
      ...state.currentScope.identity.team,
      approvers: state.currentScope.identity.team.approvers.map((c) => c.id == action.member.id ? action.member : c)
    })
  } else if (action.member.type == "TRAFFICKER"){
    team = plainToInstance(ScopeTeam, {
      ...state.currentScope.identity.team,
      traffickers: state.currentScope.identity.team.traffickers.map((c) => c.id == action.member.id ? action.member : c)
    })
  }

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      identity: {
        ...state.currentScope.identity,
        team: team
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeCollaborator(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeCollaboratorSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      collaborators: state.currentScope.collaborators.filter((c) => c.user.id !== action.member.user.id),
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          collaborators: state.currentScope.identity.team.collaborators.filter((c) => c.id !== action.member.id)
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeReviewer(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeReviewerSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      reviewers: state.currentScope.reviewers.filter((c) => c.user.id !== action.member.user.id),
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          reviewers: state.currentScope.identity.team.reviewers.filter((c) => c.id !== action.member.id)
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeApprover(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeApproverSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      approvers: state.currentScope.approvers.filter((c) => c.voter.id !== action.member.user.id),
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          approvers: state.currentScope.identity.team.approvers.filter((c) => c.id !== action.member.id)
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeTrafficker(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function removeScopeTraffickerSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      identity: {
        ...state.currentScope.identity,
        team: plainToInstance(ScopeTeam, {
          ...state.currentScope.identity.team,
          traffickers: state.currentScope.identity.team.traffickers.filter((c) => c.id !== action.member.id)
        })
      }
    }),
    loading: { ...state.loading, loadingTeamMemberUpdate: true },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: false },
  };
}

function scopeTeamFailHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    teamError: action.error,
    loading: { ...state.loading, loadingTeamMemberUpdate: false },
    loaded: { ...state.loaded, loadedTeamMemberUpdate: true },
  };
}

function deleteComponentFeeSuccess(state: ScopeOverviewState, action) {
  // Create an instance of the relevant class from the current state's currentScope
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((d) => d.id === action.deliverableId);
  if (deliverable && deliverable.components) {
    let component = deliverable.components.find((c) => c.id === action.componentId);
    if (component) {
      component.feeItems = component.feeItems?.filter((s) => s.id !== action.feeItemId);
    }
  }

  let allComponentFees = state.allComponentFees.filter((f) => f.id !== action.feeItemId)

  return {
    ...state,
    currentScope: currentScopeInstance,
    allComponentFees: allComponentFees
  };
}

function deleteComponentFeeFail(state: ScopeOverviewState, error) {
  return {
    ...state,
    error
  };
}

function deleteDeliverableFeeSuccess(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((d) => d.id === action.deliverableId);
  if (deliverable) {
    deliverable.feeItems = deliverable.feeItems?.filter((s) => s.id !== action.feeItemId);
  }

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function deleteDeliverableFeeFail(state: ScopeOverviewState, error: any) {
  return {
    ...state,
    error
  };
}

function deleteSectionFee(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDeleteSectionFee: true },
    loaded: { ...state.loaded, loadedDeleteSectionFee: false },
  };
}

function deleteSectionFeeSuccess(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let section = currentScopeInstance.sections.find((s) => s.id === action.sectionId);
  if (section) {
    section.feeItems = section.feeItems?.filter((s) => s.id !== action.feeItemId);
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingDeleteSectionFee: false },
    loaded: { ...state.loaded, loadedDeleteSectionFee: true },
  };
}

function deleteSectionFeeFail(state: ScopeOverviewState, error: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeleteSectionFee: false },
    loaded: { ...state.loaded, loadedDeleteSectionFee: true },
  };
}

function deleteScopeFee(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDeleteScopeFee: true },
    loaded: { ...state.loaded, loadedDeleteScopeFee: false },
  };
}

function deleteScopeFeeSuccess(state: ScopeOverviewState, action) {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  currentScopeInstance.feeItems = currentScopeInstance.feeItems?.filter((s) => s.id !== action.feeItemId);

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingDeleteScopeFee: true },
    loaded: { ...state.loaded, loadedDeleteScopeFee: false },
  };
}

function deleteScopeFeeFail(state: ScopeOverviewState, error: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeleteScopeFee: true },
    loaded: { ...state.loaded, loadedDeleteScopeFee: false },
  };
}

function filterDeliverablesSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    filteredDeliverables: action.filteredDeliverables,
  };
}

function createScopeFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      feeItems: [...state.currentScope.feeItems, action.feeItem]
    })
  };
}

function createSectionFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let sections = cloneDeep(state.currentScope.sections)
  sections.forEach((s) => {
    if (s.id == action.sectionId) {
      s.feeItems.push(action.feeItem)
    }
  })

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      sections: sections
    })
  };
}

function createDeliverableFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let deliverables = cloneDeep(state.currentScope.deliverables)
  deliverables.forEach((d) => {
    if (d.id == action.deliverableId) {
      d.feeItems.push(action.feeItem)
    }
  })

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      deliverables: deliverables
    })
  };
}

function createComponentFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let deliverables = cloneDeep(state.currentScope.deliverables)
  deliverables.forEach((d) => {
    if (d.id == action.deliverableId && d.components) {
      d.components.forEach((c) => {
        if (c.id == action.componentId) {
          c.feeItems.push(action.feeItem)
        }
      })
    }
  })

  let allComponentFees = state.allComponentFees.concat([action.feeItem])

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      deliverables: deliverables
    }),
    allComponentFees: allComponentFees
  };
}

function editScopeFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let feeItems = cloneDeep(state.currentScope.feeItems)
  let index = feeItems.findIndex((f) => f.id == action.feeItemId)
  feeItems[index] = action.feeItem

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      feeItems: feeItems
    })
  };
}

function editSectionFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let sections = cloneDeep(state.currentScope.sections)
  sections.forEach((s) => {
    if (s.id == action.sectionId) {
      let index = s.feeItems.findIndex((f) => f.id == action.feeItemId)
      s.feeItems[index] = action.feeItem
    }
  })

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      sections: sections
    })
  };
}

function editDeliverableFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let deliverables = cloneDeep(state.currentScope.deliverables)
  deliverables.forEach((d) => {
    if (d.id == action.deliverableId) {
      let index = d.feeItems.findIndex((f) => f.id == action.feeItemId)
      d.feeItems[index] = action.feeItem
    }
  })

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      deliverables: deliverables
    })
  };
}

function editComponentFeeItemSuccessHandler(state: ScopeOverviewState, action) {
  let deliverables = cloneDeep(state.currentScope.deliverables)
  deliverables.forEach((d) => {
    if (d.id == action.deliverableId && d.components) {
      d.components.forEach((c) => {
        if (c.id == action.componentId) {
          let index = c.feeItems.findIndex((f) => f.id == action.feeItemId)
          c.feeItems[index] = action.feeItem
        }
      })
    }
  })

  let allComponentFees = state.allComponentFees.map((f) =>
    f.id === action.feeItemId ? action.feeItem : f
  )

  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      deliverables: deliverables
    }),
    allComponentFees: allComponentFees
  };
}

function updateScopeDiscountSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      discount: action.discount
    })
  };
}

function updateScopeMsaSuccessHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      msaLineItem: action.msaLineItem
    })
  };
}

function updateScopeRetainedHoursStatsSuccessHandler(state: ScopeOverviewState, { scopeRetainedHoursStats }: any) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      useRetainedHours: !state.currentScope.useRetainedHours,
    }),
    scopeRetainedHourStats: scopeRetainedHoursStats,
  };
}

function updateScopeSectionsOrderHandler(state: ScopeOverviewState, action) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, {
      ...state.currentScope,
      sections: action.sections
    })
  };
}

function updateDeliverablesOrderHandler(state: ScopeOverviewState, action) {
  let currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope)
  if (action.deliverables.length) {
    if (currentScopeInstance.sections.length > 0) {
      currentScopeInstance.deliverables = currentScopeInstance.deliverables.map((del) =>
        action.deliverables.find((update) => update.id == del.id) || del
      )
    } else {
      currentScopeInstance.deliverables = action.deliverables
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function deliverableTypeSearch(state: ScopeOverviewState) {
  return {
    ...state,
    availableDeliverableTypes: null,
    loading: { ...state.loading, loadingDeliverableTypeSearch: true },
    loaded: { ...state.loaded, loadedDeliverableTypeSearch: false },
  }
}

function deliverableTypeSearchSuccessHandler(state: ScopeOverviewState, { extendedDeliverables }: any) {
  return {
    ...state,
    availableDeliverableTypes: extendedDeliverables,
    loading: { ...state.loading, loadingDeliverableTypeSearch: false },
    loaded: { ...state.loaded, loadedDeliverableTypeSearch: true },
  }
}

function deliverableTypeSearchFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeliverableTypeSearch: false },
    loaded: { ...state.loaded, loadedDeliverableTypeSearch: true },
  }
}

function initiateTradeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingInitiateTrade: true },
    loaded: { ...state.loaded, loadedInitiateTrade: false },
  }
}

function initiateTradeSuccessHandler(state: ScopeOverviewState, { tradedDeliverables }: any) {
  return {
    ...state,
    tradedDeliverables,
    loading: { ...state.loading, loadingInitiateTrade: false },
    loaded: { ...state.loaded, loadedInitiateTrade: true },
  }
}

function initiateTradeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingInitiateTrade: false },
    loaded: { ...state.loaded, loadedInitiateTrade: true },
  }
}

function reopenTradeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingReopenTrade: true },
    loaded: { ...state.loaded, loadedReopenTrade: false },
  }
}

function reopenTradeSuccessHandler(state: ScopeOverviewState, { tradedDeliverables }: any) {
  return {
    ...state,
    tradedDeliverables,
    loading: { ...state.loading, loadingReopenTrade: false },
    loaded: { ...state.loaded, loadedReopenTrade: true },
  }
}

function reopenTradeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingReopenTrade: false },
    loaded: { ...state.loaded, loadedReopenTrade: true },
  }
}

function stopTradeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingStopTrade: true },
    loaded: { ...state.loaded, loadedStopTrade: false },
  }
}

function stopTradeSuccessHandler(state: ScopeOverviewState, { tradedDeliverables }: any) {
  return {
    ...state,
    tradedDeliverables,
    loading: { ...state.loading, loadingStopTrade: false },
    loaded: { ...state.loaded, loadedStopTrade: true },
  }
}

function stopTradeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingStopTrade: false },
    loaded: { ...state.loaded, loadedStopTrade: true },
  }
}

function completeTradeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingCompleteTrade: true },
    loaded: { ...state.loaded, loadedCompleteTrade: false },
  }
}

function completeTradeSuccessHandler(state: ScopeOverviewState, { tradedDeliverables }: any) {
  return {
    ...state,
    tradedDeliverables,
    loading: { ...state.loading, loadingCompleteTrade: false },
    loaded: { ...state.loaded, loadedCompleteTrade: true },
  }
}

function completeTradeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingCompleteTrade: false },
    loaded: { ...state.loaded, loadedCompleteTrade: true },
  }
}

function cancelTradeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingCancelTrade: true },
    loaded: { ...state.loaded, loadedCancelTrade: false },
  }
}

function cancelTradeSuccessHandler(state: ScopeOverviewState, { tradedDeliverables }: any) {
  return {
    ...state,
    tradedDeliverables,
    loading: { ...state.loading, loadingCancelTrade: false },
    loaded: { ...state.loaded, loadedCancelTrade: true },
  }
}

function cancelTradeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingCancelTrade: false },
    loaded: { ...state.loaded, loadedCancelTrade: true },
  }
}

function confirmTradeHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingConfirmTrade: true },
    loaded: { ...state.loaded, loadedConfirmTrade: false },
  }
}

function confirmTradeSuccessHandler(state: ScopeOverviewState, { tradedDeliverables }: any) {
  return {
    ...state,
    tradedDeliverables,
    loading: { ...state.loading, loadingConfirmTrade: false },
    loaded: { ...state.loaded, loadedConfirmTrade: true },
  }
}

function confirmTradeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingConfirmTrade: false },
    loaded: { ...state.loaded, loadedConfirmTrade: true },
  }
}

function applyLocationCardToDeliverableHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingApplyDeliverableLocationCard: true },
    loaded: { ...state.loaded, loadedApplyDeliverableLocationCard: false },
  }
}

function applyLocationCardToDeliverableSuccessHandler(state: ScopeOverviewState, { deliverable }: any) {
  let currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope)

  let index = currentScopeInstance.deliverables.findIndex((d) => d.id === deliverable.id)
  currentScopeInstance.deliverables[index] = deliverable

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingApplyDeliverableLocationCard: false },
    loaded: { ...state.loaded, loadedApplyDeliverableLocationCard: true },
  }
}

function applyLocationCardToDeliverableFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingApplyDeliverableLocationCard: false },
    loaded: { ...state.loaded, loadedApplyDeliverableLocationCard: true },
  }
}

function deleteScopeDiscountHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDeleteScopeDiscount: true },
    loaded: { ...state.loaded, loadedDeleteScopeDiscount: false },
  }
}

function deleteScopeDiscountSuccessHandler(state: ScopeOverviewState) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, { ...state.currentScope, discount: null }),
    loading: { ...state.loading, loadingDeleteScopeDiscount: false },
    loaded: { ...state.loaded, loadedDeleteScopeDiscount: true },
  }
}

function deleteScopeDiscountFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeleteScopeDiscount: false },
    loaded: { ...state.loaded, loadedDeleteScopeDiscount: true },
  }
}

function deleteScopeMsaHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingDeleteScopeMsa: true },
    loaded: { ...state.loaded, loadedDeleteScopeMsa: false },
  }
}

function deleteScopeMsaSuccessHandler(state: ScopeOverviewState) {
  return {
    ...state,
    currentScope: plainToInstance(ScopeVersion, { ...state.currentScope, msaLineItem: null }),
    loading: { ...state.loading, loadingDeleteScopeMsa: false },
    loaded: { ...state.loaded, loadedDeleteScopeMsa: true },
  }
}

function deleteScopeMsaFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingDeleteScopeMsa: false },
    loaded: { ...state.loaded, loadedDeleteScopeMsa: true },
  }
}

function updateCurrentScope(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingUpdateCurrentScope: true },
    loaded: { ...state.loaded, loadedUpdateCurrentScope: false },
  }
}

function updateCurrentScopeSuccessHandler(state: ScopeOverviewState, { currentScope }: any) {
  let currentScopeInstance = plainToInstance(ScopeVersion, currentScope)
  remapDeliverablesInTradedHierarchy(currentScopeInstance)

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingUpdateCurrentScope: false },
    loaded: { ...state.loaded, loadedUpdateCurrentScope: true },
  }
}

function updateCurrentScopeFailHandler(state: ScopeOverviewState, { error }: any) {
  return {
    ...state,
    error,
    loading: { ...state.loading, loadingUpdateCurrentScope: false },
    loaded: { ...state.loaded, loadedUpdateCurrentScope: true },
  }
}

function addDeliverableCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) deliverable.comments.push(action.comment)

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addComponentCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) {
    let component = deliverable.components.find((com) => com.id == action.componentId)
    if (component) component.comments.push(action.comment)
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addDepartmentCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) {
    let component = deliverable.components.find((com) => com.id == action.componentId)
    if (component) {
      let department = component.departments.find((dep) => dep.id == action.departmentId)
      if (department) department.comments.push(action.comment)
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addRoleCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) {
    let component = deliverable.components.find((com) => com.id == action.componentId)
    if (component) {
      component.departments.forEach((department) => {
        let role = department.roles.find((role) => role.id == action.roleId)
        if (role) role.comments.push(action.comment)
      })
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addDeliverableSectionCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) {
    let section = deliverable.sections.find((section) => section.id == action.sectionId)
    if (section) section.comments.push(action.comment)
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addScopeSectionCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let section = currentScopeInstance.sections.find((section) => section.id == action.sectionId)
  if (section) section.comments.push(action.comment)

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addDeliverableTPCCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) {
    let tpc = deliverable.thirdPartyCosts.find((tpc) => tpc.id == action.tpcId)
    if (tpc) tpc.comments.push(action.comment)
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addScopeTPCCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let tpc = currentScopeInstance.thirdPartyCosts.find((tpc) => tpc.id == action.tpcId)
  if (tpc) tpc.comments.push(action.comment)

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addComponentTPCCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  let deliverable = currentScopeInstance.deliverables.find((del) => del.id == action.deliverableId)
  if (deliverable) {
    let component = deliverable.components.find((com) => com.id == action.componentId)
    if (component) {
      let tpc = component.thirdPartyCosts.find((tpc) => tpc.id == action.tpcId)
      if (tpc) tpc.comments.push(action.comment)
    }
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function addScopeCommentSuccessHandler(state: ScopeOverviewState, action): ScopeOverviewState {
  const currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

  currentScopeInstance.comments.push(action.comment)

  return {
    ...state,
    currentScope: currentScopeInstance,
    loading: { ...state.loading, loadingAddScopeDeliverable: false },
    loaded: { ...state.loaded, loadedAddScopeDeliverable: true },
  };
}

function getDeliverableAndComponentFeesHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAllFees: true },
    loaded: { ...state.loaded, loadedAllFees: false },
  };
}

function getDeliverableAndComponentFeesSuccessHandler(state: ScopeOverviewState, action) {
  let currentScopeInstance: ScopeVersion
  if (action.deliverableFeeItems?.length) {
    currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

    let deliverable
    action.deliverableFeeItems.forEach((feeItem: FeeItemInstance) => {
      if (deliverable?.id !== feeItem.deliverable.id) {
        deliverable = currentScopeInstance.deliverables.find((del) => del.id == feeItem.deliverable.id)
      }

      if (!deliverable.componentsInitialised) {
        deliverable.feeItems.push(feeItem)
      }
    })
  } else {
    currentScopeInstance = state.currentScope
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    allComponentFees: action.componentFeeItems,
    loading: { ...state.loading, loadingAllFees: false },
    loaded: { ...state.loaded, loadedAllFees: true },
  };
}

function getDeliverableAndComponentFeesFailHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAllFees: false },
    loaded: { ...state.loaded, loadedAllFees: false },
  };
}

function getAllDeliverableTPCsHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAllTPCs: true },
    loaded: { ...state.loaded, loadedAllThirdPartyCosts: false },
  };
}

function getAllDeliverableTPCsSuccessHandler(state: ScopeOverviewState, action) {
  let currentScopeInstance: ScopeVersion
  if (action.deliverableThirdPartyCosts?.length) {
    currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope);

    let deliverable
    action.deliverableThirdPartyCosts.forEach((thirdPartyCost: ThirdPartyCost) => {
      if (deliverable?.id !== thirdPartyCost.deliverable.id) {
        deliverable = currentScopeInstance.deliverables.find((del) => del.id == thirdPartyCost.deliverable.id)
      }

      if (!deliverable.componentsInitialised) {
        deliverable.thirdPartyCosts.push(thirdPartyCost)
      }
    })
  } else {
    currentScopeInstance = state.currentScope
  }

  return {
    ...state,
    currentScope: currentScopeInstance,
    allComponentTPCs: action.componentThirdPartyCosts,
    allSectionTPCs: action.sectionThirdPartyCosts,
    loading: { ...state.loading, loadingAllTPCs: false },
    loaded: { ...state.loaded, loadedAllThirdPartyCosts: true },
  };
}

function getAllDeliverableTPCsFailHandler(state: ScopeOverviewState) {
  return {
    ...state,
    loading: { ...state.loading, loadingAllTPCs: false },
    loaded: { ...state.loaded, loadedAllThirdPartyCosts: false },
  };
}

function searchComponentsForScopeSuccessHandler(state: ScopeOverviewState, action: any) {
  return {
    ...state,
    scopeComponents: action.components.filter((item, i, arr) => arr.findIndex((t) => t.name === item.name && t.originalName === item.originalName) === i),
  };
}

function searchNonDistinctComponentsSuccessHandler(state: ScopeOverviewState, action: any) {
  return {
    ...state,
    nonDistinctComponents: action.components,
  };
}

function searchDepartmentsForScopeSuccessHandler(state: ScopeOverviewState, action: any) {
  return {
    ...state,
    scopeDepartments: action.departments,
  };
}

function searchRolesForScopeSuccessHandler(state: ScopeOverviewState, action: any) {
  return {
    ...state,
    scopeRoles: action.roles,
  };
}

function applyLocationToRoleSuccessHandler(state: ScopeOverviewState, action: any) {
  let currentScopeInstance = plainToInstance(ScopeVersion, state.currentScope)

  currentScopeInstance.deliverables.forEach((deliverable) => {
    let department = deliverable.components[0].departments.find((d) => d.source.id === action.sourceDepartmentId)
    if (department) {
      let role = department.roles.find((r) => r.source.id == action.sourceRoleId)
      if (role) {
        role.ratecardLocationRole = action.ratecardLocationRole
        role.locationName = action.locationName
        role.rateCardRate = action.rateCardRate
        role.rateCardCost = action.rateCardCost
      }
    }
  })

  return {
    ...state,
    currentScope: currentScopeInstance
  };
}

function setDialogIdHandler(state: ScopeOverviewState, action: any) {
  return { ...state,dialogId: action.id };
}

const ScopeOverviewReducer = createReducer(
  initialState,
  on(ScopeOverviewActions.getActivitiesSuccess, getActivityCommentsSuccessHandler),
  on(ScopeOverviewActions.submitCommentSuccess, submitCommentSuccessHandler),
  on(ScopeOverviewActions.getCurrentScope, getCurrentScopeHandler),
  on(ScopeOverviewActions.getCurrentScopeSuccess, getCurrentScopeSuccessHandler),
  on(ScopeOverviewActions.getCurrentScopeFail, getCurrentScopeFailHandler),
  on(ScopeOverviewActions.getScopeRetainedStats, getScopeRetainedStats),
  on(ScopeOverviewActions.getScopeRetainedStatsSuccess, getScopeRetainedStatsSuccess),
  on(ScopeOverviewActions.getScopeRetainedStatsFail, getScopeRetainedStatsFail),
  on(ScopeOverviewActions.getSowRetainedStats, getSowRetainedStats),
  on(ScopeOverviewActions.getSowRetainedStatsSuccess, getSowRetainedStatsSuccess),
  on(ScopeOverviewActions.getSowRetainedStatsFail, getSowRetainedStatsFail),
  on(ScopeOverviewActions.getThirdPartyBalance, getThirdPartyCostBalance),
  on(ScopeOverviewActions.getThirdPartyBalanceSuccess, getThirdPartyCostBalanceSuccess),
  on(ScopeOverviewActions.getThirdPartyBalanceFail, getThirdPartyCostBalanceFail),
  on(ScopeOverviewActions.changeScopeVersion, changeScopeVersionHandler),
  on(ScopeOverviewActions.changeScopeVersionSuccess, changeScopeVersionSuccess),
  on(ScopeOverviewActions.changeScopeVersionFail, changeScopeVersionFail),
  on(ScopeOverviewActions.addScopeSection, addScopeSection),
  on(ScopeOverviewActions.addScopeSectionSuccess, addScopeSectionSuccess),
  on(ScopeOverviewActions.addScopeSectionFail, addScopeSectionFail),
  on(ScopeOverviewActions.archiveScope, archiveScope),
  on(ScopeOverviewActions.archiveScopeSuccess, archiveScopeSuccess),
  on(ScopeOverviewActions.archiveScopeFail, archiveScopeFail),
  on(ScopeOverviewActions.unarchiveScope, unarchiveScope),
  on(ScopeOverviewActions.unarchiveScopeSuccess, unarchiveScopeSuccess),
  on(ScopeOverviewActions.unarchiveScopeFail, unarchiveScopeFail),
  on(ScopeOverviewActions.getScopeDeliverable, getScopeDeliverable),
  on(ScopeOverviewActions.getScopeDeliverableSuccess, getScopeDeliverableSuccessHandler),
  on(ScopeOverviewActions.getScopeDeliverableFail, getScopeDeliverableFailHandler),
  on(ScopeOverviewActions.getAllTasks, getAllTasksHandler),
  on(ScopeOverviewActions.getAllTasksSuccess, getAllTasksSuccessHandler),
  on(ScopeOverviewActions.getAllTasksFail, getAllTasksFailHandler),
  on(ScopeOverviewActions.getAvailableScopeDeliverableTypes, getAvailableDeliverableTypes),
  on(ScopeOverviewActions.getAvailableScopeDeliverableTypesSuccess, getAvailableDeliverableTypesSuccess),
  on(ScopeOverviewActions.getAvailableScopeDeliverableTypesFail, getAvailableDeliverableTypesFail),
  on(ScopeOverviewActions.addScopeDeliverable, addScopeDeliverable),
  on(ScopeOverviewActions.addScopeDeliverableSuccess, addScopeDeliverableSuccessHandler),
  on(ScopeOverviewActions.addScopeDeliverableFail, addScopeDeliverableFailHandler),
  on(ScopeOverviewActions.addScopeDeliverablesSuccess, addScopeDeliverablesSuccessHandler),
  on(ScopeOverviewActions.duplicateScopeDeliverable, duplicateScopeDeliverable),
  on(ScopeOverviewActions.duplicateScopeDeliverableSuccess, duplicateScopeDeliverableSuccessHandler),
  on(ScopeOverviewActions.duplicateScopeDeliverableFail, duplicateScopeDeliverableFailHandler),
  on(ScopeOverviewActions.moveComponentToDeliverable, moveComponentToDeliverable),
  on(ScopeOverviewActions.moveComponentToDeliverableSuccess, moveComponentToDeliverableSuccessHandler),
  on(ScopeOverviewActions.moveComponentToDeliverableFail, moveComponentToDeliverableFailHandler),
  on(ScopeOverviewActions.updateScopeDeliverable, updateScopeDeliverable),
  on(ScopeOverviewActions.updateScopeDeliverableSuccess, updateScopeDeliverableSuccessHandler),
  on(ScopeOverviewActions.updateScopeDeliverableFail, updateScopeDeliverableFailHandler),
  on(ScopeOverviewActions.clearScopeTaskRoles, clearScopeTaskRoles),
  on(ScopeOverviewActions.clearScopeTaskRolesSuccess, clearScopeTaskRolesSuccessHandler),
  on(ScopeOverviewActions.clearScopeTaskRolesFail, clearScopeTaskRolesFailHandler),
  on(ScopeOverviewActions.updateScopeSection, updateScopeSection),
  on(ScopeOverviewActions.updateScopeSectionSuccess, updateScopeSectionSuccessHandler),
  on(ScopeOverviewActions.updateScopeSectionFail, updateScopeSectionFailHandler),
  on(ScopeOverviewActions.updateScopeComponent, updateScopeComponent),
  on(ScopeOverviewActions.updateScopeComponentSuccess, updateScopeComponentSuccessHandler),
  on(ScopeOverviewActions.updateScopeComponentFail, updateScopeComponentFailHandler),
  on(ScopeOverviewActions.updateDeliverableSection, updateDeliverableSectionHandler),
  on(ScopeOverviewActions.updateDeliverableSectionSuccess, updateDeliverableSectionSuccessHandler),
  on(ScopeOverviewActions.updateDeliverableSectionFail, updateDeliverableSectionFailHandler),
  on(ScopeOverviewActions.overrideScopeComponentSuccess, overrideScopeComponentSuccessHandler),
  on(ScopeOverviewActions.overrideRoleSuccess, overrideRoleSuccessHandler),
  on(ScopeOverviewActions.updateRole, updateRoleHandler),
  on(ScopeOverviewActions.createScopeTpcSuccess, createScopeTpcSuccessHandler),
  on(ScopeOverviewActions.updateScopeTpcSuccess, updateScopeTpcSuccessHandler),
  on(ScopeOverviewActions.deleteScopeTpcSuccess, deleteScopeTpcSuccessHandler),
  on(ScopeOverviewActions.createLibraryDeliverableTemplate, createLibraryTemplate),
  on(ScopeOverviewActions.createLibraryDeliverableTemplateSuccess, createLibraryTemplateSuccess),
  on(ScopeOverviewActions.createLibraryDeliverableTemplateFail, createLibraryTemplateFail),
  on(ScopeOverviewActions.createLibraryDeliverableEntry, createLibraryEntry),
  on(ScopeOverviewActions.createLibraryDeliverableEntrySuccess, createLibraryEntrySuccess),
  on(ScopeOverviewActions.createLibraryDeliverableEntry, createLibraryEntryFail),
  on(ScopeOverviewActions.getOutputTemplate, getOutputTemplate),
  on(ScopeOverviewActions.getOutputTemplateSuccess, getOutputTemplateSuccess),
  on(ScopeOverviewActions.getOutputTemplateFail, getOutputTemplateFail),
  on(ScopeOverviewActions.updateTemplateFail, updateTemplateFail),
  on(ScopeOverviewActions.previewScopeDocx, previewScopeDocx),
  on(ScopeOverviewActions.previewScopeDocxSuccess, previewScopeDocxSuccess),
  on(ScopeOverviewActions.previewScopeDocxFail, previewScopeDocxFail),
  on(ScopeOverviewActions.getSecondParty, getSecondParty),
  on(ScopeOverviewActions.getSecondPartySuccess, getSecondPartySuccess),
  on(ScopeOverviewActions.getSecondPartyFail, getSecondPartyFail),
  on(ScopeOverviewActions.getOfficeLocation, getOfficeLocation),
  on(ScopeOverviewActions.getOfficeLocationSuccess, getOfficeLocationSuccess),
  on(ScopeOverviewActions.getOfficeLocationFail, getOfficeLocationFail),
  on(ScopeOverviewActions.getSowRetainedTeamStats, getSowRetainedTeamStats),
  on(ScopeOverviewActions.getSowRetainedTeamStatsSuccess, getSowRetainedTeamStatsSuccess),
  on(ScopeOverviewActions.getSowRetainedTeamStatsFail, getSowRetainedTeamStatsFail),
  on(ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdated, getScopeCustomFieldsDefinitionOutdated),
  on(ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdatedSuccess, getScopeCustomFieldsDefinitionOutdatedSuccess),
  on(ScopeOverviewActions.getScopeCustomFieldsDefinitionOutdatedFail, getScopeCustomFieldsDefinitionOutdatedFail),
  on(ScopeOverviewActions.getAllRateCardsForScopeCreate, getAllRateCardsForScopeCreate),
  on(ScopeOverviewActions.getAllRateCardsForScopeCreateSuccess, getAllRateCardsForScopeCreateSuccess),
  on(ScopeOverviewActions.getAllRateCardsForScopeCreateFail, getAllRateCardsForScopeCreateFail),
  on(ScopeOverviewActions.getDefaultRateCard, getDefaultRateCard),
  on(ScopeOverviewActions.getDefaultRateCardSuccess, getDefaultRateCardSuccess),
  on(ScopeOverviewActions.getDefaultRateCardFail, getDefaultRateCardFail),
  on(ScopeOverviewActions.deleteScopeSection, deleteScopeSection),
  on(ScopeOverviewActions.deleteScopeSectionSuccess, deleteScopeSectionSuccess),
  on(ScopeOverviewActions.deleteScopeSectionFail, deleteScopeSectionFail),
  on(ScopeOverviewActions.updateScope, updateScope),
  on(ScopeOverviewActions.updateScopeSuccess, updateScopeSuccessHandler),
  on(ScopeOverviewActions.updateScopeFail, updateScopeFailHandler),
  on(ScopeOverviewActions.refreshOutdatedCustomFields, refreshOutdatedCustomFields),
  on(ScopeOverviewActions.refreshOutdatedCustomFieldsSuccess, refreshOutdatedCustomFieldsSuccessHandler),
  on(ScopeOverviewActions.refreshOutdatedCustomFieldsFail, refreshOutdatedCustomFieldsFailHandler),
  on(ScopeOverviewActions.addScopeCollaborator, addScopeCollaborator),
  on(ScopeOverviewActions.addScopeCollaboratorSuccess, addScopeCollaboratorSuccessHandler),
  on(ScopeOverviewActions.addScopeReviewer, addScopeReviewer),
  on(ScopeOverviewActions.addScopeReviewerSuccess, addScopeReviewerSuccessHandler),
  on(ScopeOverviewActions.addScopeApprover, addScopeApprover),
  on(ScopeOverviewActions.addScopeApproverSuccess, addScopeApproverSuccessHandler),
  on(ScopeOverviewActions.addScopeTrafficker, addScopeTrafficker),
  on(ScopeOverviewActions.addScopeTraffickerSuccess, addScopeTraffickerSuccessHandler),
  on(ScopeOverviewActions.updateTeamMember, updateTeamMember),
  on(ScopeOverviewActions.updateTeamMemberSuccess, updateTeamMemberSuccessHandler),
  on(ScopeOverviewActions.removeScopeCollaborator, removeScopeCollaborator),
  on(ScopeOverviewActions.removeScopeCollaboratorSuccess, removeScopeCollaboratorSuccessHandler),
  on(ScopeOverviewActions.removeScopeReviewer, removeScopeReviewer),
  on(ScopeOverviewActions.removeScopeReviewerSuccess, removeScopeReviewerSuccessHandler),
  on(ScopeOverviewActions.removeScopeApprover, removeScopeApprover),
  on(ScopeOverviewActions.removeScopeApproverSuccess, removeScopeApproverSuccessHandler),
  on(ScopeOverviewActions.removeScopeTrafficker, removeScopeTrafficker),
  on(ScopeOverviewActions.removeScopeTraffickerSuccess, removeScopeTraffickerSuccessHandler),
  on(ScopeOverviewActions.scopeTeamFail, scopeTeamFailHandler),
  on(ScopeOverviewActions.deleteDeliverable, deleteDeliverable),
  on(ScopeOverviewActions.deleteDeliverableSuccess, deleteDeliverableSuccess),
  on(ScopeOverviewActions.deleteDeliverableFail, deleteDeliverableFail),
  on(ScopeOverviewActions.deleteDeliverableFeeItemSuccess, deleteDeliverableFeeSuccess),
  on(ScopeOverviewActions.deleteDeliverableFeeItemFail, deleteDeliverableFeeFail),
  on(ScopeOverviewActions.deleteComponentFeeSuccess, deleteComponentFeeSuccess),
  on(ScopeOverviewActions.deleteComponentFeeFail, deleteComponentFeeFail),
  on(ScopeOverviewActions.deleteScopeFeeItem, deleteScopeFee),
  on(ScopeOverviewActions.deleteScopeFeeItemSuccess, deleteScopeFeeSuccess),
  on(ScopeOverviewActions.deleteScopeFeeItemFail, deleteScopeFeeFail),
  on(ScopeOverviewActions.deleteSectionFeeItem, deleteSectionFee),
  on(ScopeOverviewActions.deleteSectionFeeItemSuccess, deleteSectionFeeSuccess),
  on(ScopeOverviewActions.updateScopeDiscountSuccess, updateScopeDiscountSuccessHandler),
  on(ScopeOverviewActions.updateScopeMsaSuccess, updateScopeMsaSuccessHandler),
  on(ScopeOverviewActions.updateScopeRetainedHoursStatsSuccess, updateScopeRetainedHoursStatsSuccessHandler),
  on(ScopeOverviewActions.deleteSectionFeeItemFail, deleteSectionFeeFail),
  on(ScopeOverviewActions.setScopeNotificationEvent, setScopeNotificationEvent),
  on(ScopeOverviewActions.filterDeliverablesSuccess, filterDeliverablesSuccessHandler),
  on(ScopeOverviewActions.createScopeFeeItemSuccess, createScopeFeeItemSuccessHandler),
  on(ScopeOverviewActions.createSectionFeeItemSuccess, createSectionFeeItemSuccessHandler),
  on(ScopeOverviewActions.createDeliverableFeeItemSuccess, createDeliverableFeeItemSuccessHandler),
  on(ScopeOverviewActions.createComponentFeeItemSuccess, createComponentFeeItemSuccessHandler),
  on(ScopeOverviewActions.editScopeFeeItemSuccess, editScopeFeeItemSuccessHandler),
  on(ScopeOverviewActions.editSectionFeeItemSuccess, editSectionFeeItemSuccessHandler),
  on(ScopeOverviewActions.editDeliverableFeeItemSuccess, editDeliverableFeeItemSuccessHandler),
  on(ScopeOverviewActions.editComponentFeeItemSuccess, editComponentFeeItemSuccessHandler),
  on(ScopeOverviewActions.updateScopeSectionsOrder, updateScopeSectionsOrderHandler),
  on(ScopeOverviewActions.updateDeliverablesOrder, updateDeliverablesOrderHandler),
  on(ScopeOverviewActions.deliverableTypeSearch, deliverableTypeSearch),
  on(ScopeOverviewActions.deliverableTypeSearchSuccess, deliverableTypeSearchSuccessHandler),
  on(ScopeOverviewActions.deliverableTypeSearchFail, deliverableTypeSearchFailHandler),
  on(ScopeOverviewActions.initiateTrade, initiateTradeHandler),
  on(ScopeOverviewActions.initiateTradeSuccess, initiateTradeSuccessHandler),
  on(ScopeOverviewActions.initiateTradeFail, initiateTradeFailHandler),
  on(ScopeOverviewActions.updateCurrentScope, updateCurrentScope),
  on(ScopeOverviewActions.updateCurrentScopeSuccess, updateCurrentScopeSuccessHandler),
  on(ScopeOverviewActions.updateCurrentScopeFail, updateCurrentScopeFailHandler),
  on(ScopeOverviewActions.reopenTrade, reopenTradeHandler),
  on(ScopeOverviewActions.reopenTradeSuccess, reopenTradeSuccessHandler),
  on(ScopeOverviewActions.reopenTradeFail, reopenTradeFailHandler),
  on(ScopeOverviewActions.stopTrade, stopTradeHandler),
  on(ScopeOverviewActions.stopTradeSuccess, stopTradeSuccessHandler),
  on(ScopeOverviewActions.stopTradeFail, stopTradeFailHandler),
  on(ScopeOverviewActions.completeTrade, completeTradeHandler),
  on(ScopeOverviewActions.completeTradeSuccess, completeTradeSuccessHandler),
  on(ScopeOverviewActions.completeTradeFail, completeTradeFailHandler),
  on(ScopeOverviewActions.cancelTrade, cancelTradeHandler),
  on(ScopeOverviewActions.cancelTradeSuccess, cancelTradeSuccessHandler),
  on(ScopeOverviewActions.cancelTradeFail, cancelTradeFailHandler),
  on(ScopeOverviewActions.confirmTrade, confirmTradeHandler),
  on(ScopeOverviewActions.confirmTradeSuccess, confirmTradeSuccessHandler),
  on(ScopeOverviewActions.confirmTradeFail, confirmTradeFailHandler),
  on(ScopeOverviewActions.applyLocationCardToDeliverable, applyLocationCardToDeliverableHandler),
  on(ScopeOverviewActions.applyLocationCardToDeliverableSuccess, applyLocationCardToDeliverableSuccessHandler),
  on(ScopeOverviewActions.applyLocationCardToDeliverableFail, applyLocationCardToDeliverableFailHandler),
  on(ScopeOverviewActions.deleteScopeDiscount, deleteScopeDiscountHandler),
  on(ScopeOverviewActions.deleteScopeDiscountSuccess, deleteScopeDiscountSuccessHandler),
  on(ScopeOverviewActions.deleteScopeDiscountFail, deleteScopeDiscountFailHandler),
  on(ScopeOverviewActions.deleteScopeMsa, deleteScopeMsaHandler),
  on(ScopeOverviewActions.deleteScopeMsaSuccess, deleteScopeMsaSuccessHandler),
  on(ScopeOverviewActions.deleteScopeMsaFail, deleteScopeMsaFailHandler),
  on(ScopeOverviewActions.addDeliverableCommentSuccess, addDeliverableCommentSuccessHandler),
  on(ScopeOverviewActions.addComponentCommentSuccess, addComponentCommentSuccessHandler),
  on(ScopeOverviewActions.addDepartmentCommentSuccess, addDepartmentCommentSuccessHandler),
  on(ScopeOverviewActions.addRoleCommentSuccess, addRoleCommentSuccessHandler),
  on(ScopeOverviewActions.addDeliverableSectionCommentSuccess, addDeliverableSectionCommentSuccessHandler),
  on(ScopeOverviewActions.addScopeSectionCommentSuccess, addScopeSectionCommentSuccessHandler),
  on(ScopeOverviewActions.addDeliverableTPCCommentSuccess, addDeliverableTPCCommentSuccessHandler),
  on(ScopeOverviewActions.addScopeTPCCommentSuccess, addScopeTPCCommentSuccessHandler),
  on(ScopeOverviewActions.addComponentTPCCommentSuccess, addComponentTPCCommentSuccessHandler),
  on(ScopeOverviewActions.addScopeCommentSuccess, addScopeCommentSuccessHandler),
  on(ScopeOverviewActions.getDeliverableAndComponentFees, getDeliverableAndComponentFeesHandler),
  on(ScopeOverviewActions.getDeliverableAndComponentFeesSuccess, getDeliverableAndComponentFeesSuccessHandler),
  on(ScopeOverviewActions.getDeliverableAndComponentFeesFail, getDeliverableAndComponentFeesFailHandler),
  on(ScopeOverviewActions.getAllDeliverableTPCs, getAllDeliverableTPCsHandler),
  on(ScopeOverviewActions.getAllDeliverableTPCsSuccess, getAllDeliverableTPCsSuccessHandler),
  on(ScopeOverviewActions.getAllDeliverableTPCsFail, getAllDeliverableTPCsFailHandler),
  on(ScopeOverviewActions.searchComponentsForScopeSuccess, searchComponentsForScopeSuccessHandler),
  on(ScopeOverviewActions.searchNonDistinctComponentsSuccess, searchNonDistinctComponentsSuccessHandler),
  on(ScopeOverviewActions.searchDepartmentsForScopeSuccess, searchDepartmentsForScopeSuccessHandler),
  on(ScopeOverviewActions.searchRolesForScopeSuccess, searchRolesForScopeSuccessHandler),
  on(ScopeOverviewActions.applyLocationToRoleSuccess, applyLocationToRoleSuccessHandler),
  on(ScopeOverviewActions.setDialogId, setDialogIdHandler)
)

export function reducer(state: ScopeOverviewState | undefined, action: Action): ScopeOverviewState {
  return ScopeOverviewReducer(state, action);
}
