import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SharedModule } from '@app/shared/shared.module';
import {
  ScopeUiDatepickerComponent,
} from '@app/shared/components/ui-components/scope-ui-datepicker/scope-ui-datepicker.component';
import {
  ScopeUiAutocompleteComponent,
} from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxMaskDirective } from 'ngx-mask';
import {
  ScopeUiDropdownComponent,
} from '@shared/components/ui-components/scope-ui-dropdown/scope-ui-dropdown.component';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  SNACKBAR_LENGTH_LONG,
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import {
  DynamicFieldType,
  ScopeDynamicFieldSettingModel,
} from '@app/features/scoping/models/scope-dynamic-field-setting.model';
import { ScopeDynamicFieldSettingService } from '@app/features/scoping/service/scope-dynamic-field-setting.service';
import { ScopeActions } from '@app/features/scoping/store/actions/scoping.actions';
import { UserSettingsService } from '@app/features/scope-overview/service/user-settings.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

export interface ScopeDynamicFieldSettingModalConfig {
  title: string;
  submitButtonName: string;
  scopeDynamicFieldSetting: ScopeDynamicFieldSettingModel;
  isEditModal: boolean;
}

@Component({
  selector: 'create-scope-dynamic-field-setting-modal',
  standalone: true,
  imports: [CommonModule, ScopeUiDatepickerComponent, ScopeUiAutocompleteComponent, SharedModule, MatDatepickerModule, CKEditorModule, ScopeUiInputComponent, NgxSliderModule, NgxMaskDirective, ScopeUiDropdownComponent, NgxJsonViewerModule],
  templateUrl: './create-scope-dynamic-field-setting-modal.component.html',
  styleUrls: ['./create-scope-dynamic-field-setting-modal.component.scss'],
})
export class CreateScopeDynamicFieldSettingModalComponent implements OnInit, AfterViewInit {
  dynamicFieldTypeDropdownOptions: { name: string, id: DynamicFieldType }[] = [];
  companyApiIntegrationsDropdownOptions: { id: number, name: string }[] = [];
  companyApiIntegrations: any = [];
  selectedCompanyApiIntegration: { name: string, id: number };
  fieldNameControl = new FormControl();
  valueControl = new FormControl();
  resourceControl = new FormControl();
  jsonValueNameControl = new FormControl();
  fieldJsonKeyControl = new FormControl();
  jsonNode: any;
  isFetchingExternalData: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ScopeDynamicFieldSettingModalConfig,
    private scopeDynamicFieldSettingService: ScopeDynamicFieldSettingService,
    private store: Store,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    public userSettingsService: UserSettingsService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    if (!this.data.isEditModal) {
      this.data.scopeDynamicFieldSetting = new ScopeDynamicFieldSettingModel();
    }
    this.dynamicFieldTypeDropdownOptions = Object.values(DynamicFieldType).map(type => ({
      name: type,
      id: type,
    }));

    if (this.data.scopeDynamicFieldSetting && this.data.scopeDynamicFieldSetting.type === 'DROPDOWN_EXTERNAL') {
      this.getCompanyApiIntegration(this.data.scopeDynamicFieldSetting.companyApiIntegrationId);
      const updatedSetting = { ...this.data.scopeDynamicFieldSetting };
      updatedSetting.jsonValueName = updatedSetting.jsonValueName.replace(/^\$\./, '');
      this.data.scopeDynamicFieldSetting = updatedSetting;
    }

    this.fieldNameControl.valueChanges.subscribe(value => {
      this.onFieldNameChange(value);
    });
    this.valueControl.valueChanges.subscribe(value => {
      this.onValueChange(value);
    });
    this.resourceControl.valueChanges.subscribe(value => {
      this.onResourceChange(value);
    });
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onFieldNameChange(value: string) {
    const updatedSetting = { ...this.data.scopeDynamicFieldSetting };
    updatedSetting.displayName = value;
    if (value) {
      const fieldJsonKey = value.toLowerCase().replace(/\s+/g, '_');
      this.fieldJsonKeyControl.setValue(fieldJsonKey);
      updatedSetting.name = fieldJsonKey;
    } else {
      this.fieldJsonKeyControl.setValue('');
    }
    this.data.scopeDynamicFieldSetting = updatedSetting;
    this.cdr.detectChanges();
  }

  onValueChange(value: string) {
    const clonedSetting = { ...this.data.scopeDynamicFieldSetting };
    clonedSetting.value = value;
    this.data.scopeDynamicFieldSetting = clonedSetting;
  }

  onResourceChange(value: string) {
    this.data.scopeDynamicFieldSetting.externalSystemResourceUri = value;
  }

  onSelectionDynamicFieldType(event: any) {
    if (!event.id) return;
    if (event.id === 'DROPDOWN_EXTERNAL') {
      this.getCompanyApiIntegrations();
    }
    this.valueControl.reset();
    this.resourceControl.reset();
    this.jsonNode = null;
    this.data.scopeDynamicFieldSetting.type = event.id;
  }

  clearDropdownExternalData(): void {
    this.data.scopeDynamicFieldSetting.companyApiIntegrationId = null;
    this.data.scopeDynamicFieldSetting.jsonValueName = null;
    this.data.scopeDynamicFieldSetting.externalSystemResourceUri = null;
    this.data.scopeDynamicFieldSetting.apiIntegrationName = null;
  }

  getCompanyApiIntegrations(): void {
    this.userSettingsService.getCompanyIntegrations().subscribe(response => {
      this.companyApiIntegrations = response;
      this.companyApiIntegrationsDropdownOptions = this.companyApiIntegrations.map(companyApiIntegration => ({
        name: companyApiIntegration.apiIntegration.name,
        id: companyApiIntegration.id,
      }));
    });
  }

  getCompanyApiIntegration(id: number) {
    this.userSettingsService.getCompanyIntegrations().subscribe(response => {
      this.companyApiIntegrations = response;
      this.companyApiIntegrationsDropdownOptions = this.companyApiIntegrations.map(companyApiIntegration => ({
        name: companyApiIntegration.apiIntegration.name,
        id: companyApiIntegration.id,
      }));
      this.selectedCompanyApiIntegration = this.companyApiIntegrationsDropdownOptions.find(companyApiIntegration => companyApiIntegration.id === id);
      this.data.scopeDynamicFieldSetting.apiIntegrationName = this.selectedCompanyApiIntegration.name;
      this.fetchExternalData();
    });
  }

  onSelectionCompanyApiIntegration(event: any) {
    if (!event.id) return;
    this.data.scopeDynamicFieldSetting.companyApiIntegrationId = event.id;
    this.data.scopeDynamicFieldSetting.apiIntegrationName = event.name;
  }

  disableSubmit(): boolean {
    switch (this.data.scopeDynamicFieldSetting?.type) {
      case DynamicFieldType.TEXT:
        return !this.fieldNameControl.getRawValue();
      case DynamicFieldType.DROPDOWN:
        return !this.fieldNameControl.getRawValue() || !this.valueControl.getRawValue();
      case DynamicFieldType.DROPDOWN_EXTERNAL:
        return !this.fieldNameControl.getRawValue() ||
          !this.data.scopeDynamicFieldSetting?.companyApiIntegrationId ||
          !this.data.scopeDynamicFieldSetting?.externalSystemResourceUri ||
          !this.jsonValueNameControl.getRawValue();
      default:
        return true;
    }
  }

  isDropdownExternal(): boolean {
    return this.data.scopeDynamicFieldSetting?.type === 'DROPDOWN_EXTERNAL';
  }

  onJsonKeyClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('segment-key')) {
      const key = target.textContent?.trim();
      let parentArraysLength = 0;
      let currentElement = target.parentElement;
      let pathSegments: string[] = [];

      while (currentElement && !currentElement.classList.contains('ngx-json-viewer')) {
        if (currentElement.classList.contains('segment-type-array')) {
          parentArraysLength++;
          if (parentArraysLength > 0) {
            pathSegments = [];
            break;
          }
        }

        let parentNode = currentElement.parentElement;
        while (parentNode && !parentNode.classList.contains('segment')) {
          parentNode = parentNode.parentElement;
        }

        const parentKeyElement = parentNode?.querySelector('.segment-key') as HTMLElement;
        if (parentKeyElement) {
          const parentKey: string = parentKeyElement.textContent?.trim();
          if (parentKey && isNaN(Number(parentKey))) {
            pathSegments.unshift(parentKey);
          }
        }
        currentElement = parentNode;
      }
      if (parentArraysLength === 0 && key) {
        const jsonPath = pathSegments.join('.');
        this.jsonValueNameControl.setValue(jsonPath);
        this.cdr.detectChanges();
      } else {
        this.jsonValueNameControl.setValue(null);
        this.jsonValueNameControl.setErrors({
          invalidValue: true,
          message: 'You can\'t choose a field from a nested array',
        });
        this.cdr.detectChanges();
      }
    }
  }


  fetchExternalData() {
    this.isFetchingExternalData = true;
    this.scopeDynamicFieldSettingService.getExternalSystemResource({
      integrationName: this.data.scopeDynamicFieldSetting.apiIntegrationName,
      resourceUri: this.data.scopeDynamicFieldSetting.externalSystemResourceUri,
      fetchFirstPageOnly: true
    }).subscribe({
      next: (resp) => {
        this.jsonNode = resp.slice(0, 10);
        this.isFetchingExternalData = false;
      }, error: () => {
        this.jsonNode = null;
        this.jsonValueNameControl.setValue(null);
        this.isFetchingExternalData = false;
        this.snackbarService.showSnackbar('Error during getting External System Resource', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR);
      }
    });
  }

  onSubmit() {
    if (this.data.scopeDynamicFieldSetting.type === DynamicFieldType.DROPDOWN_EXTERNAL) {
      this.data.scopeDynamicFieldSetting.jsonValueName = '$.' + this.jsonValueNameControl.getRawValue();
    } else {
      this.clearDropdownExternalData();
    }
    if (this.data.isEditModal) {
      this.scopeDynamicFieldSettingService.update(this.data.scopeDynamicFieldSetting.id, this.data.scopeDynamicFieldSetting).subscribe({
        next: (resp) => {
          this.store.dispatch(ScopeActions.updateDynamicFieldSettingSuccess({ setting: resp }));
          this.snackbarService.showSnackbar(`Scope Dynamic Field Setting ${resp.displayName} was successfully updated.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
        },
        error: () => this.snackbarService.showSnackbar('Error during saving Scope Dynamic Field Setting', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR),
      });
    } else {
      this.scopeDynamicFieldSettingService.create(this.data.scopeDynamicFieldSetting).subscribe({
        next: (resp) => {
          this.store.dispatch(ScopeActions.createDynamicFieldSettingSuccess({ setting: resp }));
          this.snackbarService.showSnackbar(`Scope Dynamic Field Setting ${resp.displayName} was successfully created.`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.SUCCESS);
        },
        error: () => this.snackbarService.showSnackbar('Error during creating Scope Dynamic Field Setting', SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR),
      });
    }
    this.dialog.closeAll();
  }
}
