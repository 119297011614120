<div class="team-container">
  <div class="team-wrapper flex flex-col">
    <div class="ml-auto flex">
      <scope-ui-options-menu
        *ngIf="isMenuVisible()"
        [menuOptions]="menuOptions"
        [buttonText]="'Add ' + ('team_member|l' | lang)" />
    </div>
    <div class="flex pt-8">
      <scope-ui-table
        class="team-table"
        [loggedInUser]='loggedInUser'
        [data]="mappedCollaboratorsDataSource$ | async"
        [displayedColumns]="collaboratorColumns"
        [addToggleListColumn]="true"
        [noSort]="true"
        [noPagination]="true"
        [noPreferences]="true"
        [deleteAction]="collaboratorDeleteAction"
        [showToggleMenu]="false"
        [expandable]="false">
      </scope-ui-table>
    </div>
    <div class="flex pt-8" *ngIf="loggedInUser?.company?.scopePlan?.hasFeature(ScopePlanTypeFeature.SCOPE_APPROVALS)">
      <scope-ui-table
        class="team-table"
        [loggedInUser]='loggedInUser'
        [data]="mappedReviewersDataSource$ | async"
        [displayedColumns]="reviewerColumns"
        [addToggleListColumn]="true"
        [noSort]="true"
        [noPagination]="true"
        [noPreferences]="true"
        [deleteAction]="reviewerDeleteAction"
        [showToggleMenu]="false"
        [expandable]="false">
      </scope-ui-table>
    </div>
    <div class="flex pt-8" *ngIf="loggedInUser?.company?.scopePlan?.hasFeature(ScopePlanTypeFeature.SCOPE_APPROVALS)">
      <scope-ui-table
        class="team-table"
        [loggedInUser]='loggedInUser'
        [data]="mappedApproversDataSource$ | async"
        [displayedColumns]="approverColumns"
        [addToggleListColumn]="true"
        [noSort]="true"
        [noPagination]="true"
        [noPreferences]="true"
        [deleteAction]="approverDeleteAction"
        [showToggleMenu]="false"
        [expandable]="false">
      </scope-ui-table>
    </div>
    <div class="flex pt-8">
      <scope-ui-table
        class="team-table"
        [loggedInUser]='loggedInUser'
        [data]="mappedTraffickersDataSource$ | async"
        [displayedColumns]="traffickerColumns"
        [addToggleListColumn]="true"
        [noSort]="true"
        [noPagination]="true"
        [noPreferences]="true"
        [deleteAction]="traffickerDeleteAction"
        [showToggleMenu]="false"
        [expandable]="false">
      </scope-ui-table>
    </div>
  </div>
</div>
