<div class="table-container">
  <table-loader [loading]="loading" [condensed]="condensed"></table-loader>
  <table
    *ngIf="loggedInUser"
    [hidden]="loading"
    (matSortChange)="onSortChange($event)"
    [class]="tableClass"
    [ngClass]="{
      'condensed': condensed,
      'collapsed': !_tableExpanded && headerExpandable,
      'sticky-header': stickyHeader,
      'descriptions': showDescription,
      'row-gaps': rowGaps
    }"
    mat-table
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackById"
    multiTemplateDataRows
    (click)="noSort && expandTable()">
    <ng-container *ngFor="let column of _displayedColumns; let idx = index; let last = last">
      <ng-container *ngIf="column.key !== TableColumnKey.TOGGLE_LIST" matColumnDef="{{ column.key }}">
        <ng-container *ngIf="!noSort && !column.noSort">
          <th class="scope-header-cell text-scope_purple font-newJuneBold leading-5 {{level ? 'level-'+level : ''}}"
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef>
            <mat-icon
              *ngIf="headerExpandable && !hideToggles && idx === 0 && dataSource?.data?.length > 0"
              class="arrow orange material-symbols-rounded"
              [ngClass]="{ active: _tableExpanded }"
              (click)="expandTable(); $event.stopPropagation()">expand_more</mat-icon>
            <mat-icon
              *ngIf="showExpandAll && !hideToggles && idx === 0 && dataSource?.data?.length > 0"
              class="arrow orange material-symbols-rounded"
              [ngClass]="{ active: Object.keys(expandedElements).length }"
              (click)="expandAll($event); $event.stopPropagation()">expand_more</mat-icon>
            <span isEllipsisActive>{{ column.name | langf }}</span>
            <sort-icon
              [column]="column.key"
              [sortedColumn]="sortedColumn"
              [sortDirection]="sortDirection"></sort-icon>
            <commentable *ngIf="column.headerValue && column.headerCommentable" class="flex items-center"
                        [c-key]="column.headerCommentable().key" [c-entity]="column.headerCommentable().entity">
              <span class="header-value">{{column.headerValue()}}</span>
            </commentable>
            <span class="header-value" *ngIf="column.headerValue && !column.headerCommentable">{{column.headerValue()}}</span>
          </th>
        </ng-container>
        <ng-container *ngIf="noSort || column.noSort">
          <th class="scope-header-cell text-scope_purple font-newJuneBold leading-5 {{level ? 'level-'+level : ''}}"
              mat-header-cell
              *matHeaderCellDef>
            <div class="flex items-center">
              <mat-icon
                *ngIf="headerExpandable && !hideToggles && idx === 0 && dataSource?.data?.length > 0"
                class="arrow orange material-symbols-rounded"
                [ngClass]="{ active: _tableExpanded }"
                (click)="expandTable(); $event.stopPropagation()">expand_more</mat-icon>
              <mat-icon
                *ngIf="showExpandAll && !hideToggles && idx === 0 && dataSource?.data?.length > 0"
                class="arrow orange material-symbols-rounded"
                [ngClass]="{ active: Object.keys(expandedElements).length }"
                (click)="expandAll($event);">expand_more</mat-icon>
              <i
                class="material-symbols-rounded d-flex-inline pr-1"
                *ngIf="column.icon"
                >{{ column.icon }}</i
              >
              <span class="flex flex-col flex-center-m">{{ column.name | langf }}</span>
            </div>
            <commentable *ngIf="column.headerValue && column.headerCommentable" class="flex items-center"
                        [c-key]="column.headerCommentable().key" [c-entity]="column.headerCommentable().entity">
              <span class="header-value">{{column.headerValue()}}
                <mat-icon *ngIf="column.headerValueAction && column.headerValueActionAvailable && column.headerValueActionAvailable()"
                          (click)="column.headerValueAction()" [matTooltip]="column.headerValueTooltip"
                          class="clear-hours-action orange material-symbols-rounded">clear_all</mat-icon>
              </span>
            </commentable>
            <span class="header-value" *ngIf="column.headerValue && !column.headerCommentable">{{column.headerValue()}}</span>
          </th>
        </ng-container>

        <td
          mat-cell
          *matCellDef="let element"
          class="element-row"
          [class]="getCellClass(column.key, element[column.key], element.entity)">
          <div>
            <ng-container *ngIf="idx === 0">
              <i *ngIf="showToggle(element)" class="material-icons material-symbols-rounded orange arrow pointer" [ngClass]="{ active: expandedElements?.[useSourceId ? element.entity.source.id : element.entity.id] }" (click)="onExpandElement(element, $event)">expand_more</i>
              <i *ngIf="!showToggle(element)" class="material-icons material-symbols-rounded arrow invisible" [ngClass]="{ active: expandedElements?.[useSourceId ? element.entity.source.id : element.entity.id] }">expand_more</i>
              <mat-icon class="material-symbols-rounded item-icon mr-1" *ngIf="column.valueIconFunction">{{column.valueIconFunction(element.entity)}}</mat-icon>
              <img *ngIf="column.imageFunction" class="column-val-icon mr-2" src="{{column.imageFunction(element.entity)}}" alt=""/>
            </ng-container>

            <div class="data-wrapper relative">
              <div *ngIf="!column.options && !column.optionsFunction && !column.inputType?.(element.entity)" class="flex items-center justify-center text-center data-value">
                <commentable *ngIf="column.commentable" [c-key]="column.commentable().key" [c-entity]="element.entity" [c-root-entity]="column.commentable().rootEntity">
                  <div class="cell-value" matTooltip="{{formatCellData(column.key, element[column.key], element.entity).length > 20 ? formatCellData(column.key, element[column.key], element.entity) : ''}}">{{ formatCellData(column.key, element[column.key], element.entity) }}</div>
                </commentable>
                <div class="cell-value" *ngIf="!column.commentable && column.key !== TableColumnKey.TOGGLE">
                  {{ formatCellData(column.key, element[column.key], element.entity) }}
                </div>
                <div class="flex float-right" *ngIf="column.key === TableColumnKey.TOGGLE">
                  <scope-ui-options-menu [menuOptions]="menuOptions" [toggleButton]="true" [element]="element">
                  </scope-ui-options-menu>
                </div>
                <span class='relative'>
                  <mat-icon *ngIf="column.key === TableColumnKey.FEE_NAME" class="material-symbols-rounded component-description-icon grey"
                            (click)="toggleEditFeeItemDescription(element)">help_outline</mat-icon>
                  <span class="hidden-tooltip-right"
                        (mouseout)="disableDragging=false; disableDraggingChange.emit(disableDragging)"
                        (mouseover)="disableDragging=true; disableDraggingChange.emit(disableDragging)"
                        *ngIf="column.key === TableColumnKey.FEE_NAME && showFeeItemDescription === element" data-nodrag>
                    <form name="editFeeItemDescriptionForm">
                      <span class="title">Description</span>
                      <scope-ui-input
                        [isTextarea]='true'
                        [isDisabled]="!loggedInUser.hasPrivilege('SCOPEMARK_COMPONENT_INFO__EDIT')"
                        [maximumLength]='100000'
                        [initialValue]='newFeeItemDescription'
                        [inputClass]="'resize-none'"
                        [textareaRows]="5"
                        (onInputChange)="newFeeItemDescription = $event"
                      ></scope-ui-input>
                      <div style="display: inline-flex">
                        <button class="btn btn--cancel ultra-grey"
                                (click)="toggleEditFeeItemDescription(element)">Cancel</button>
                        <button class="btn btn--action py-0.5 px-2"
                                style="margin-left: 6px"
                                [disabled]='newFeeItemDescription === element.entity.feeItem.description'
                                (click)="setEditFeeItemDescription(element)">Save</button>
                      </div>
                    </form>
                  </span>
                </span>
                <span class='relative'>
                  <mat-icon *ngIf="column.key === TableColumnKey.TPC_NAME" class="material-symbols-rounded component-description-icon grey"
                            (click)="toggleScopeTpcDescription(element)">help_outline</mat-icon>
                  <span class="hidden-tooltip-right"
                        (mouseout)="disableDragging=false; disableDraggingChange.emit(disableDragging)"
                        (mouseover)="disableDragging=true; disableDraggingChange.emit(disableDragging)"
                        *ngIf="column.key === TableColumnKey.TPC_NAME && showScopeTpcDescription === element" data-nodrag>
                    <form name="editScopeTpcDescriptionForm">
                      <span class="title">Description</span>
                      <scope-ui-input
                        [isTextarea]='true'
                        [isDisabled]="!loggedInUser.hasPrivilege('SCOPEMARK_COMPONENT_INFO__EDIT')"
                        [maximumLength]='100000'
                        [initialValue]='newScopeTpcDescription'
                        [inputClass]="'resize-none'"
                        [textareaRows]="5"
                        (onInputChange)="newScopeTpcDescription = $event"
                      ></scope-ui-input>
                      <div style="display: inline-flex">
                        <button class="btn btn--cancel ultra-grey"
                                (click)="toggleScopeTpcDescription(element)">Cancel</button>
                        <button class="btn btn--action py-0.5 px-2"
                                style="margin-left: 6px;"
                                [disabled]='newScopeTpcDescription === element.entity.description'
                                (click)="setScopeTpcItemDescription(element)">Save</button>
                      </div>
                    </form>
                  </span>
                </span>
              </div>

              <ng-container *ngIf="(column.options || column.optionsFunction) && !column.hideOptionsMenu?.(element.entity)">
                <div class="flex justify-center">
                  <scope-ui-dropdown
                    [dropdownClass]="'small-dropdown'"
                    [disabled]="column.isDisabled?.(element)"
                    [dropdownOptions]="column.optionsFunction ? column.optionsFunction(element.entity) : column.options"
                    [displayFn]="column.optionDisplayFn"
                    [selectedValue]="element[column.key]"
                    [isStandardDropdown]="true"
                    [element]="element.entity"
                    (onSelectionChange)="column.onChange?.($event)" />
                </div>
              </ng-container>

              <ng-container *ngIf="column.inputType?.(element.entity) === 'linkTo'">
                <link-to-selector
                  *ngIf="column.key === TableColumnKey.LINK_TO"
                  [currentUser]='loggedInUser'
                  [level]='element.entity.level'
                  [linkTo]='element[column.key]'
                  (levelChange)='onChangeLevel(element.entity, $event)'
                  (linkToChange)='onLinkElement(element.entity, $event)'></link-to-selector>
              </ng-container>

              <ng-container *ngIf="column.inputType?.(element.entity) === 'number'">
                <scope-ui-counter
                  [initialValue]="element[column.key]"
                  [isDecimal]="column.inputContext.isDecimal"
                  [enableMinors]="column.inputContext.enableMinors"
                  [majorStep]="column.inputContext.majorStep"
                  [minorStep]="column.inputContext.minorStep"
                  [formatDisplayValue]="column.inputContext.formatDisplayValue"
                  [parseDisplayValue]="column.inputContext.parseDisplayValue"
                  [formClass]="'small'"
                  [maxLength]="column.inputContext.maxLength"
                  (onUpdateValue)="column.onInputChange(element.entity, $event)"></scope-ui-counter>
              </ng-container>
              <ng-container *ngIf="column.inputType?.(element.entity) === 'box-number'">
                <div class="flex justify-center">
                  <span *ngIf="!column.isVisibleInList?.(element.entity)">-</span>
                  <scope-ui-box-input *ngIf="column.isVisibleInList?.(element.entity)"
                    [initialValue]="element[column.key]"
                    [isDecimal]="column.inputContext.isDecimal"
                    [formClass]="'small'"
                    [disabled]="column.isDisabled?.(element)"
                    (onUpdateValue)="column.onInputChange(element.entity, $event)">
                  </scope-ui-box-input>
                </div>
              </ng-container>
              <ng-container *ngIf="column.inputType?.(element.entity) === 'editable-number'">
                <div class="flex justify-center">
                  <span *ngIf="!column.isVisibleInList?.(element.entity)">-</span>
                  <scope-ui-box-input class="flex flex-nowrap pt-1" *ngIf="column.isVisibleInList?.(element.entity)"
                    [initialValue]="element[column.key].amount ? element[column.key].amount : element[column.key]"
                    [isDecimal]="column.inputContext.isDecimal"
                    [formClass]="column.inputContext && column.inputContext.formClass ? column.inputContext.formClass : 'small'"
                    (onUpdateValue)="column.onInputChange(element.entity, $event)"
                    [confirm]="true"
                    [disabled]="column.isDisabled?.(element)"
                    [viewValue]="formatCellData(column.key, element[column.key], element.entity)"
                  >
                  </scope-ui-box-input>
                </div>
              </ng-container>

              <ng-container *ngIf="column.key === TableColumnKey.NAME && shouldDisplayScopeNumber()">
                <span>{{ formatCellData(TableColumnKey.SCOPE_NUMBER, element[TableColumnKey.SCOPE_NUMBER]) }}</span>
              </ng-container>

              <ng-container *ngIf="column.key === TableColumnKey.DELIVERABLE_NAME || column.key === TableColumnKey.COMPONENT_NAME">
                <span>
                  {{ element.entity.source.name }}
                </span>
              </ng-container>
              <ng-container *ngIf="isFolderBudgetDisplay(element, column.key)">
                <button class="toggle-button float-right ml-1" (click)="$event.stopPropagation()" mat-mini-fab [matMenuTriggerFor]="menu">
                  <mat-icon class="material-symbols-rounded">attach_money</mat-icon>
                </button>
                <mat-menu #menu="matMenu" backdropClass="customize">
                  <button mat-menu-item (click)="$event.stopPropagation();$event.preventDefault()" *ngFor="let budget of element.entity.budgets">
                    {{ formatCellData(column.key, budget, element.entity) }}
                  </button>
                </mat-menu>
              </ng-container>
              <ng-container *ngIf="isFolderValueDisplay(element, column.key)">
                <button class="toggle-button float-right ml-1" (click)="$event.stopPropagation()" mat-mini-fab [matMenuTriggerFor]="menu">
                  <mat-icon class="material-symbols-rounded">attach_money</mat-icon>
                </button>
                <mat-menu #menu="matMenu" backdropClass="customize">
                  <button mat-menu-item (click)="$event.stopPropagation();$event.preventDefault()" *ngFor="let value of element.entity.totalValues">
                    {{ formatCellData(column.key, value, element.entity) }}
                  </button>
                </mat-menu>
              </ng-container>
            </div>

            <ng-container *ngIf="column.key === TableColumnKey.NAME">
              <div *ngIf="(isScopeLoading$ | async) && loadingRowId === element.entity.id" class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </ng-container>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef class="{{level ? 'level-'+level : ''}}">
          <div class="data-wrapper">
            <ng-container *ngIf="column.footerRowValue">
              {{ column.footerRowValue(parentEntity) }}
            </ng-container>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <ng-container [matColumnDef]="TableColumnKey.TOGGLE_LIST">
      <th mat-header-cell *matHeaderCellDef id="toggle">
        <ng-container *ngIf="!noPreferences">
          <scope-ui-toggle-list
            (onTogglePref)="onTogglePreference($event)"
            [preferences]="preferencesArray"
            [maxSelected]="maxColumns"
            [isOpen]="isOpen"
            (onOpenClose)="isOpen = $event"
            class="float-right">
          </scope-ui-toggle-list>
        </ng-container>
        <button
          class="toggle-button float-right ml-1"
          mat-mini-fab
          *ngIf="showAddButton"
          (click)="onAdd(); $event.stopPropagation()">
          <mat-icon class="orange material-symbols-rounded">add</mat-icon>
        </button>
      </th>

      <td
        mat-cell
        *matCellDef="let element">
        <div class="flex float-right">
          <ng-container
            *ngIf=" editAction && (editAction.showOptionFunc ? editAction.showOptionFunc(element.entity) : true)">
            <button
              *ngIf="editAction.buttonType === 'icon'"
              class="float-right ml-1"
              (click)="editAction.clickAction(element.entity); $event.stopPropagation()"
              mat-icon-button>
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
            </button>
            <button
              *ngIf="editAction.buttonType !== 'icon'"
              class="toggle-button float-right ml-1"
              (click)="editAction.clickAction(element.entity); $event.stopPropagation()"
              mat-mini-fab>
              <mat-icon class="material-symbols-rounded">edit</mat-icon>
            </button>
          </ng-container>
          <ng-container
            *ngIf="deleteAction && (deleteAction.showOptionFunc ? deleteAction.showOptionFunc(element.entity) : true)">
            <button
              *ngIf="deleteAction.buttonType === 'icon'"
              class="float-right ml-1"
              (click)="deleteAction.clickAction(element.entity); $event.stopPropagation()"
              mat-icon-button>
              <mat-icon class="material-symbols-rounded orange">delete</mat-icon>
            </button>
            <button
              *ngIf="deleteAction.buttonType !== 'icon'"
              class="toggle-button float-right ml-1"
              (click)="deleteAction.clickAction(element.entity); $event.stopPropagation()"
              mat-mini-fab>
              <mat-icon class="material-symbols-rounded">delete</mat-icon>
            </button>
          </ng-container>
          <scope-ui-options-menu *ngIf="showToggleMenu" [menuOptions]="menuOptions" [toggleButton]="true" [element]="element">
          </scope-ui-options-menu>
        </div>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th
        mat-header-cell
        *matHeaderCellDef
        aria-label="row actions">
        &nbsp;
      </th>
      <td
        mat-cell
        *matCellDef="let element"></td>
      <td
        mat-footer-cell
        *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="breadcrumb">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="_displayedColumns?.length">
        <span isEllipsisActive>{{element.entity.getBreadcrumb?.() || element.entity.breadcrumb}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="_displayedColumns?.length">
        <description-editor
          class="px-8 flex flex-col"
          [visible]="element.editDescription || displayAllDescriptions"
          (visibleChange)="element.editDescription"
          [model]="element.entity"
          [isScope]="false"
          [editable]="showToggleMenu"
          [status]="currentScopeStatus || 'DRAFT'"
          [showHideDescriptionOnly]="showHideDescriptionOnly"
          (onSaveDescription)="saveElementDescription($event, element.entity)"
          (onSaveNote)="saveElementInternalNote($event, element.entity)">
        </description-editor>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="_displayedColumns?.length">
        <div
          class="element-detail"
          [@detailExpand]="expandedElements?.[useSourceId ? element.entity.source.id : element.entity.id] ? 'expanded' : 'collapsed'">
          <ng-container *ngIf="isLibraryItems(element)">
            <table-loader class="w-full" [loading]="element.entity.isLoading" [condensed]="true" [amountOfRows]="0"></table-loader>
            <table
              *ngIf="!element.entity.isLoading"
              class="sub-table text-center"
              mat-table
              [dataSource]="element.children$"
              multiTemplateDataRows>
              <ng-container *ngFor="let innerColumn of _displayedChildColumns; let idx = index; let last = last">
                <ng-container
                  *ngIf="innerColumn.key !== TableColumnKey.TOGGLE_LIST"
                  [matColumnDef]="innerColumn.key">
                  <th
                    class="scope-header-cell text-scope_purple font-newJuneBold leading-5"
                    mat-header-cell
                    *matHeaderCellDef>
                    {{ innerColumn.name | langf }}
                  </th>

                  <td
                    mat-cell
                    *matCellDef="let element"
                    class="element-row">
                    {{ formatCellData(innerColumn.key, element[innerColumn.key], element.entity) }}
                  </td>
                </ng-container>
              </ng-container>
              <ng-container [matColumnDef]="TableColumnKey.TOGGLE_LIST">
                <th
                  mat-header-cell
                  *matHeaderCellDef>
                  <scope-ui-toggle-list
                    (onTogglePref)="onToggleChildPreference($event)"
                    [preferences]="childPreferencesArray"
                    class="float-right">
                  </scope-ui-toggle-list>
                </th>
                <td
                  mat-cell
                  *matCellDef="let element">
                  <scope-ui-options-menu
                    [menuOptions]="childMenuOptions"
                    [toggleButton]="true"
                    [element]="element">
                  </scope-ui-options-menu>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedChildColumnKeys!"></tr>
              <tr
                mat-row
                *matRowDef="let element; columns: displayedChildColumnKeys!"></tr>
            </table>
          </ng-container>
          <ng-container [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: element, sort: _sort}"></ng-container>
        </div>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef></td>
    </ng-container>

    <tr
      class="scope-first-row"
      [ngClass]="{ collapse: hideHeader }"
      mat-header-row
      *matHeaderRowDef="displayedColumnKeys!; sticky: stickyHeader"></tr>
    <ng-container *ngIf="showBreadcrumb">
      <tr
        mat-row
        *matRowDef="let row; columns: ['breadcrumb']"
        [ngClass]="{'collapse': !(row.entity.getBreadcrumb?.() || row.entity.breadcrumb)}"
        class="breadcrumb-row"></tr>
    </ng-container>
    <ng-container *ngIf="rowGaps">
      <tr
        mat-row
        *matRowDef="let row; columns: []"
        class="row-gap"></tr>
    </ng-container>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumnKeys!"
      class="element-row"
      [class.expanded-row]="expandedElements?.[useSourceId ? element.entity.source.id : element.entity.id]"
      (click)="onSelectElement(element, $event)"></tr>
    <ng-container *ngIf="showDescription">
      <tr
        mat-row
        *matRowDef="let row; columns: ['description']"
        class="description-row"></tr>
    </ng-container>
    <ng-container *ngIf="expandable">
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
        [class.expanded-row]="expandedElements?.[useSourceId ? row.entity.source.id : row.entity.id]"></tr>
    </ng-container>
    <tr class="mat-row no-data-row" *matNoDataRow [hidden]="!includeEmptyMessage">
      <td [attr.colspan]="displayedColumnKeys?.length" *ngIf="!headerExpandable || _tableExpanded">
        <h1 class="product-heading-bold">No items added here yet.</h1>
      </td>
    </tr>
    <ng-container *ngIf="showFooterRow">
      <tr
        mat-footer-row
        *matFooterRowDef="displayedColumnKeys!"></tr>
    </ng-container>
  </table>
</div>

<scope-ui-paginator
  *ngIf="!noPagination"
  class='block mt-6'
  [currentPage]='page'
  [totalCount]='totalCount'
  (changePage)="onChangePage($event)">
</scope-ui-paginator>
