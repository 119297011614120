import dayjs, { Dayjs } from "dayjs"

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
export const DAYJS_DATE_FORMAT = 'YYYY-MM-DD'

export const formatDate = (date: any): string => dayjs(date)?.format(DAYJS_DATE_FORMAT)

export class DateRange {
  startDate: Dayjs
  endDate: Dayjs
}
